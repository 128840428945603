import React, { FunctionComponent, PropsWithChildren } from 'react'
import { WhiteBox } from '../../../components/molecules/WhiteBox'
import styled, { css } from 'styled-components'
import { ColorContextProvider } from '@/tonies-ui/index'

export type Layout = 'horizontal' | 'vertical'

export type CustomerCareInfoProps = {
  layout: Layout
  image?: {
    alt: string
    src: string
  }
}

const headerOffsetTopRem = 4

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
`

const WhiteBoxWrapper = styled.div<{ hasImage: boolean }>`
  display: grid;
  position: absolute;
  top: ${p => (p.hasImage ? headerOffsetTopRem : 0)}rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`

const Content = styled.div`
  padding: 1rem;

  a {
    color: ${p => p.theme.colors.primary};
  }
`

const Photo = styled.img<{ layout: Layout }>`
  display: block;
  margin: 0 auto 1rem auto;
  width: 70%;

  ${p =>
    p.layout === 'horizontal' &&
    css`
      float: right;
      margin-left: 1rem;
      width: 10rem;
    `}
`

const Text = styled.div<{ layout: Layout; hasImage: boolean }>`
  margin-top: -0.5rem;

  p {
    margin-top: 0.5rem;
    font-weight: 300;
  }

  ${p =>
    p.layout === 'horizontal' &&
    p.hasImage &&
    css`
      padding-top: ${headerOffsetTopRem}rem;
    `}
`

export const CustomerCareInfo: FunctionComponent<
  CustomerCareInfoProps & PropsWithChildren
> = ({ children, image, layout }) => (
  <ColorContextProvider backgroundColor="#fff">
    <Wrapper>
      <Content>
        {image && <Photo layout={layout} src={image.src} alt={image.alt} />}
        <Text layout={layout} hasImage={!!image}>
          {children}
        </Text>
      </Content>
      <WhiteBoxWrapper hasImage={!!image}>
        <WhiteBox>
          <></>
        </WhiteBox>
      </WhiteBoxWrapper>
    </Wrapper>
  </ColorContextProvider>
)
