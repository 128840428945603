import React, { FunctionComponent, useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import {
  Grid,
  GridArea,
  media,
  Spacing,
  Headline5,
  Paragraph,
  CoverCard,
  useIsViewportDesktop,
  Button,
  TagV2,
} from '@boxine/tonies-ui'
import { cloudinaryUrl } from '@/tonies-ui/atoms/Image/components/cloudinary'
import { Modal } from '@/tonies-ui/molecules/Modal'
import { Section } from '../../../components/atoms/Section'
import { PageLayout } from '../../PageLayout'
import { DeliveryInfoTeaserCollection } from '../../../components/organisms/DeliveryInfoTeaserCollection'
import { useTranslation } from 'next-i18next'
import { FreeHtml } from '../../../components/molecules/FreeHtml'
import { ProductCardCarousel } from '../../../components/organisms/ProductCardCarousel'
import { useAggregatedShopLocale } from '../../../providers/aggregatedShopLocale'
import { useGtmV4 } from '../../../providers/gtmV4'
import { BundledProducts } from '../components/BundledProducts'
import { FloatingPanel } from '../components/FloatingPanel'
import { TrackList } from '../components/TrackList'
import { StickyFooter } from '../components/StickyFooter'
import { ProductSocialShare } from '../components/ProductSocialShare'
import { AdditionalProductInfo } from '../components/AdditionalProductInfo'
import { usePDPLayout } from '../hooks/usePDPLayout'
import { useMetaData } from '../hooks/useMetaData'
import { AudioSampleContainer } from '../components/AudioSample'
import { Price } from '../../../components/atoms/Price'
import { TunesGeoIpWarning } from '../../../components/molecules/Warning'
import { MainActionButton } from '../components/MainActionButton'
import {
  useHouseholdData,
  useHouseholdTunesData,
} from '../../../providers/auth'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { useShopLocaleConfig } from '../../../hooks/useShopLocaleConfig'
import { useUrlModularContentSection } from '../../../hooks/useUrlModularContentSection'
import { Hr } from '../../../components/atoms/Hr'
import { ModularContentSection } from '../../../components/organisms/ModularContentSection'
import { useEcomLocale } from '../../../providers/locale'
import { CompatibleToniesProductCardCarouselView } from '../../../components/molecules/CompatibleToniesProductCardCarousel/components/View'
import { AggregatedPdp } from '../../../lib/transformation/aggregatePdp'
import { BazaarvoiceReviews } from '../components/Bazaarvoice'
import { ToggleFromWishlist } from '../../../components/atoms/ToggleFromWishlist'
import { useOwnAudioContentContext } from '../../../providers/ownAudioContent/OwnAudioContext'
import { AssignModalContent } from '../../../components/molecules/GenericProductCard/variants/components/AssignModalContent'
import { NoTonieCompatibleYetModalContent } from '../../../components/molecules/GenericProductCard/variants/components/NoCompatibleTonieYetModalContent'
import { useToniesToAssign } from '../../../hooks/useToniesToAssign'
import { useAssignIdOfOwnAudioContent } from '../../../hooks/useAssignIdOfOwnAudioContent'
import { useRecommendations } from '../../../providers/recommendations'

export type TunesPdpProps = {
  product: NormalizedProductExtended
  compatibleCreativeTonies?: AggregatedPdp['compatibleCreativeTonies']
  compatibleTonies?: AggregatedPdp['compatibleTonies']
  moreFromSeriesGroup?: AggregatedPdp['moreFromSeriesGroup']
}

const CoverCardWrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  position: relative;
  max-width: 320px;

  ${media.tablet`
    width: 40%;
    min-width: 320px;
  `}
`

const MobileFullbleedWrapper = styled.div`
  margin: 0 -1rem;
`

// FIXME: avoid overwriting internal component styles
const ProductDetailWrapper = styled(Section)`
  padding-top: 6rem;

  ${media.laptop`
    padding-top: 8rem;
  `}
`

const WishlistWrapper = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 1;
`

export const TunesPdp: FunctionComponent<TunesPdpProps> = ({
  product,
  compatibleCreativeTonies,
  compatibleTonies,
  moreFromSeriesGroup,
}) => {
  const lcCC = useEcomLocale()
  const { handleSetLastSeenSku } = useRecommendations()
  handleSetLastSeenSku(product.sku)
  const {
    content: { deliveryInfoTeaserList, recommendedNormalizedProducts },
    commonPages: { audioContentPage, creativeToniesPage },
  } = useAggregatedShopLocale()
  const { pushGtmV4Event } = useGtmV4()
  const isMobile = !useIsViewportDesktop()
  const { t } = useTranslation()
  const { colors } = useTheme()
  const layout = usePDPLayout()
  const { isPending } = useHouseholdData()
  const { isMyTune } = useHouseholdTunesData(product.salesId)
  const shopGlobalLocaleConfig = useShopLocaleConfig()
  const urlModularContentSection = useUrlModularContentSection()
  const [hasProductDetails, setHasProductDetails] = useState(
    !urlModularContentSection
  )
  const [, setHasAssignSuccess] = useState(false)
  const { ageMin, description, tracks } = product
  const hasCompatibleCreativeTonies =
    compatibleCreativeTonies && compatibleCreativeTonies.length > 0
  const hasCompatibleTonies =
    compatibleTonies !== 'request-failed' &&
    compatibleTonies &&
    compatibleTonies.length > 0
  const hasMoreFromSeriesGroup =
    moreFromSeriesGroup?.products && moreFromSeriesGroup.products.length > 0
  const hasRecommendations =
    recommendedNormalizedProducts && recommendedNormalizedProducts.length > 0
  const {
    assignStatus,
    isDirectOwnAudioContentAssignmentEnabled,
    isAllOwnAudioContentReady,
  } = useOwnAudioContentContext()
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
  const { toniesToAssign, isFetchingTonies } = useToniesToAssign(
    product.salesId
  )
  const hasToniesToAssign = Boolean(
    !isFetchingTonies &&
      toniesToAssign &&
      toniesToAssign?.length &&
      toniesToAssign.length > 0
  )
  const isAlreadyAssignedToOnlyCompatibleTonie =
    toniesToAssign &&
    toniesToAssign.filter(tonie => !tonie?.tune).length < 1 &&
    assignStatus.some(
      item =>
        item.sku === product.sku &&
        toniesToAssign[0].id === item.assignedTonieId
    )
  const isAudioContentCurrentlyAssigned = assignStatus.some(
    item => item.sku === product.sku
  )
  const mytuneId = useAssignIdOfOwnAudioContent(lcCC, product.salesId)

  // useEffect here because of PDPs with a ColorSelector where we don't have a page
  // load for the selected product, but the change counts as a new GTM product view
  useEffect(() => {
    pushGtmV4Event({
      eventType: 'viewItem',
      item: product,
    })
  }, [product, pushGtmV4Event])

  const mainActionButtonIsDisabled = !shopGlobalLocaleConfig?.showTunes
  const mainActionButtonType = isPending
    ? undefined
    : isMyTune ||
      (isDirectOwnAudioContentAssignmentEnabled &&
        mytuneId &&
        isMyTune &&
        isAllOwnAudioContentReady)
    ? 'assign'
    : 'addToCart'

  return (
    <PageLayout
      dataTestId="pdp-pagelayout"
      stickyFooterChildren={
        <StickyFooter product={product}>
          <div className="price-add-to-cart">
            {!isMyTune && (
              <Price
                product={product}
                vatHint={{
                  content: 'included',
                  position: 'new-line',
                }}
              />
            )}
            <MainActionButton
              product={product}
              isDisabled={mainActionButtonIsDisabled}
              type={mainActionButtonType}
              data-testid="tunes-pdp__mainbutton--mobile"
              hasToniesToAssign={hasToniesToAssign}
              isAudioContentCurrentlyAssigned={isAudioContentCurrentlyAssigned}
              isAlreadyAssignedToOnlyCompatibleTonie={
                isAlreadyAssignedToOnlyCompatibleTonie
              }
              setIsAssignModalOpen={() => setIsAssignModalOpen(true)}
            />
          </div>
        </StickyFooter>
      }
      meta={useMetaData(product)}
    >
      <ProductDetailWrapper data-testextra={product.sku}>
        {!isPending && !isMyTune && <TunesGeoIpWarning />}
        <Grid hasGapH>
          <GridArea {...layout.breadcrumbs}>
            <Spacing mb="spacing-l">
              <Breadcrumbs product={product} />
            </Spacing>
          </GridArea>
          <GridArea {...layout.gallery}>
            <CoverCardWrapper>
              <CoverCard
                src={cloudinaryUrl({ src: product.image.src })}
                alt={product.image.alt}
              />
              <WishlistWrapper>
                <ToggleFromWishlist product={product} />
              </WishlistWrapper>
            </CoverCardWrapper>
          </GridArea>
          <GridArea {...layout.description}>
            <FloatingPanel product={product}>
              {(hasCompatibleCreativeTonies || hasCompatibleTonies) && (
                <Spacing pt="spacing-m">
                  <Paragraph size={3}>
                    {t(
                      'productDetailPage:additionalProductInfo:tunes:compatibleTo'
                    )}
                  </Paragraph>
                  {hasCompatibleCreativeTonies && (
                    <TagV2
                      href="#compatible-creative-tonies"
                      backgroundColor="green-20"
                    >
                      {t(
                        'productDetailPage:additionalProductInfo:tunes:creativeTonies'
                      )}
                    </TagV2>
                  )}
                  {hasCompatibleTonies && (
                    <TagV2 href="#compatible-tonies">
                      {t(
                        'productDetailPage:additionalProductInfo:tunes:sameSeries'
                      )}
                    </TagV2>
                  )}
                </Spacing>
              )}
              <Spacing pt="spacing-m">
                <Price
                  product={product}
                  vatHint={{
                    content: 'included',
                    position: 'inline',
                  }}
                />
                <Spacing mt="spacing-s">
                  <MainActionButton
                    product={product}
                    isDisabled={mainActionButtonIsDisabled}
                    type={mainActionButtonType}
                    data-testid="tunes-pdp__mainbutton--desktop"
                    hasToniesToAssign={hasToniesToAssign}
                    isAudioContentCurrentlyAssigned={
                      isAudioContentCurrentlyAssigned
                    }
                    isAlreadyAssignedToOnlyCompatibleTonie={
                      isAlreadyAssignedToOnlyCompatibleTonie
                    }
                    setIsAssignModalOpen={() => setIsAssignModalOpen(true)}
                  />
                </Spacing>
              </Spacing>
            </FloatingPanel>
          </GridArea>
          <GridArea {...layout.content}>
            <div>
              {(hasProductDetails || product.audioSampleUrl) && (
                <Spacing pt={isMobile ? 'spacing-l' : 'spacing-xl'}>
                  <Headline5 asHTMLTag="h3">
                    {t('productDetailPage:content')}
                  </Headline5>
                </Spacing>
              )}

              <AudioSampleContainer product={product} />

              {hasProductDetails && (
                <>
                  <Spacing pt="spacing-xs">
                    <FreeHtml
                      html={description || ''}
                      pSize={isMobile ? 1 : 2}
                      pWeight={300}
                    />
                  </Spacing>

                  {typeof ageMin === 'number' && (
                    <Spacing
                      pt="spacing-s"
                      pb={isMobile ? 'spacing-xl' : undefined}
                    >
                      <Paragraph
                        size={isMobile ? 1 : 2}
                        weight={300}
                        color="primary"
                      >
                        {t(
                          'productDetailPage:additionalProductInfo:ageMinValue',
                          {
                            years: ageMin,
                          }
                        )}
                      </Paragraph>
                    </Spacing>
                  )}
                </>
              )}

              <BundledProducts product={product} />
            </div>
          </GridArea>
          {hasProductDetails && (
            <>
              {tracks && tracks.length && (
                <GridArea {...layout.tracks}>
                  <Spacing pt={isMobile ? 'spacing-s' : 'spacing-xl'}>
                    <TrackList tracks={tracks} />
                  </Spacing>
                </GridArea>
              )}
              <GridArea {...layout.additionalProductInfo}>
                <Spacing pt="spacing-xxl">
                  {isMobile ? (
                    <MobileFullbleedWrapper>
                      <AdditionalProductInfo
                        product={product}
                        hasCompatibleCreativeTonies={
                          hasCompatibleCreativeTonies
                        }
                        hasCompatibleTonies={hasCompatibleTonies}
                      />
                    </MobileFullbleedWrapper>
                  ) : (
                    <AdditionalProductInfo
                      product={product}
                      hasCompatibleCreativeTonies={hasCompatibleCreativeTonies}
                      hasCompatibleTonies={hasCompatibleTonies}
                    />
                  )}
                </Spacing>
              </GridArea>
              {'socialShare' in layout && (
                <GridArea {...layout.socialShare}>
                  <Spacing pt="spacing-l">
                    <ProductSocialShare
                      product={product}
                      justifyItems="center"
                      verticalGap="large"
                    />
                  </Spacing>
                </GridArea>
              )}
            </>
          )}
        </Grid>
        {hasProductDetails && (
          <>
            <BazaarvoiceReviews product={product} />
            {hasMoreFromSeriesGroup &&
              product.seriesGroup &&
              product.series && (
                <Section
                  removePaddingX
                  removePaddingY
                  id="related-to-this-product"
                >
                  <ProductCardCarousel
                    headline={t(
                      `productDetailPage:moreFromCategories.tunesHeadline`,
                      {
                        name: product.seriesGroup.label,
                      }
                    )}
                    referenceTitle={`[coded] MoreFromCategories -- seriesGroup: ${
                      product.seriesGroup.label
                    } -- ${moreFromSeriesGroup.products
                      .map(p => p.salesId)
                      .join(',')}`}
                    items={moreFromSeriesGroup.products}
                    showOutOfStock={true}
                    showAllLink={`${audioContentPage?.slug}/?series=${product.seriesGroup.key}&series=${product.series.key}`}
                  />
                </Section>
              )}
            <Section removePaddingX removePaddingY id="compatible-tonies">
              <CompatibleToniesProductCardCarouselView
                product={product}
                contentError={compatibleTonies === 'request-failed'}
                products={hasCompatibleTonies ? compatibleTonies : undefined}
                showOutOfStock={true}
                showSkeleton={false}
              />
            </Section>
            {hasCompatibleCreativeTonies && (
              <Section
                removePaddingX
                removePaddingY
                id="compatible-creative-tonies"
              >
                <ProductCardCarousel
                  headline={t(
                    'products:tunes:compatibleCreativeToniesHeadline'
                  )}
                  referenceTitle={`[coded] CompatibleCreativeToniesProductCardCarousel -- ${compatibleCreativeTonies
                    ?.map(p => p.salesId)
                    .join(',')}`}
                  items={compatibleCreativeTonies}
                  showOutOfStock={true}
                  showAllLink={`${creativeToniesPage?.slug}/`}
                />
              </Section>
            )}
          </>
        )}
        {!hasProductDetails && (
          <>
            <Button
              variant="secondary"
              onClick={() => setHasProductDetails(curr => !curr)}
            >
              {t('productDetailPage:showMoreDetails')}
            </Button>
            <Hr />
          </>
        )}
      </ProductDetailWrapper>

      {urlModularContentSection && (
        <ModularContentSection
          contentSection={urlModularContentSection.modularContent}
        />
      )}

      <Section backgroundColor={colors.lightergrey}>
        <DeliveryInfoTeaserCollection items={deliveryInfoTeaserList} />
      </Section>

      {hasRecommendations && (
        <Section backgroundColor={colors['anthracite-10']}>
          <ProductCardCarousel
            headline={t('productDetailPage:recommended')}
            showAllLink={t('productDetailPage:find-more-link')}
            referenceTitle="[coded] Recommendations"
            items={recommendedNormalizedProducts}
            getAPIRecommendations={true}
          />
        </Section>
      )}
      <Modal
        isOpen={isAssignModalOpen}
        onClose={() => setIsAssignModalOpen(false)}
      >
        {toniesToAssign && hasToniesToAssign && mytuneId && (
          <AssignModalContent
            referenceTitle="[coded] PDP MainAction Assign"
            tonies={toniesToAssign}
            tune={product}
            tuneId={mytuneId}
            setIsAssignModalOpen={setIsAssignModalOpen}
            setHasAssignSuccess={setHasAssignSuccess}
          />
        )}
        {!hasToniesToAssign && (
          <NoTonieCompatibleYetModalContent
            setIsAssignModalOpen={setIsAssignModalOpen}
            tune={product}
          />
        )}
      </Modal>
    </PageLayout>
  )
}
