import { lineClampCss, Paragraph } from '@boxine/tonies-ui'
import { ColorContextProvider } from '@/tonies-ui/index'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import styled, { css, useTheme } from 'styled-components'
import { useDateFormatter } from '../../../hooks/useDateFormatter'

type Background = 'anthracite-10' | 'lightblue-20'

export type BorderLeft = 'round' | 'straight'

export type Props = {
  borderLeft: BorderLeft
  product: NormalizedProduct
}

export const Wrapper = styled.div<{
  background: Background
  borderLeft: BorderLeft
}>`
  border-style: solid;
  border-width: 0 2px 2px 0;
  border-radius: 0.5rem;
  padding: 0.75rem 0.5rem 0.5rem 0.5rem;

  background-color: ${p => p.theme.colors[p.background]};

  border-color: ${p =>
    p.background === 'anthracite-10'
      ? p.theme.colors['anthracite-40']
      : p.theme.colors['lightblue-40']};

  ${p =>
    p.borderLeft === 'straight' &&
    css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `}

  > * {
    ${lineClampCss(3)}
  }

  a {
    color: ${p => p.theme.colors.primary};
  }
`

export const Stopper: FunctionComponent<Props> = ({
  borderLeft,
  product: { availability },
}) => {
  const df = useDateFormatter()
  const { colors } = useTheme()
  const { t } = useTranslation()
  let background: Background
  let content: string

  if (availability.state === 'preorderable') {
    background = 'lightblue-20'
    content = t('products:shippingFromDate', {
      date: df(new Date(availability.shippingFromDate)),
    })
  } else if (availability.state === 'announced') {
    background = 'anthracite-10'
    content = t('products:orderFromDate', {
      date: df(new Date(availability.orderFromDate)),
    })
  } else if (availability.state === 'sold-out') {
    background = 'anthracite-10'
    content = t('products:soldOut')
  } else {
    return null
  }

  return (
    <ColorContextProvider backgroundColor={colors[background]}>
      <Wrapper background={background} borderLeft={borderLeft}>
        <Paragraph size={3} weight={500}>
          {content}
        </Paragraph>
      </Wrapper>
    </ColorContextProvider>
  )
}
