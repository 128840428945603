import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from 'react'
import styled from 'styled-components'
import { SocialMediaLink } from '../../../components/atoms/SocialMediaLink'
import { Button, Paragraph, useIsViewportMobile } from '@boxine/tonies-ui'
import { useTranslation } from 'next-i18next'
import { useGtmV4 } from '../../../providers/gtmV4'
import { fullUrl } from '../../../utils/urlHelpers'

export type Props = {
  justifyItems: 'start' | 'center'
  product: NormalizedProduct
  verticalGap: 'small' | 'large'
}

const Wrapper = styled.div<{
  justifyItems: 'start' | 'center'
  verticalGap: 'small' | 'large'
}>`
  display: grid;
  grid-gap: ${p => (p.verticalGap === 'small' ? 0.5 : 1)}rem;
  justify-items: ${p => p.justifyItems};
`

const IconWrapper = styled.div<{
  justifyItems: 'start' | 'center'
}>`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, 1.75rem);
  justify-content: ${p => p.justifyItems};
  width: 100%;
`

export const ProductSocialShare: FunctionComponent<Props> = ({
  product,
  justifyItems,
  verticalGap,
}) => {
  const isMobile = useIsViewportMobile()
  const { t } = useTranslation()
  const { pushGtmV4Event } = useGtmV4()

  const shareUrl = fullUrl(product.path || '', true)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [canShare, setCanShare] = useState(false)
  useEffect(() => {
    setCanShare(!!navigator.share)
  }, [])

  const handleShare = useCallback(async () => {
    try {
      await navigator.share({
        url: shareUrl,
      })
      pushGtmV4Event({
        eventType: 'click',
        type: 'onClick',
        label: shareUrl,
        action: 'share',
      })
    } catch (error) {
      console.error(error)
    }
  }, [pushGtmV4Event, shareUrl])

  return (
    <Wrapper justifyItems={justifyItems} verticalGap={verticalGap}>
      <Paragraph size={isMobile ? 2 : 3} weight={700}>
        {t('productDetailPage:tellOthers')}
      </Paragraph>
      {canShare ? (
        <Button icon="share" onClick={handleShare}>
          {t('productDetailPage:label:share')}
        </Button>
      ) : (
        <IconWrapper justifyItems={justifyItems}>
          {(['facebook', 'twitter', 'pinterest'] as const).map(sn => (
            <SocialMediaLink
              key={sn}
              type="share"
              socialNetwork={sn}
              shareUrl={shareUrl}
              shareTitle={product.name + ' ' + product.subName}
              sizeInEm={2}
            />
          ))}
        </IconWrapper>
      )}
    </Wrapper>
  )
}
