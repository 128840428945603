import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import {
  Grid,
  GridArea,
  useIsViewportMobile,
  useIsViewportTablet,
} from '@boxine/tonies-ui'
import {
  DeliveryInfoTeaser,
  DeliveryInfoTeaserProps,
} from '../../molecules/GenericImageText/variants/DeliveryInfoTeaser'

const Box = styled.div<{ hasSeparator: boolean }>`
  ${p =>
    p.hasSeparator &&
    css`
      border-right: 1px solid ${p => p.theme.colors.normalgrey};
    `}

  align-self: stretch;
  padding: 0 0.75rem;
`

export type DeliveryInfoTeaserCollectionProps = {
  items: DeliveryInfoTeaserProps[] | undefined
}

export const DeliveryInfoTeaserCollection: FunctionComponent<
  DeliveryInfoTeaserCollectionProps
> = ({ items }) => {
  const isMobile = useIsViewportMobile()
  const isTablet = useIsViewportTablet()
  const colWidth = isMobile ? 12 : isTablet ? 6 : 3

  return (
    <>
      {items && (
        <Grid hasGapV>
          {items.map((item, i) => (
            <GridArea
              colStart={(i * colWidth) % 12}
              colWidth={colWidth}
              contentAlignV="start"
              rowStart={Math.floor((i * colWidth) / 12)}
              key={item.title + i}
            >
              <Box hasSeparator={((i + 1) * colWidth) % 12 !== 0}>
                <DeliveryInfoTeaser
                  iconType={item.iconType}
                  title={item.title}
                  text={item.text}
                />
              </Box>
            </GridArea>
          ))}
        </Grid>
      )}
    </>
  )
}
