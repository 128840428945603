import { PHASE_PRODUCTION_BUILD } from 'next/constants'
import { FetchResult, fetch } from '../../util/fetch'
import { CategoryQueryResult } from './types'
import { EcomLocale } from '../../../../config/shopAPI/types'
import { LRUCache } from '../../../utils/lrucache'

const cache = new LRUCache<string, FetchResult<CategoryQueryResult>>()
// Use caching only during the build phase on the server
if (process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD) {
  cache.disable()
}

/**
 * Fetches all Categories (default limit=50) from the Shop API.
 */
export const getCategories = async (
  lcCC: EcomLocale,
  limit = 50,
  keys?: string[]
) => {
  const cacheKey = JSON.stringify({ lcCC, limit, keys: keys ? keys : null })
  const cacheResult = cache.has(cacheKey) ? cache.get(cacheKey) : undefined

  if (cacheResult) {
    return cacheResult
  }

  const response = await fetch<Record<string, unknown>, CategoryQueryResult>({
    query: `/categories?limit=${limit}`,
    lcCC,
    request: {
      where: keys ? `key in ("${keys.join('","')}")` : undefined,
    },
  })

  if (response.result === 'successful') {
    cache.put(cacheKey, response)
  }

  return response
}
