import React, { FunctionComponent, useCallback, useState } from 'react'
import { PageLayout } from '../PageLayout'
import { Section } from '../../components/atoms/Section'
import { SeoText } from '../../components/molecules/SeoText'
import { ShopAllLayoutSchema } from 'lib/contentful/datamodel/layouts'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { SectionHeadline } from '../../components/molecules/SectionHeadline'
import { Hr } from '../../components/atoms/Hr'
import { Spacing } from '@boxine/tonies-ui'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'
import { AggregatedProducts } from '../../lib/transformation/aggregateProducts'

export const ShopAllLayout: FunctionComponent<
  Aggregated<ShopAllLayoutSchema>
> = ({ pageMetaData, headline, description, seoText, products }) => {
  const [selection, setSelection] = useState<AggregatedProducts | undefined>(
    undefined
  )
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleSetSelection = useCallback(
    (selection: AggregatedProducts | undefined) => {
      if (typeof window === 'undefined') {
        return
      }

      if (selection) {
        setScrollPosition(window.scrollY)
        setSelection(selection)
        window.scroll(0, 0)
      } else {
        setSelection(undefined)
        window.scroll(0, scrollPosition)
      }
    },
    [scrollPosition]
  )

  console.log(selection)

  return (
    <PageLayout meta={pageMetaData}>
      <Section isStageSection removePaddingY={false} noGaps>
        <SectionHeadline title={headline} text={description} />
        <Spacing pb="spacing-s">
          <Hr />
        </Spacing>
        {products.map((p, index) => (
          <Spacing
            key={`${p.referenceTitle}${index}`}
            mt="spacing-xs"
            mb="spacing-s"
          >
            <ContentModels.Products
              {...p}
              showMoreOnClick={
                p.showMoreUrl ? undefined : () => handleSetSelection(p)
              }
              variant="slider"
            />
          </Spacing>
        ))}
      </Section>
      {seoText?.content && (
        <Section backgroundColor="#eee">
          <SeoText document={seoText.content} />
        </Section>
      )}
    </PageLayout>
  )
}
