import { media } from '@boxine/tonies-ui'
import React, { FunctionComponent } from 'react'
import { SectionProductCopy } from '../SectionProductCopy'
import styled, { DefaultTheme, useTheme } from 'styled-components'
import { TextHero } from '../TextHero'
import { SectionProductCopySchema } from '../../../lib/contentful/datamodel/schemas'
import { Aggregated } from '../../../lib/transformation/aggregatePage/aggregate'
import { SupportedHTMLTags } from '@boxine/tonies-ui/dest/types/src/atoms/Typography/types'
import { Curve } from '@/tonies-ui/atoms/Curve'

const CurveWrapper = styled.div`
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;

  ${media.laptop`
    padding-top: 1rem;
  `}
`

// the layout for this page has to follow a fixed design
// schema. this keeps it consisted and saves us having
// to add it as an option in contentful
function getCalculatedLayout(
  index: number,
  { colors }: DefaultTheme
): {
  contentPositionDesktop: 'left' | 'right'
  backgroundColor: DefaultTheme['colors'][
    | 'anthracite-100'
    | 'berry-100'
    | 'pink-10'
    | 'lightblue-30']
} {
  if (index % 4 === 1) {
    return {
      backgroundColor: colors['anthracite-100'],
      contentPositionDesktop: 'right',
    }
  }
  if (index % 4 === 2) {
    return {
      backgroundColor: colors['pink-10'],
      contentPositionDesktop: 'left',
    }
  }
  if (index % 4 === 3) {
    return {
      backgroundColor: colors['berry-100'],
      contentPositionDesktop: 'right',
    }
  }
  return {
    backgroundColor: colors['lightblue-30'],
    contentPositionDesktop: 'left',
  }
}

interface PromotionToniesLayoutProps {
  title: string
  headlineAsHTMLTag?: SupportedHTMLTags
  text?: string
  entries: Aggregated<SectionProductCopySchema>[]
}

export const PromotionToniesLayout: FunctionComponent<
  PromotionToniesLayoutProps
> = ({ title, headlineAsHTMLTag = 'h2', text, entries }) => {
  const theme = useTheme()

  return (
    <>
      <TextHero
        headlineAsHTMLTag={headlineAsHTMLTag}
        title={title}
        text={text}
        backgroundColor={theme.colors.white}
      />
      <CurveWrapper>
        <Curve
          color={theme.colors['lightblue-30']}
          isInverted
          layout="bottomLeft"
        />
      </CurveWrapper>
      {entries.map(
        (entry, index) =>
          entry.normalizedProduct && (
            <SectionProductCopy
              {...entry}
              {...getCalculatedLayout(index, theme)}
              key={index}
              normalizedProduct={entry.normalizedProduct}
            />
          )
      )}
    </>
  )
}
