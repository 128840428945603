import React, { FunctionComponent } from 'react'
import { useTheme } from 'styled-components'
import { TonieboxLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { PageLayout } from '../PageLayout'
import { Headline1, Spacing, useIsViewportMobile } from '@boxine/tonies-ui'
import { Section } from '../../components/atoms/Section'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { ImageText } from '../../components/organisms/ImageText'
import { VideoText } from '../../components/organisms/VideoText'
import { DeliveryInfoTeaserCollection } from '../../components/organisms/DeliveryInfoTeaserCollection'
import { SeoText } from '../../components/molecules/SeoText'
import { ImageTextCarousel } from '../../components/organisms/ImageTextCarousel'
import { useAggregatedShopLocale } from '../../providers/aggregatedShopLocale'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { ProductSelector } from '../../components/organisms/ProductSelector'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'

export const TonieboxLayout: FunctionComponent<
  Aggregated<TonieboxLayoutSchema>
> = ({
  pageMetaData,
  productList,
  frequentlyAskedQuestions,
  promotion,
  description1,
  description2,
  description3,
  description4,
  description5,
  featuresGallery,
  productItemTeaserCarousel,
  editorialTeaserList,
  seoText,
}) => {
  const aggregatedShopLocale = useAggregatedShopLocale()

  const isMobile = useIsViewportMobile()
  const theme = useTheme()

  return (
    <PageLayout meta={pageMetaData}>
      <ProductSelector
        referenceTitle={productList.referenceTitle}
        uniqueInputName="tonieboxes"
        productList={productList}
        hasProductDetails
        hasAddToCart
        curveColor={theme.colors.white}
        headline={
          <Spacing pb={isMobile ? 'spacing-xs' : 'spacing-xl'}>
            <Headline1 align="center">
              <Accent text={productList.headline} />
            </Headline1>
          </Spacing>
        }
        hasDetailLink
        isImageLink
        hasUrlReferralCodeSection
      />

      {promotion && <ContentModels.MediaCopySection {...promotion} />}

      <ImageText {...description1} textAlignMobile="left" />

      <VideoText
        videoDesktop={description2.videoDesktop.file.url}
        videoMobile={description2.videoMobile.file.url}
        posterDesktop={description2.posterDesktop}
        posterMobile={description2.posterMobile}
        videoAlt={description2.videoAlt}
        title={description2.title}
        headlineAsHTMLTag={description2.headlineAsHTMLTag}
        text={description2.text}
        ctaUrl={description2.ctaUrl}
        ctaText={description2.ctaText}
        contentPositionDesktop="left"
        backgroundColor={theme.colors.lightgrey}
      />

      <ImageText {...description3} textAlignMobile="left" />

      <Section>
        <ContentModels.Gallery {...featuresGallery} />
      </Section>

      <ContentModels.FrequentlyAskedQuestions {...frequentlyAskedQuestions} />

      <Section>
        {productItemTeaserCarousel?.skuList && (
          <ContentModels.ProductItemTeaserCarousel
            {...productItemTeaserCarousel}
          />
        )}
        <DeliveryInfoTeaserCollection
          items={aggregatedShopLocale.content.deliveryInfoTeaserList}
        />
      </Section>

      <ImageTextCarousel
        title={description4.title}
        headlineAsHTMLTag={description4.headlineAsHTMLTag}
        items={description4.items.map(item => ({
          ...item,
          contentPositionDesktop: 'left',
        }))}
      />

      <ImageText {...description5} textAlignMobile="left" />

      <Section
        backgroundColor={editorialTeaserList.backgroundColor}
        removePaddingX={editorialTeaserList.variant === 'slider'}
      >
        <ContentModels.EditorialTeaserList {...editorialTeaserList} />
      </Section>

      {seoText?.content && (
        <Section>
          <SeoText document={seoText.content} />
        </Section>
      )}
    </PageLayout>
  )
}
