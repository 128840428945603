import { useIsViewportMobile } from '@boxine/tonies-ui'
import { ColorContextProvider } from '@/tonies-ui/index'
import { SupportedHTMLTags } from '@boxine/tonies-ui/dest/types/src/atoms/Typography/types'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { withGTMV4Context } from '../../../providers/gtmV4'
import { WithReferenceTitle } from '../../../providers/gtmV4/types'
import { Carousel } from '../../molecules/Carousel'
import { SectionHeadline } from '../../molecules/SectionHeadline'
import { ImageText, ImageTextProps } from '../ImageText'

export type Props = {
  background?: string
  dataTestId?: string
  title: string
  headlineAsHTMLTag?: SupportedHTMLTags
  items: ImageTextProps[]
  id?: string
}

const Wrapper = styled.div<{ background?: string }>`
  background: ${({ background = 'transparent' }) => background};
`

const SectionHeadlineWrapper = styled.div`
  margin-bottom: -2rem;
`

const ImageTextCarouselContainer: FunctionComponent<
  WithReferenceTitle<Props>
> = ({
  background: bgProp,
  dataTestId = 'imageTextCarousel',
  title,
  headlineAsHTMLTag,
  items,
  id,
}) => {
  const isMobile = useIsViewportMobile()
  const background = bgProp || items[0].backgroundColor

  return (
    <ColorContextProvider backgroundColor={background}>
      <Wrapper background={background} data-testid={dataTestId} id={id}>
        <SectionHeadlineWrapper data-testid={dataTestId}>
          <SectionHeadline
            title={title}
            headlineAsHTMLTag={headlineAsHTMLTag}
          />
        </SectionHeadlineWrapper>
        <Carousel
          ariaTitle={title}
          naturalSlideWidth={320}
          visibleSlides={1}
          buttons={isMobile ? 'none' : 'center'}
          hasPagination
          isInfinite
          isIntrinsicHeight
        >
          {items.map((item, i) => (
            <ImageText
              key={i}
              {...item}
              textAlignMobile="center"
              backgroundColor={background || item.backgroundColor}
            />
          ))}
        </Carousel>
      </Wrapper>
    </ColorContextProvider>
  )
}

export const ImageTextCarousel = withGTMV4Context(
  ImageTextCarouselContainer,
  'ImageTextCarousel'
)
