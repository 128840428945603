import styled from 'styled-components'
import { media } from '@boxine/tonies-ui'

export const ImageTextWrapper = styled.div`
  margin-top: -2rem;
  > section {
    padding-top: 0;
    padding-bottom: 0;
  }
  > section > div > div {
    padding-top: 0;
    padding-bottom: 0;
    :first-child > * {
      padding-left: 25%;
      ${media.tablet`
        padding-left: 0; 
      `}
    }
  }
`
