import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
import { Section } from '../../components/atoms/Section'
import { PageLayout } from '../PageLayout'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { SeoText } from '../../components/molecules/SeoText'
import {
  Box,
  Button,
  CTARow,
  Grid,
  GridArea,
  Spacing,
  media,
  Headline4,
} from '@boxine/tonies-ui'
import { useViewportConfig } from '../../tonies-ui/hooks/useViewport'
import { BlogPage } from '../../pages/[locale]/blog/index.page'
import {
  BlogPostTeasers,
  BlogPromotedPostTeasers,
} from '../../components/molecules/GenericImageText/variants/BlogTeaser'
import { SocialMediaLinks } from '../../components/molecules/SocialMediaLinks'
import { useFilteredPosts } from './hooks/useFilteredPosts'
import { BlogFilter } from './components/BlogFilter'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'

export const StickySidebar = styled.div`
  ${media.laptop`
    position: sticky;
    top: 6rem;
  `}
`

const BlogPostTeasersWrapper = styled.div`
  display: grid;
  gap: 2rem;
  align-items: start;

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}
`

export const BlogLayout: FunctionComponent<Aggregated<BlogPage['page']>> = ({
  pageMetaData,
  stage,
  seoText,
  postTeasers,
  promotedPostTeasers,
}) => {
  const { t } = useTranslation()
  const [postToShow, setPostToShow] = useState(6)
  const { filteredPosts } = useFilteredPosts(postTeasers || [])

  const config = useViewportConfig({
    desktop: {
      columns: 12,
      content: { col: { width: 8, start: 0 }, row: { start: 0 } },
      sideBar: { col: { width: 4, start: 8 }, row: { start: 0 } },
    },
    tablet: {
      columns: 2,
      content: { col: { width: 2 }, row: { start: 0 } },
      sideBar: { col: { width: 1 }, row: { start: 1 } },
    },
    mobile: {
      columns: 1,
      content: { col: { width: 1 }, row: { start: 0 } },
      sideBar: { col: { width: 1 }, row: { start: 1 } },
    },
  })

  return (
    <PageLayout meta={pageMetaData}>
      {stage.contentTypeId === 'mediaCopySection' && (
        <ContentModels.MediaCopySection {...stage} />
      )}
      {stage.contentTypeId === 'carousel' && (
        <ContentModels.Carousel
          {...stage}
          componentProps={{ hasMarginTop: true }}
        />
      )}
      {stage.contentTypeId === 'textHero' && (
        <ContentModels.TextHero {...stage} />
      )}
      <Section>
        <Grid hasGapH hasGapV columns={config.columns}>
          {/* OVERVIEW */}
          <GridArea
            colWidth={config.content.col.width}
            rowStart={config.content.row.start}
          >
            {filteredPosts && (
              <>
                <BlogFilter posts={filteredPosts} />
                <BlogPostTeasersWrapper>
                  <BlogPostTeasers
                    entries={filteredPosts.slice(0, postToShow)}
                  />
                </BlogPostTeasersWrapper>
              </>
            )}

            {filteredPosts.length > postToShow && (
              <Spacing my="spacing-l">
                <CTARow justifyContent="center">
                  <Button
                    onClick={() => setPostToShow(curr => curr + 6)}
                    icon="arrowDown"
                  >
                    {t('blog:loadMore')}
                  </Button>
                </CTARow>
              </Spacing>
            )}
          </GridArea>

          {/* SIDEBAR */}
          <GridArea
            colWidth={config.sideBar.col.width}
            colStart={config.sideBar.col.start}
            rowStart={config.sideBar.row.start}
          >
            <StickySidebar>
              {promotedPostTeasers && promotedPostTeasers.length > 0 && (
                <Spacing mb="spacing-m">
                  <Box borderColor="lightgrey" borderRadius={['s']}>
                    <Box
                      px="spacing-s"
                      py="spacing-xs"
                      backgroundColor="lightergrey"
                    >
                      <Headline4>
                        {t('blog:highlightedPosts.headline')}
                      </Headline4>
                    </Box>
                    <Box pl="spacing-s" pt="spacing-s" pr="spacing-s">
                      <BlogPromotedPostTeasers entries={promotedPostTeasers} />
                    </Box>
                  </Box>
                </Spacing>
              )}
              <Spacing mb="spacing-m">
                <Box
                  backgroundColor="white"
                  borderColor="lightgrey"
                  borderRadius={['s']}
                >
                  <Box
                    px="spacing-s"
                    py="spacing-xs"
                    backgroundColor="lightergrey"
                  >
                    <Headline4>{t('blog:socialMedia.headline')}</Headline4>
                  </Box>
                  <Box p="spacing-s">
                    <SocialMediaLinks iconSizeInEm={2} iconGapInEm={1} />
                  </Box>
                </Box>
              </Spacing>
            </StickySidebar>
          </GridArea>
        </Grid>
        {seoText?.content && <SeoText document={seoText.content} />}
      </Section>
    </PageLayout>
  )
}
