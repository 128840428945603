import {
  Paragraph,
  Spacing,
  media,
  useIsViewportMobile,
  useIsViewportTablet,
} from '@boxine/tonies-ui'
import React, { FunctionComponent, PropsWithChildren, useCallback } from 'react'
import styled from 'styled-components'
import { ProductName } from './ProductName'
import { ProductTags } from './ProductTags'
import { ColorSelector } from '../../../components/atoms/ColorSelector'
import { LabelSelector } from '../../../components/atoms/LabelSelector'
import { useProductVariants } from '../../../hooks/useProductVariants'
import { RichText } from '../../../components/molecules/RichText'
import { useAggregatedShopLocale } from '../../../providers/aggregatedShopLocale'
import { Price } from '../../../components/atoms/Price'
import { PredefinedBundleToBundlerLink } from './PredefinedBundleToBundlerLink'
import { CreateCustomBundleAccordion } from './CreateCustomBundleAccordion'
import { useTheme } from 'styled-components'
import { useTranslation } from 'next-i18next'
import { ProductSocialShare } from './ProductSocialShare'
import { usePDPLayout } from '../hooks/usePDPLayout'
import { MainActionButton } from './MainActionButton'
import { BazaarvoiceRatingSummary } from './Bazaarvoice'

export const FloatingPanelWrapper = styled.div`
  /* Forces the product description to float while the user is scrolling */
  position: sticky;
  top: 6rem;
`

// fixes safari spacing between sections
const FixedSpacing = styled.div`
  position: relative;
  bottom: -2px;
`

const ChokingHazardWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.middlegrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.middlegrey};
`

const PredefinedBundleToBundlerLinkWrapper = styled.span`
  display: block;
  padding-top: 1rem;

  ${media.laptopL`
    display: inline-block;
    padding-top: 0;
    padding-left: 1rem;
  `}
`

export const FloatingPanelContentDefault: FunctionComponent<
  ReturnType<typeof useProductVariants<NormalizedProductExtended>>
> = ({
  activeProduct,
  colorSelectorVariants,
  labelSelectorVariants,
  setActiveProductId,
  variants,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const isTablet = useIsViewportTablet()
  const isMobile = useIsViewportMobile() || isTablet
  const {
    content: { additionalPaymentInfo },
  } = useAggregatedShopLocale()

  const handleVariantSelection = useCallback(
    (productId: string) => {
      const v = variants.find(p => p.id === productId)

      if (v?.path) {
        setActiveProductId(v.id)
        window.history.replaceState(null, '', v.path)
      }
    },
    [variants, setActiveProductId]
  )

  return (
    <>
      <ProductTags pt="spacing-xs" product={activeProduct} />
      {/*
    TODO
    This is just a workaround until we can handle "real" product variants properly
  */}
      {colorSelectorVariants.length > 1 && (
        <Spacing pt="spacing-s">
          <ColorSelector
            activeVariantId={activeProduct.id}
            variants={colorSelectorVariants}
            onChange={handleVariantSelection}
            uniqueInputName="variant-selector--color"
          />
        </Spacing>
      )}
      {labelSelectorVariants.length > 1 && (
        <Spacing pt="spacing-s">
          <LabelSelector
            label={activeProduct.variantLabel}
            activeVariantId={activeProduct.id}
            variants={labelSelectorVariants}
            onChange={handleVariantSelection}
            uniqueInputName="variant-selector--label"
          />
        </Spacing>
      )}
      {!isMobile && (
        <>
          <Spacing pt="spacing-l">
            <Price
              product={activeProduct}
              vatHint={{
                content: 'included',
                position: 'inline',
              }}
            />
            {additionalPaymentInfo && (
              <RichText
                size={4}
                linksInheritColor={true}
                document={additionalPaymentInfo}
              />
            )}
            <Spacing mt={additionalPaymentInfo ? undefined : 'spacing-s'}>
              <MainActionButton product={activeProduct} type="addToCart" />
              {activeProduct.bundledProducts &&
                activeProduct.availability.state === 'sold-out' && (
                  <PredefinedBundleToBundlerLinkWrapper>
                    <PredefinedBundleToBundlerLink
                      product={activeProduct}
                      variant="link"
                    />
                  </PredefinedBundleToBundlerLinkWrapper>
                )}
            </Spacing>
            <FixedSpacing>
              <CreateCustomBundleAccordion
                product={activeProduct}
                backgroundColor={colors.lightgrey}
                mt="spacing-m"
              />
            </FixedSpacing>
          </Spacing>
          {activeProduct.hasChokingHazardWarning && (
            <Spacing mt="spacing-m">
              <ChokingHazardWrapper>
                <Spacing pt="spacing-m" pb="spacing-m">
                  <Paragraph size={3}>
                    <Paragraph size={3} asHTMLTag="span" weight={700}>
                      {t('productDetailPage:chokingHazard:headline')}
                    </Paragraph>{' '}
                    {t('productDetailPage:chokingHazard:text')}
                  </Paragraph>
                </Spacing>
              </ChokingHazardWrapper>
            </Spacing>
          )}
        </>
      )}
    </>
  )
}

export const FloatingPanel: FunctionComponent<
  {
    product: NormalizedProductExtended
  } & PropsWithChildren
> = ({ product, children }) => {
  const isTablet = useIsViewportTablet()
  const isMobile = useIsViewportMobile() || isTablet

  return (
    <FloatingPanelWrapper>
      <Spacing
        pt={isMobile ? 'spacing-s' : undefined}
        mt={isMobile ? 'spacing-xs' : undefined}
      >
        <ProductName product={product} />
      </Spacing>
      <BazaarvoiceRatingSummary product={product} />
      {children}
      {!('socialShare' in usePDPLayout()) && (
        <Spacing mt="spacing-m">
          <ProductSocialShare
            product={product}
            justifyItems="start"
            verticalGap="small"
          />
        </Spacing>
      )}
    </FloatingPanelWrapper>
  )
}
