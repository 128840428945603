import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import {
  Headline5,
  useIsViewportMobile,
  Spacing,
  Accordion,
  AudioSample,
} from '@boxine/tonies-ui'
import { ProductCardCarousel } from '../../../components/organisms/ProductCardCarousel'
import {
  AdditionalProductInfoTable,
  BlueBoxWrapper,
} from './AdditionalProductInfo'
import { TrackList } from './TrackList'
import { FreeHtml } from '../../../components/molecules/FreeHtml'
import { PredefinedBundleToBundlerLink } from './PredefinedBundleToBundlerLink'
import { AccordionItem, ColorContextProvider } from '@/tonies-ui/index'

export const BundledProducts: FunctionComponent<{
  product: NormalizedProductExtended
}> = ({ product }) => {
  const isMobile = useIsViewportMobile()
  const { t } = useTranslation()

  const products = product.bundledProductsExtended

  if (!products) {
    return null
  }

  return (
    <>
      <Spacing pt={isMobile ? 'spacing-s' : 'spacing-l'}>
        <Headline5 asHTMLTag="h3">
          {t('productDetailPage:bundledProducts')}
        </Headline5>
      </Spacing>
      <Spacing pt="spacing-s">
        <ProductCardCarousel
          referenceTitle="[coded] Bundled products"
          items={products}
          showOutOfStock={true}
        />
      </Spacing>
      <Spacing pt="spacing-s">
        <PredefinedBundleToBundlerLink
          product={product}
          variant="textWithLink"
        />
      </Spacing>
      <Spacing pt="spacing-s">
        <Accordion borderRadius={undefined} px={undefined}>
          {products.map(p => (
            <AccordionItem key={p.sku} title={p.name}>
              <>
                <Spacing pb="spacing-l">
                  <Spacing pb="spacing-xs">
                    <Headline5 asHTMLTag="h3">
                      {t('productDetailPage:content')}
                    </Headline5>
                  </Spacing>
                  {p.audioSampleUrl && (
                    // TODO
                    // add GA event 'audioSample'
                    <Spacing pb="spacing-xs">
                      <AudioSample url={p.audioSampleUrl} loading="lazy" />
                    </Spacing>
                  )}
                  <FreeHtml
                    html={p.description || ''}
                    pSize={isMobile ? 1 : 2}
                    pWeight={300}
                  />
                </Spacing>
                {p.tracks && p.tracks.length && (
                  <Spacing pb="spacing-xl">
                    <TrackList tracks={p.tracks} />
                  </Spacing>
                )}
                <ColorContextProvider backgroundColor="lightblue-20">
                  <BlueBoxWrapper backgroundColor="lightblue-20">
                    <AdditionalProductInfoTable product={p} />
                  </BlueBoxWrapper>
                </ColorContextProvider>
              </>
            </AccordionItem>
          ))}
        </Accordion>
      </Spacing>
    </>
  )
}
