import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useAuth } from '../../providers/auth'
import { OrderSuccessLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { useEcomLocale } from '../../providers/locale'
import { getOrder } from '../../lib/commercetools/requests/orders/getOrder'
import { BxOrder } from '../../lib/commercetools/requests/bxTypes'
import { LoadingLayout } from '../LoadingLayout'
import { ErrorLayout } from '../ErrorLayout'
import { Page } from './components/Page'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { useUrlQuery } from '../../providers/urlQuery'

export const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 20rem;
  justify-content: center;
`

export const OrderSuccessLayout: FunctionComponent<
  Aggregated<OrderSuccessLayoutSchema>
> = ({
  pageMetaData,
  customerCarePicture,
  customerCareInfo,
  customerCareInfoTunesOnly,
  customerCareInfoRepair,
  editorialTeaserList,
}) => {
  const { user, isPending } = useAuth()
  const lcCC = useEcomLocale()
  const [order, setOrder] = useState<BxOrder | undefined>(undefined)
  const shouldFetchOrder = useRef(true)
  const [isNotFound, setIsNotFound] = useState(false)
  const orderId = useUrlQuery().getUrlParamAsSingleton('orderId')

  // Fetch order details
  useEffect(() => {
    if (shouldFetchOrder.current && !isPending) {
      shouldFetchOrder.current = false

      if (orderId) {
        getOrder(lcCC, orderId).then(response => {
          if (response.result === 'successful') {
            setOrder(response.data)
          } else {
            setIsNotFound(true)
          }
        })
      } else {
        setIsNotFound(true)
      }
    }
  }, [orderId, isPending, lcCC])

  return order ? (
    <Page
      contentTypeId="orderSuccessPage"
      order={order}
      user={user}
      pageMetaData={{
        ...pageMetaData,
        isCacheable: false,
        includeInSitemapXml: false,
      }}
      customerCarePicture={customerCarePicture}
      customerCareInfo={customerCareInfo}
      customerCareInfoTunesOnly={customerCareInfoTunesOnly}
      customerCareInfoRepair={customerCareInfoRepair}
      editorialTeaserList={editorialTeaserList}
      referenceTitle=""
    />
  ) : isNotFound ? (
    <ErrorLayout hasHeader statusCode={404} />
  ) : (
    <LoadingLayout
      pageMetaData={{
        ...pageMetaData,
        isCacheable: false,
        includeInSitemapXml: false,
      }}
    />
  )
}
