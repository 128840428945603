import React, { FunctionComponent, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { CardShared } from '@boxine/tonies-ui'
import { ColorContextProvider } from '@/tonies-ui/index'

const Container = styled.div<{ focus?: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  ${CardShared.CardInner}
  display: block;
  overflow: visible; // for CustomerServiceInfo Header
  position: relative;
  /*
   * .5rem to offset progress bar which somehow is still part of
   * the parent flex measurements...
   */
  transition: border-radius 0.5s;
  transition-delay: 0.5s;
  animation: ${({ focus, theme }) => {
    return focus
      ? `${theme.animation.heartBeat.duration} ${theme.animation.heartBeat.timingFunction} ${theme.animation.heartBeat.delay} heartBeat`
      : 'none'
  }};
  @keyframes heartBeat {
    ${({ theme }) => theme.animation.heartBeat.keyframes}
  }
`

// FIXME: avoid overwriting internal component styles
const CardOuter = styled(CardShared.CardOuter)`
  &::after {
    background: ${props => props.theme.colors['anthracite-30']};
    opacity: 0.4;
  }
`

// FIXME: avoid overwriting internal component styles
const CardShadow = styled(CardShared.CardShadow)`
  &::after {
    background: ${props => props.theme.colors['anthracite-30']};
    opacity: 0.4;
  }
`
export type WhiteBoxProps = {
  focus?: boolean
}

export const WhiteBox: FunctionComponent<WhiteBoxProps & PropsWithChildren> = ({
  children,
  focus = false,
}) => (
  <ColorContextProvider backgroundColor="#fff">
    <CardOuter>
      <CardShadow>
        <Container focus={focus}>{children}</Container>
      </CardShadow>
    </CardOuter>
  </ColorContextProvider>
)
