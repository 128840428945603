import { Alignment } from '@boxine/tonies-ui/dest/types/components/Grid/components/GridArea'
import { useViewportConfig } from '../../../tonies-ui/hooks/useViewport'

export const usePDPLayout = () =>
  useViewportConfig({
    mobile: {
      breadcrumbs: {
        rowStart: 1,
        colWidth: 12,
      },
      gallery: {
        rowStart: 2,
        colWidth: 12,
      },
      description: {
        rowStart: 3,
        colWidth: 12,
      },
      content: {
        rowStart: 4,
        colWidth: 12,
      },
      tracks: {
        rowStart: 5,
        colWidth: 12,
      },
      additionalProductInfo: {
        rowStart: 6,
        colWidth: 12,
      },
      socialShare: {
        rowStart: 7,
        colWidth: 12,
      },
    },
    tablet: {
      breadcrumbs: {
        rowStart: 1,
        colWidth: 12,
      },
      gallery: {
        rowStart: 2,
        colWidth: 12,
      },
      description: {
        rowStart: 3,
        colWidth: 12,
      },
      content: {
        rowStart: 4,
        colWidth: 12,
      },
      tracks: {
        rowStart: 5,
        colWidth: 12,
      },
      additionalProductInfo: {
        rowStart: 6,
        colWidth: 12,
      },
      socialShare: {
        rowStart: 7,
        colWidth: 12,
      },
    },
    desktop: {
      breadcrumbs: {
        rowStart: 1,
        colWidth: 12,
      },
      gallery: {
        rowStart: 2,
        colStart: 0,
        colWidth: 8,
      },
      description: {
        rowStart: 2,
        rowHeight: 99,
        colStart: 8,
        colWidth: 4,
        contentAlignV: 'start' as Alignment,
      },
      content: {
        rowStart: 3,
        colWidth: 8,
      },
      tracks: {
        rowStart: 4,
        colWidth: 8,
      },
      additionalProductInfo: {
        rowStart: 5,
        colWidth: 8,
      },
    },
  })
