import { PHASE_PRODUCTION_BUILD } from 'next/constants'
import { getCategories } from '../categories/getCategories'
import { fetch, FetchResult } from '../../util/fetch'
import { stringify } from '../../util/stringify'
import { EcomLocale } from '../../../../config/shopAPI/types'
import { ProductQueryResult } from './types'
import { getCurrencyCodeByLocale } from '../../../../config/shopAPI'
import { LRUCache } from '../../../utils/lrucache'

const cache = new LRUCache<string, FetchResult<ProductQueryResult>>()
// Use caching only during the build phase on the server
if (process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD) {
  cache.disable()
}

async function getProducts(
  request: Record<string, string>,
  lcCC: EcomLocale
): Promise<FetchResult<ProductQueryResult>> {
  const cacheKey = JSON.stringify({ request, lcCC })
  const cacheResult = cache.has(cacheKey) ? cache.get(cacheKey) : undefined

  if (cacheResult) {
    return cacheResult
  }

  try {
    // Submit query
    const response = await fetch<Record<string, unknown>, ProductQueryResult>({
      query: '/products',
      lcCC,
      request,
    })

    // If request succeeded and currency was specified: exclude products without a price
    if (response.result === 'successful') {
      response.data.results = response.data.results.filter(
        r => !!r.masterData.current?.masterVariant.price
      )
      cache.put(cacheKey, response)
    }

    // Return results
    return response
  } catch (error: unknown) {
    console.error(`Request ${request.where} failed`, error)

    return {
      result: 'request-failed',
      error: error instanceof Error ? error : new Error('Unknown error'),
    }
  }
}

export const getProductsByCategory = async (
  lcCC: EcomLocale,
  categoryKeys?: string[], // optionally filter products by category on the server side
  limit = 500,
  isSalesPage = false,
  useAndWhere = false
): Promise<FetchResult<ProductQueryResult>> => {
  // Prepare query parameters
  const priceCountry = lcCC.substring(3, 5)

  const request: Record<string, string> = {
    expand: 'masterData.current.categories[*].parent',
    limit: limit.toString(),
    priceCountry,
    priceCurrency: getCurrencyCodeByLocale(lcCC),
  }

  // Category keys must be resolved to category IDs for use in `where=`

  const categoryIds: string[] = []

  if (categoryKeys) {
    const response = await getCategories(
      lcCC,
      categoryKeys.length,
      categoryKeys
    )

    if (response.result !== 'successful') {
      // Bail out if the request to resolve the category has failed
      return response
    }

    for (const category of response.data.results) {
      categoryIds.push(category.id)
    }
  }

  if (categoryIds.length) {
    if (useAndWhere) {
      // get products that have ALL categories (AND)
      request.where = categoryIds
        .map(id => `masterData(current(categories(id = "${id}")))`)
        .join(' AND ')
    } else {
      // get products that have ANY category (OR)
      const categoryIdList = stringify(categoryIds)
      request.where = `masterData(current(categories(id IN ${categoryIdList})))`
    }
  }

  // UK has no prices.country but "Any"
  if (priceCountry !== 'GB') {
    request.where += ` AND masterData(current(masterVariant(prices(country="${priceCountry}"))))`
  }

  if (isSalesPage) {
    if (priceCountry === 'GB') {
      request.where += ` AND masterData(current(masterVariant(prices(discounted(value(centAmount>0))))))`
    } else {
      request.where += ` AND masterData(current(masterVariant(prices(country="${priceCountry}" AND discounted(value(centAmount>0))))))`
    }
  }

  return getProducts(request, lcCC)
}
