import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Paragraph, Spacing, Button } from '@boxine/tonies-ui'
import { useTranslation } from 'next-i18next'
import { PaymentType } from '../../../providers/payment/types'
import { useCheckout } from '../../../hooks/useCheckout'
import { PaymentIcon } from '../../atoms/PaymentIcon'
import { PaymentIconName } from 'providers/payment/lib'

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'a b c';
  align-items: center;
`

const StyledIcon = styled.div`
  grid-area: a;
  margin-right: 1rem;
`

// FIXME: avoid overwriting internal component styles
const StyledButton = styled(Button)`
  grid-area: c;
`

const StyledNoPayment = styled.div`
  grid-area: a;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export type PaymentInfoTeaserProps = {
  paymentType?: PaymentType
  handleEdit?: (() => void) | undefined
  dataTestId?: string
  hasNoPaymentNecessaryButton?: boolean
}

export const PaymentInfoTeaser: FunctionComponent<PaymentInfoTeaserProps> = ({
  paymentType,
  handleEdit,
  dataTestId = 'payment-info-teaser',
  hasNoPaymentNecessaryButton = true,
}) => {
  const { t } = useTranslation()
  const { hasPlacedOrder, setHasPlacedOrder } = useCheckout()
  return (
    <div data-testid={dataTestId}>
      {paymentType ? (
        <Container>
          <StyledIcon>
            <PaymentIcon icon={paymentType.toLowerCase() as PaymentIconName} />
          </StyledIcon>
          <Paragraph size={3}>
            {t(`checkout:payment:type-${paymentType}`)}
          </Paragraph>
          {handleEdit && (
            <StyledButton variant="secondary" onClick={handleEdit}>
              {t('checkout:payment:edit')}
            </StyledButton>
          )}
        </Container>
      ) : (
        <>
          <StyledNoPayment data-testid="payment-unnecessary">
            <Paragraph size={3}>{t('checkout:payment:unnecessary')}</Paragraph>
          </StyledNoPayment>
          {hasNoPaymentNecessaryButton && (
            <Spacing
              mb="spacing-m"
              ml="spacing-s"
              mr="spacing-s"
              mt="spacing-s"
            >
              <ButtonWrapper>
                <Button
                  disabled={hasPlacedOrder}
                  onClick={() => setHasPlacedOrder(true)}
                  data-testid="checkout-place-order-button"
                >
                  {t('checkout:summary:place-free-order')}
                </Button>
              </ButtonWrapper>
            </Spacing>
          )}
        </>
      )}
    </div>
  )
}
