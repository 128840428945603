import { Headline2, Spacing } from '@boxine/tonies-ui'
import React, { FunctionComponent } from 'react'
import { Section } from '../../components/atoms/Section'
import { PodcastLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { PageLayout } from '../PageLayout'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { PodigeePodcastPlayer } from '../../components/molecules/PodigeePodcastPlayer'
import { ImageText } from '../../components/organisms/ImageText'
import { ImageTextCarousel } from '../../components/organisms/ImageTextCarousel'
import { SeoText } from '../../components/molecules/SeoText'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'

export const PodcastLayout: FunctionComponent<
  Aggregated<PodcastLayoutSchema>
> = ({
  pageMetaData,
  stage,
  latestEntry,
  imageText,
  imageTextCarousel,
  archiveHeadline,
  archiveEntries,
  editorialTeaserList,
  seoText,
}) => {
  return (
    <PageLayout meta={pageMetaData}>
      <ContentModels.MediaCopySection
        {...stage}
        componentProps={{ hasMarginTop: true }}
      />

      {/* Latest Podcast Episode */}
      <ImageText {...latestEntry}>
        <PodigeePodcastPlayer episodeUrl={latestEntry.episodeUrl} />
      </ImageText>

      {latestEntry.toniesInEpisode && (
        <Section backgroundColor={latestEntry.backgroundColor}>
          <ContentModels.ProductItemTeaserCarousel
            {...latestEntry.toniesInEpisode}
          />
        </Section>
      )}

      <ImageText {...imageText} />

      <ImageTextCarousel {...imageTextCarousel} />

      {/* Podcast Archive */}
      <Spacing my="spacing-xl">
        <Headline2 align="center">
          <Accent text={archiveHeadline} />
        </Headline2>
      </Spacing>

      {archiveEntries.map(entry => (
        <span key={entry.episodeUrl}>
          <ImageText {...entry}>
            <PodigeePodcastPlayer episodeUrl={entry.episodeUrl} />
          </ImageText>
          {entry.toniesInEpisode && (
            <Section backgroundColor={entry.backgroundColor}>
              <ContentModels.ProductItemTeaserCarousel
                {...entry.toniesInEpisode}
              />
            </Section>
          )}
        </span>
      ))}

      <Section
        backgroundColor={editorialTeaserList.backgroundColor}
        removePaddingX={editorialTeaserList.variant === 'slider'}
      >
        <ContentModels.EditorialTeaserList {...editorialTeaserList} />
      </Section>

      {seoText?.content && (
        <Section>
          <SeoText document={seoText.content} />
        </Section>
      )}
    </PageLayout>
  )
}
