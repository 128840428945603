import React from 'react'
import IframeResizer from 'iframe-resizer-react'

export const PodigeePodcastPlayer = ({
  episodeUrl,
}: {
  episodeUrl: string
}) => (
  <IframeResizer
    src={`https://cdn.podigee.com/podcast-player/podigee-podcast-player.html?configuration=https://aller-guten-tonies-sind-drei.podigee.io/${episodeUrl}/embed?context=external`}
    style={{ width: '1px', minWidth: '100%', margin: 0 }}
    frameBorder="0"
  />
)
