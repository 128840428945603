import React, { FunctionComponent } from 'react'
import {
  media,
  Paragraph,
  Headline5,
  useIsViewportMobile,
  useIsViewportDesktop,
  Grid,
  Spacing,
  Accordion,
  List,
  ListItem,
} from '@boxine/tonies-ui'
import styled, { DefaultTheme } from 'styled-components'
import { useTranslation } from 'next-i18next'
import { Markdown } from '../../../components/molecules/Markdown'
import { AccordionItem, ColorContextProvider } from '@/tonies-ui/index'

const Wrapper = styled.div`
  display: grid;
`

type BlueBoxWrapperProps = {
  backgroundColor: keyof DefaultTheme['colors']
}

export const BlueBoxWrapper = styled.div<BlueBoxWrapperProps>`
  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor]};
  padding: 1rem;
  strong {
    font-weight: 500;
  }

  ${media.laptop`
    padding: 1.5rem;
  `}
`

const StyledGrid = styled(Grid)`
  grid-row-gap: 1rem;

  ${media.laptop`
    grid-row-gap: 1.5rem;
  `}
`

const StyledColumn = styled.div`
  grid-column: 1 / 12;

  ${media.tablet`
    grid-column: auto / span 4;
  `}
`

/**
 * FIXME
 * Add link formatting to ListItem
 */
const StyledListItem = styled(ListItem)`
  a {
    font-size: inherit;
    font-weight: inherit;
    color: ${({ theme }) => theme.link.color};
  }
`

const additionalProductAttributes: (keyof NormalizedProductExtended)[] = [
  'ageMin',
  'authors', // Tunes only attribute
  'colors',
  'dimensions',
  'commonAttributes',
  'packageContents',
  'production',
  'readers',
  'runTime',
  'variantLabel',
  'variantSelectValue',
  'variantType',
  'weight',
]

const hasAdditionalProductInfoForTable = (product: NormalizedProductExtended) =>
  additionalProductAttributes.some(key => product[key])

export const AdditionalProductInfoTable: FunctionComponent<{
  product: NormalizedProductExtended
  hasCompatibleCreativeTonies?: boolean
  hasCompatibleTonies?: boolean
}> = ({ product, hasCompatibleCreativeTonies, hasCompatibleTonies }) => {
  const { t } = useTranslation()
  const isMobile = useIsViewportMobile()
  const {
    ageMin,
    colors,
    dimensions,
    commonAttributes,
    packageContents,
    production,
    readers,
    runTime,
    variantLabel,
    variantSelectValue,
    variantType,
    weight,
  } = product

  return (
    <StyledGrid hasGapH hasGapV>
      {typeof runTime === 'number' && (
        <StyledColumn>
          <Headline5>
            {t('productDetailPage:additionalProductInfo:runTime')}
          </Headline5>
          <Paragraph size={isMobile ? 1 : 2}>
            {t('productDetailPage:additionalProductInfo:runTimeValue', {
              minutes: runTime,
            })}
          </Paragraph>
        </StyledColumn>
      )}
      {typeof ageMin === 'number' && (
        <StyledColumn>
          <Headline5>
            {t('productDetailPage:additionalProductInfo:ageMin')}
          </Headline5>
          <Paragraph size={isMobile ? 1 : 2}>
            {t('productDetailPage:additionalProductInfo:ageMinValue', {
              years: ageMin,
            })}
          </Paragraph>
        </StyledColumn>
      )}
      {/* TODO: remove `readers` when `production` is implemented */}
      {readers && !production && (
        <StyledColumn>
          <Headline5>
            {t('productDetailPage:additionalProductInfo:readers')}
          </Headline5>
          <Paragraph size={isMobile ? 1 : 2}>{readers}</Paragraph>
        </StyledColumn>
      )}
      {variantType === 'select' && variantSelectValue && (
        <StyledColumn>
          <Headline5>{variantLabel}</Headline5>
          <Paragraph size={isMobile ? 1 : 2}>{variantSelectValue}</Paragraph>
        </StyledColumn>
      )}
      {dimensions && (
        <StyledColumn>
          <Headline5>
            {t('productDetailPage:additionalProductInfo:dimensions')}
          </Headline5>
          <Paragraph size={isMobile ? 1 : 2}>{dimensions}</Paragraph>
        </StyledColumn>
      )}
      {weight && (
        <StyledColumn>
          <Headline5>
            {t('productDetailPage:additionalProductInfo:weight')}
          </Headline5>
          <Paragraph size={isMobile ? 1 : 2}>{weight}</Paragraph>
        </StyledColumn>
      )}
      {colors && (
        <StyledColumn>
          <Headline5>
            {t('productDetailPage:additionalProductInfo:colors')}
          </Headline5>
          <Paragraph size={isMobile ? 1 : 2}>{colors}</Paragraph>
        </StyledColumn>
      )}
      {commonAttributes &&
        /**
         * commonAttributes will have to be separated by `---` (hr) in
         * commercetools so we can split them up here to grid columns
         */
        commonAttributes.split('---').map((md, i) => {
          return (
            <StyledColumn key={'commonPdpAttribute' + i}>
              <Markdown md={md} textsSize={isMobile ? 1 : 2} />
            </StyledColumn>
          )
        })}
      {packageContents && (
        <StyledColumn>
          <Headline5>
            {t('productDetailPage:additionalProductInfo:packageContents')}
          </Headline5>
          <Markdown md={packageContents} textsSize={isMobile ? 1 : 2} />
        </StyledColumn>
      )}
      {(hasCompatibleCreativeTonies || hasCompatibleTonies) && (
        <StyledColumn>
          <Headline5>
            {t('productDetailPage:additionalProductInfo:tunes:compatibleTo')}
          </Headline5>
          <List size={isMobile ? 1 : 2}>
            {hasCompatibleTonies && (
              <StyledListItem>
                <a href="#compatible-tonies">
                  {t(
                    'productDetailPage:additionalProductInfo:tunes:compatibleTonies'
                  )}
                </a>
              </StyledListItem>
            )}
            {hasCompatibleCreativeTonies && (
              <StyledListItem>
                <a href="#compatible-creative-tonies">
                  {t(
                    'productDetailPage:additionalProductInfo:tunes:compatibleCreativeTonies'
                  )}
                </a>
              </StyledListItem>
            )}
          </List>
        </StyledColumn>
      )}
    </StyledGrid>
  )
}

export const AdditionalProductInfo: FunctionComponent<{
  product: NormalizedProductExtended
  hasCompatibleCreativeTonies?: boolean
  hasCompatibleTonies?: boolean
}> = ({ product, hasCompatibleCreativeTonies, hasCompatibleTonies }) => {
  const { t } = useTranslation()
  const isMobile = useIsViewportMobile()
  const isDesktop = useIsViewportDesktop()
  const { authors, copyrights, production } = product

  const hasAdditionalProductInfo =
    authors || // Tunes only attribute
    production ||
    copyrights ||
    hasAdditionalProductInfoForTable(product)

  if (!hasAdditionalProductInfo) {
    return null
  } else {
    return (
      <Wrapper>
        <ColorContextProvider backgroundColor="lightblue-20">
          <BlueBoxWrapper backgroundColor="lightblue-20">
            <AdditionalProductInfoTable
              product={product}
              hasCompatibleCreativeTonies={hasCompatibleCreativeTonies}
              hasCompatibleTonies={hasCompatibleTonies}
            />
          </BlueBoxWrapper>
        </ColorContextProvider>

        {(production || copyrights || authors) && (
          <Spacing mt="spacing-m">
            <Accordion px={isDesktop ? undefined : 'spacing-s'}>
              {(production || authors) && (
                <AccordionItem
                  key="pdp-accordion-production"
                  title={t(
                    'productDetailPage:additionalProductInfo:production'
                  )}
                >
                  {production && (
                    <Markdown
                      md={production}
                      textsSize={isMobile ? 1 : 2}
                      headlinesMarginBottom="spacing-xs"
                      textsMarginBottom="spacing-xs"
                    />
                  )}
                  {authors && (
                    <Spacing mb="spacing-xs">
                      <Paragraph size={2}>
                        {`${t(
                          'productDetailPage:additionalProductInfo:authors'
                        )}: ${authors.join(', ')}`}
                      </Paragraph>
                    </Spacing>
                  )}
                </AccordionItem>
              )}
              {copyrights && (
                <AccordionItem
                  key="pdp-accordion-copyrights"
                  title={t(
                    'productDetailPage:additionalProductInfo:copyrights'
                  )}
                >
                  <Markdown
                    md={copyrights}
                    textsSize={isMobile ? 1 : 2}
                    headlinesMarginBottom="spacing-xs"
                    textsMarginBottom="spacing-xs"
                  />
                </AccordionItem>
              )}
            </Accordion>
          </Spacing>
        )}
      </Wrapper>
    )
  }
}
