import dynamic from 'next/dynamic'
import React, { FunctionComponent, useCallback } from 'react'
import { Spacing } from '@boxine/tonies-ui'
import { ProductOverviewLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { PageLayout } from '../PageLayout'
import { Section } from '../../components/atoms/Section'
import { DeliveryInfoTeaserCollection } from '../../components/organisms/DeliveryInfoTeaserCollection'
import { useActiveTabNavigationItemByUrl } from '../../hooks/useTabNavigation'
import { SeoText } from '../../components/molecules/SeoText'
import { ProductOverviewProvider } from '../../providers/productOverview/ProductOverviewProvider'
import { ProductOverview } from '../../components/organisms/ProductOverview'
import { useAggregatedShopLocale } from '../../providers/aggregatedShopLocale'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { TunesGeoIpWarning } from '../../components/molecules/Warning'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'
import { useMeasurements } from '../../providers/measurements'

// FIXME:
// temp disable SSR to avoid render flash on mobile
const StickyTabNavigation = dynamic(
  () =>
    import('../../components/organisms/StickyTabNavigation').then(
      mod => mod.StickyTabNavigation
    ),
  {
    ssr: false,
  }
)

export const ProductOverviewLayout: FunctionComponent<
  Aggregated<ProductOverviewLayoutSchema>
> = ({
  pageMetaData,
  tabNavigation,
  productList,
  seoText,
  promotions,
  isSalesPage,
  text,
  textHero,
}) => {
  const aggregatedShopLocale = useAggregatedShopLocale()
  const { mainNavigationHeight } = useMeasurements()
  const hasTabNav = tabNavigation.entries.length > 1
  const isStageSection = !hasTabNav
  const activeTabNavigationItemByUrl = useActiveTabNavigationItemByUrl(
    tabNavigation.entries
  )

  const getCardAction = useCallback((_sku: string) => 'addToCart' as const, [])

  let productOverview = <></>

  if (productList.contentTypeId === 'products') {
    productOverview = (
      <ContentModels.Products
        {...productList}
        stickyOffset={mainNavigationHeight + (hasTabNav ? 64 : 0)}
      />
    )
  } else {
    const {
      categoryKey,
      headline,
      normalizedProducts,
      aggregations = [],
      total = 0,
      parameter,
    } = productList

    const hasFilters = ['tonies', 'tunes'].includes(categoryKey || '')

    productOverview = (
      <>
        {categoryKey === 'tunes' && <TunesGeoIpWarning />}
        <ProductOverviewProvider
          productsFetchConfig={{
            api: 'openSearch',
            initialState: {
              aggregations,
              products: normalizedProducts,
              total,
              parameter,
            },
          }}
          shopCategory={categoryKey}
          hasLoadMoreButton
          isSalesPage={isSalesPage}
          promotions={promotions}
          statePersistenceMechanism="useUrlState"
          hasScrollBehaviorOnChange
        >
          <ProductOverview
            getCardAction={getCardAction}
            hasPrices
            headline={headline}
            text={text}
            stickyFilter={{
              isActive: true,
              stickyOffset: mainNavigationHeight + (hasTabNav ? 64 : 0),
            }}
            hasFilters={hasFilters}
            hasSearch={hasFilters}
            hasSorting={hasFilters}
            hasTopFilters={hasFilters}
          />
        </ProductOverviewProvider>
      </>
    )
  }

  return (
    <PageLayout meta={pageMetaData}>
      {hasTabNav && (
        <StickyTabNavigation
          {...tabNavigation}
          {...activeTabNavigationItemByUrl}
        />
      )}
      <Section
        isStageSection={isStageSection}
        removePaddingY={!isStageSection}
        noGaps
      >
        <Spacing mt={isStageSection ? undefined : 'spacing-m'}>
          {productOverview}
        </Spacing>
      </Section>
      {textHero && (
        <Spacing mt="spacing-l">
          <ContentModels.TextHero {...textHero} />
        </Spacing>
      )}
      <Section>
        <DeliveryInfoTeaserCollection
          items={aggregatedShopLocale.content.deliveryInfoTeaserList}
        />
      </Section>
      {seoText?.content && (
        <Section backgroundColor="#eee">
          <SeoText document={seoText.content} />
        </Section>
      )}
    </PageLayout>
  )
}
