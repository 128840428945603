import React, { FunctionComponent } from 'react'
import { Section } from '../../components/atoms/Section'
import { SeoText } from '../../components/molecules/SeoText'
import { ImageText } from '../../components/organisms/ImageText'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'
import { ImageTextLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { PageLayout } from '../PageLayout'

export const ImageTextLayout: FunctionComponent<
  Aggregated<ImageTextLayoutSchema>
> = ({ textHero, entries, pageMetaData, editorialTeaserList, seoText }) => {
  return (
    <PageLayout meta={pageMetaData}>
      <ContentModels.TextHero {...textHero} />
      {entries.map((imageText, index) => {
        return (
          <ImageText
            key={imageText.imageMobile.file.url + index}
            {...imageText}
          />
        )
      })}

      {editorialTeaserList && (
        <Section
          backgroundColor={editorialTeaserList.backgroundColor}
          removePaddingX={editorialTeaserList.variant === 'slider'}
        >
          <ContentModels.EditorialTeaserList {...editorialTeaserList} />
        </Section>
      )}

      {seoText?.content && (
        <Section>
          <SeoText document={seoText.content} />
        </Section>
      )}
    </PageLayout>
  )
}
