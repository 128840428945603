import React, { FunctionComponent } from 'react'

import { Section } from '../../components/atoms/Section'
import { FullWidthMedia } from '../../components/organisms/FullWidthMedia'
import { ImageText } from '../../components/organisms/ImageText'
import { CareerLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { PageLayout } from '../PageLayout'
import { SeoText } from '../../components/molecules/SeoText'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'
import { Carousel } from '../../components/organisms/Carousel'

export const CareerLayout: FunctionComponent<
  Aggregated<CareerLayoutSchema>
> = ({
  pageMetaData,
  stage,
  gallery,
  imageText1,
  imageText2,
  textHero,
  fullWidthImage,
  testimonialCarousel,
  textHero2,
  fullWidthImage2,
  teams,
  editorialTeaserList,
  seoText,
}) => {
  return (
    <PageLayout meta={pageMetaData}>
      <ContentModels.MediaCopySection
        {...stage}
        componentProps={{ hasMarginTop: true }}
      />

      <Section>
        <ContentModels.Gallery {...gallery} />
      </Section>

      <ImageText {...imageText1} />

      <ImageText {...imageText2}>
        <a
          href="https://www.kununu.com/de/tonies?utm_source=widget&utm_campaign=widget_selfservice_scoreandreview"
          target="_blank"
          rel="nofollow noreferrer"
        >
          {/* reverted this to a normal image, since the source from kununu is unusable with our Image and results in a grey square */}
          <img
            src="https://www.kununu.com/de/partner/KlVeCVNbUw%3D%3D/self-service-button?button-type=2"
            alt="kununu"
          />
        </a>
      </ImageText>

      {textHero && <ContentModels.TextHero {...textHero} />}

      <FullWidthMedia {...fullWidthImage} />

      <Carousel hasSidePadding isContentWidth items={testimonialCarousel} />

      {textHero2 && <ContentModels.TextHero {...textHero2} />}

      {fullWidthImage2 && <FullWidthMedia {...fullWidthImage2} />}

      <ContentModels.FrequentlyAskedQuestions {...teams} />

      <Section
        backgroundColor={editorialTeaserList.backgroundColor}
        removePaddingX={editorialTeaserList.variant === 'slider'}
      >
        <ContentModels.EditorialTeaserList {...editorialTeaserList} />
      </Section>

      {seoText?.content && (
        <Section>
          <SeoText document={seoText.content} />
        </Section>
      )}
    </PageLayout>
  )
}
