import { useIsViewportDesktop } from '@boxine/tonies-ui'
import { theme } from '../../../tonies-ui/themes/theme'
import React, {
  FunctionComponent,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { Section } from '../../atoms/Section'
import { FullWidthMediaSchema } from '../../../lib/contentful/datamodel/schemas'
import styled from 'styled-components'
import { Aggregated } from '../../../lib/transformation/aggregatePage/aggregate'
import { useDisplayResolution } from '../../../hooks/useDisplayResulotion'
import {
  isOfSchema,
  imageSchema,
  videoSchema,
} from '../../../lib/contentful/datamodel/schemas'
import { Curve } from '@/tonies-ui/atoms/Curve'
import { ToniesMotions } from '@/tonies-ui/motions'

const CurveWrapper = styled.div`
  position: absolute;
  z-index: 5;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

const Video = styled.video`
  position: absolute;
  z-index: 4;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const FullWidthMedia: FunctionComponent<
  Aggregated<FullWidthMediaSchema>
> = ({
  imageMobile,
  imageDesktop,
  hasCurve,
  curveLayout = 'topLeft',
  curveColor,
  id,
}) => {
  const isDesktop = useIsViewportDesktop()
  const { isHighRes } = useDisplayResolution()
  const videoRef = useRef<HTMLVideoElement>(null)

  const currentMedia = isDesktop ? imageDesktop : imageMobile
  const isVideo = isOfSchema(currentMedia, videoSchema)
  const [heightPercent, setHeightPercent] = useState(isVideo ? 56 : 0)

  const url = currentMedia.file.url

  useEffect(() => {
    if (isOfSchema(currentMedia, imageSchema)) {
      const p =
        (currentMedia.file.details.image.height /
          currentMedia.file.details.image.width) *
        100
      setHeightPercent(p)
    }
  }, [currentMedia])

  const handleOnLoadedMetadata = useCallback(() => {
    if (videoRef.current) {
      const p =
        (videoRef.current.videoHeight / videoRef.current.videoWidth) * 100
      setHeightPercent(p)
    }
  }, [setHeightPercent, videoRef])

  return (
    <ToniesMotions.DefaultMotionWrapper data-testid="fullWidthMedia">
      <ToniesMotions.ZoomIt initial="zoomIn" viewport={{ amount: 0.25 }}>
        <Section
          backgroundImage={
            !isVideo
              ? {
                  image: url,
                  width: isDesktop
                    ? isHighRes
                      ? theme.screen4k
                      : theme.screenLaptopL
                    : isHighRes
                    ? theme.screenMobileXL
                    : theme.screenMobileL,
                }
              : undefined
          }
          removePaddingY={isVideo}
          aspectRatio={{
            ratio: heightPercent,
          }}
          id={id}
        >
          {isVideo && (
            <Video
              ref={videoRef}
              autoPlay
              loop
              muted
              playsInline
              controls={false}
              src={url}
              onLoadedMetadata={handleOnLoadedMetadata}
            />
          )}
          {hasCurve && (
            <CurveWrapper>
              <Curve
                color={curveColor ?? '#fff'}
                layout={curveLayout}
                isInverted
              />
            </CurveWrapper>
          )}
        </Section>
      </ToniesMotions.ZoomIt>
    </ToniesMotions.DefaultMotionWrapper>
  )
}
