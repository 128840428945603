import { media } from '@boxine/tonies-ui'
import React from 'react'
import styled from 'styled-components'
import { Image } from '@/tonies-ui/atoms/Image'

type StopperProps = {
  alt: string
  src: string
}

const Wrapper = styled.div`
  position: absolute;
  width: 6rem;
  height: 6rem;
  top: -1rem;
  left: 0.5rem;

  ${media.laptop`
    left: -1rem;
    width: 11.5rem; 
    height: 11.5rem; 
  `}
`

export const Stopper = ({ alt, src }: StopperProps) => (
  <Wrapper>
    <Image src={src} ratio={[1, 1]} alt={alt} responsive={[96, 188]} />
  </Wrapper>
)
