import React, { FunctionComponent, ReactNode, useEffect } from 'react'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'
import {
  Grid,
  GridArea,
  Button,
  Paragraph,
  Spacing,
  useIsViewportDesktop,
} from '@boxine/tonies-ui'
import { Customer } from '@commercetools/platform-sdk'
import { Section } from '../../../components/atoms/Section'
import { PageLayout } from '../../PageLayout'
import { CustomerCareInfo } from './CustomerCareInfo'
import { ThankYou } from '../../../components/molecules/ThankYou'
import { Order } from './Order'
import { UnverifiedEmailInfo } from './UnverifiedEmailInfo'
import { RichText } from '../../../components/molecules/RichText'
import { BxOrder } from '../../../lib/commercetools/requests/bxTypes'
import { normalizeShipping } from '../../../lib/commercetools/normalizers/normalizeShipping'
import { parsePaymentType } from '../../../providers/payment/lib'
import { useEcomLocale } from '../../../providers/locale'
import { normalizeLineItem } from '../../../lib/commercetools/normalizers/normalizeLineItem'
import { normalizeDiscount } from '../../../lib/commercetools/normalizers/normalizeDiscount'
import { Aggregated } from '../../../lib/transformation/aggregatePage/aggregate'
import { OrderSuccessLayoutSchema } from '../../../lib/contentful/datamodel/layouts'
import { usePaymentTypes } from '../../../hooks/usePaymentTypes'
import { EditorialTeaser } from '../../../components/molecules/EditorialTeaser'
import { getCheckoutConfig } from '../../../providers/checkout/config'
import { useIsAudioLibraryCheckout } from '../../../hooks/useIsAudioLibraryCheckout'
import { Currency } from '../../../config/shopAPI/types'
import { normalizeCartType } from '../../../lib/commercetools/normalizers/normalizeCart'
import { Hr } from '../../../components/atoms/Hr'

export type PageProps = Aggregated<OrderSuccessLayoutSchema> & {
  order: BxOrder
  user: Customer | null
}

const customerCareInfoRichtextOptions = {
  renderMark: {
    [MARKS.BOLD]: (children: ReactNode) => (
      <Paragraph size={2} weight={500} asHTMLTag="span">
        {children}
      </Paragraph>
    ),
    [MARKS.ITALIC]: (children: ReactNode) => (
      <Paragraph size={4} asHTMLTag="span">
        {children}
      </Paragraph>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node: unknown, children: ReactNode) => (
      <Paragraph size={2}>{children}</Paragraph>
    ),
  },
}

export const Page: FunctionComponent<PageProps> = ({
  order,
  user,
  customerCarePicture,
  customerCareInfo,
  customerCareInfoTunesOnly,
  customerCareInfoRepair,
  pageMetaData,
  editorialTeaserList,
}) => {
  const { t } = useTranslation()
  const isAudioLibraryCheckout = useIsAudioLibraryCheckout()
  const audioLibraryUrl = process.env.NEXT_PUBLIC_MY_TONIES_URL
  const { getBlockedPaymentTypes, setCartContainsTunesOnly } = usePaymentTypes()
  const blockedPaymentTypes = getBlockedPaymentTypes('checkout')
  const theme = useTheme()
  const isDesktop = useIsViewportDesktop()
  const lcCC = useEcomLocale()
  const hasRepair = order.bxLineItemTypes.includes('repair')
  const normalizedCartType = normalizeCartType(order.bxLineItemTypes)
  const isRepairOnly = normalizedCartType === 'repair'
  const isTunesOnly = normalizedCartType === 'digital'
  useEffect(() => {
    if (!isTunesOnly) return
    setCartContainsTunesOnly(isTunesOnly)
  }, [isTunesOnly, normalizedCartType, setCartContainsTunesOnly])
  /**
   * when converting a cart to an order the bundledProducts of a predefined bundle
   * are copied into individual lineItems and marked with a `bundle_marker_key`
   *
   * to prevent showing the bundle wrapper _and_ its children separately we filter them out here
   */
  const lineItems = order.lineItems
    .filter(li => !li.custom?.fields.bundle_marker_key)
    .map(li => normalizeLineItem(li, lcCC))
  const config = getCheckoutConfig({
    normalizedCartType: normalizedCartType,
    bxLineItemTypes: order.bxLineItemTypes,
  })

  return (
    <PageLayout
      bgColor={theme.colors.lightgrey}
      isMinimalHeaderVersion
      meta={pageMetaData}
      hasSideCartAutoExpandOnAddToCart={false}
      preventScrollingHeadsInFooter
    >
      <Spacing pt="spacing-xl">
        <Section data-testid="checkout-order-success-wrapper">
          <Grid hasGapH hasGapV>
            <GridArea
              colStart={0}
              colWidth={isDesktop ? 8 : 12}
              rowStart={0}
              rowHeight={isDesktop ? 3 : 1}
            >
              <ThankYou
                layout={isDesktop ? 'wide' : 'narrow'}
                message={t(config.orderSuccess.i18nHeadlineTerm)}
              />
              {user && !user.isEmailVerified && (
                <Spacing mb="spacing-s">
                  <UnverifiedEmailInfo />
                </Spacing>
              )}
              <Order
                lineItems={lineItems}
                billingAddress={order.billingAddress}
                shippingAddress={order.shippingAddress}
                paymentType={
                  order.bxCurrentPayment &&
                  parsePaymentType({
                    fields: order.bxCurrentPayment.custom.fields,
                    blockedPaymentTypes,
                  })
                }
                priceTotal={{
                  amount: order.totalPrice.amount,
                  centAmount: order.totalPrice.centAmount,
                  currency: order.totalPrice.currencyCode as Currency,
                }}
                priceOriginal={{
                  amount: order.bxOriginalTotalPrice.amount,
                  centAmount: order.bxOriginalTotalPrice.centAmount,
                  currency: order.bxOriginalTotalPrice.currencyCode as Currency,
                }}
                shipping={normalizeShipping(lcCC, order)}
                discount={normalizeDiscount(lcCC, order)}
                orderNumber={order.orderNumber || ''}
                config={config}
              />
            </GridArea>
            <GridArea
              colStart={isDesktop ? 8 : 0}
              colWidth={isDesktop ? 4 : 12}
              rowStart={isDesktop ? 0 : 2}
            >
              <CustomerCareInfo
                image={{
                  alt: customerCarePicture.title,
                  src: customerCarePicture.file.url,
                }}
                layout={isDesktop ? 'horizontal' : 'vertical'}
              >
                {!isRepairOnly && (
                  <RichText
                    size={2}
                    document={
                      isTunesOnly
                        ? customerCareInfoTunesOnly || customerCareInfo
                        : customerCareInfo
                    }
                    options={customerCareInfoRichtextOptions}
                  />
                )}
                {!isRepairOnly && hasRepair && (
                  <Spacing my="spacing-s">
                    <Hr />
                  </Spacing>
                )}
                {hasRepair && customerCareInfoRepair && (
                  <RichText
                    size={2}
                    document={customerCareInfoRepair}
                    options={customerCareInfoRichtextOptions}
                  />
                )}
              </CustomerCareInfo>
            </GridArea>
            <GridArea
              colStart={isDesktop ? 8 : 0}
              colWidth={isDesktop ? 4 : 12}
              rowStart={isDesktop ? 1 : 3}
            >
              {config.orderSuccess.showEditorialTeasers &&
                editorialTeaserList &&
                editorialTeaserList.map(
                  (
                    {
                      text,
                      textStyling,
                      ctaUrl,
                      ctaStyling,
                      ctaText,
                      backgroundImage,
                      backgroundColor,
                    },
                    index
                  ) => (
                    <Spacing
                      mb="spacing-l"
                      key={ctaUrl + backgroundImage?.file.url + text + index}
                    >
                      <EditorialTeaser
                        text={text}
                        textStyling={textStyling}
                        ctaUrl={ctaUrl}
                        ctaStyling={ctaStyling || 'primary'}
                        ctaText={ctaText}
                        backgroundImageUrl={backgroundImage.file.url}
                        backgroundColor={backgroundColor}
                      />
                    </Spacing>
                  )
                )}
              <div style={{ display: 'grid' }}>
                <Button
                  as="a"
                  href={
                    isAudioLibraryCheckout
                      ? audioLibraryUrl
                      : t('metaDefaults:startUrl')
                  }
                >
                  {t('checkout:orderSuccess:returnHomeLink')}
                </Button>
              </div>
            </GridArea>
          </Grid>
        </Section>
      </Spacing>
    </PageLayout>
  )
}
