import React, { useCallback } from 'react'
import { AudioSample } from '@boxine/tonies-ui'
import { useGtmV4 } from '../../../providers/gtmV4'
import { handleOnPlay } from '../../../components/organisms/AudioPlayerSection/utils'
import { useRecommendations } from '../../../providers/recommendations'

export const AudioSampleContainer = ({
  product,
}: {
  product: NormalizedProductExtended
}) => {
  const { pushGtmV4Event } = useGtmV4()
  const { audioSampleUrl } = product
  const { handleSetLastSeenSku } = useRecommendations()

  const handleAudioSampleOnPlay = useCallback(() => {
    handleSetLastSeenSku(product.sku)
    handleOnPlay(product, pushGtmV4Event)
  }, [handleSetLastSeenSku, product, pushGtmV4Event])

  return audioSampleUrl ? (
    <AudioSample
      url={audioSampleUrl}
      onPlay={handleAudioSampleOnPlay}
      loading="lazy"
    />
  ) : null
}
