import React, { FunctionComponent } from 'react'
import { TonieSongLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { PageLayout } from '../PageLayout'
import { ImageText } from '../../components/organisms/ImageText'
import { Section } from '../../components/atoms/Section'
import { VideoTextCarousel } from '../../components/organisms/VideoTextCarousel'
import { VideoPlayer } from '@/tonies-ui/molecules/VideoPlayer'
import { BrazeForm } from '../../components/organisms/BrazeForm'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'
import { useTheme } from 'styled-components'
import { useTranslation } from 'next-i18next'
import { Curve } from '@/tonies-ui/atoms/Curve'

export const TonieSongLayout: FunctionComponent<
  Aggregated<TonieSongLayoutSchema>
> = ({
  pageMetaData,
  textHero,
  videoPlayer,
  imageText,
  editorialTeaserCollection,
  community,
  videoTextCarousel,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()

  return (
    <PageLayout meta={pageMetaData}>
      <ContentModels.TextHero {...textHero} />

      <Curve
        color={textHero.backgroundColor}
        backgroundColor={colors['lightblue-20']}
        layout="bottomLeft"
      />

      <Section backgroundColor={colors['lightblue-20']}>
        <VideoPlayer
          alt={videoPlayer.altText}
          srcMobile={videoPlayer.mobileVideo.file.url}
          srcDesktop={videoPlayer.desktopVideo.file.url}
          posterMobile={videoPlayer.mobilePosterImage.file.url}
          posterDesktop={videoPlayer.desktopPosterImage.file.url}
        />
      </Section>

      <Section>
        <ImageText {...imageText} />
      </Section>

      <Section backgroundColor={colors['lightblue-20']}>
        <BrazeForm
          formLocation="component"
          headline={t('brazeForm:headline')}
          headlineAsHTMLTag="h3"
          referenceTitle="[coded] TonieSongLayout"
          successHeadline={t('brazeForm:successHeadline')}
          successRichtext={t('brazeForm:successText')}
          variant="Newsletter"
        />
      </Section>

      <Section>
        <VideoTextCarousel
          {...videoTextCarousel}
          items={videoTextCarousel.items.map(item => ({
            ...item,
            videoDesktop: item.videoDesktop.file.url,
            videoMobile: item.videoMobile.file.url,
          }))}
        />
      </Section>

      <Section
        backgroundColor={editorialTeaserCollection.backgroundColor}
        removePaddingX={editorialTeaserCollection.variant === 'slider'}
      >
        <ContentModels.EditorialTeaserList {...editorialTeaserCollection} />
      </Section>

      <Section
        backgroundColor={community.backgroundColor}
        removePaddingX={community.variant === 'slider'}
      >
        <ContentModels.Community {...community} />
      </Section>
    </PageLayout>
  )
}
