import { useAggregatedShopLocale } from '../providers/aggregatedShopLocale'
import { useUrlQuery } from '../providers/urlQuery'
import { useMemo } from 'react'

export const useUrlModularContentSection = () => {
  const {
    content: { urlModularContentSections },
  } = useAggregatedShopLocale()
  const templateId = useUrlQuery().getUrlParamAsSingleton('tpl')

  return useMemo(() => {
    if (templateId) {
      return urlModularContentSections.find(
        template => template.id === templateId
      )
    }
  }, [urlModularContentSections, templateId])
}
