import { Paragraph, Spacing } from '@boxine/tonies-ui'
import React from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { WhiteBox } from '../../../components/molecules/WhiteBox'

const Link = styled.a`
  // As per design decision, this should be a normal primary-color link with underline.
  font-weight: 700;
  color: ${p => p.theme.colors.primary};
`

export const UnverifiedEmailInfo = () => {
  const { t } = useTranslation()

  return (
    <WhiteBox>
      <Spacing pb="spacing-s" pt="spacing-s" ml="spacing-s" mr="spacing-s">
        <Spacing mb="spacing-s">
          <Paragraph size={2} weight={700}>
            <Accent text={t('checkout:orderSuccess:UnverifiedUserInfoText')} />
          </Paragraph>
        </Spacing>

        <Paragraph size={3}>
          {t('checkout:orderSuccess:UnverifiedUserInfoHelp') + ' '}
          <Link href={t('checkout:orderSuccess:UnverifiedUserInfoHelpLink')}>
            {t('checkout:orderSuccess:UnverifiedUserInfoHelpLinkText')}
          </Link>
        </Paragraph>
      </Spacing>
    </WhiteBox>
  )
}
