import React, { FunctionComponent } from 'react'
import { Box, SpacingsProps } from '@boxine/tonies-ui'
import { Tag } from '@/tonies-ui/atoms/Tag'

import { useProductFlag } from '../../../hooks/useProductFlag'
import styled from 'styled-components'
import { cssReset } from '@/tonies-ui/helpers/css-helper'
import { useAggregatedShopLocale } from '../../../providers/aggregatedShopLocale'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'

const Wrapper = styled(Box)`
  display: inline-flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`
const StyledLink = styled.a`
  ${cssReset}
`

type ProductTagsProps = {
  product: NormalizedProduct
}

export const ProductTags: FunctionComponent<
  ProductTagsProps & Partial<SpacingsProps>
> = ({ product, ...spacing }) => {
  const flag = useProductFlag(product)
  const { hasProductFlagFilter } = useFeatureFlags()
  const {
    commonPages: { toniesPage },
  } = useAggregatedShopLocale()

  const FlagLink =
    product.shopCategory === 'tonies' && hasProductFlagFilter
      ? StyledLink
      : // FIXME: React.Fragment has only attributes like `key` and `children` and causes an invalid prop error
        React.Fragment
  const GenreLink =
    // FIXME: React.Fragment has only attributes like `key` and `children` and causes an invalid prop error
    product.shopCategory === 'tonies' ? StyledLink : React.Fragment

  const tags: JSX.Element[] = []

  if (flag) {
    tags.push(
      <FlagLink href={toniesPage?.slug + `?flag=${flag.key}`} key={flag.key}>
        <Tag backgroundColor={flag.backgroundColor}>{flag.text}</Tag>
      </FlagLink>
    )
  }

  if (product.genre) {
    tags.push(
      <GenreLink
        href={toniesPage?.slug + `?genre=${product.genre.key}`}
        key={product.genre.key}
      >
        <Tag>{product.genre.label}</Tag>
      </GenreLink>
    )
  }

  return tags.length !== 0 ? <Wrapper {...spacing}>{tags}</Wrapper> : null
}
