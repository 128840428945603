import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { withGTMV4Context } from '../../../providers/gtmV4'
import { InfoTeaser, InfoTeaserProps } from '../../molecules/InfoTeaser'
interface InfoTeaserCollectionProps {
  entries: InfoTeaserProps[]
}

const InfoTeaserCollectionContainer: FunctionComponent<
  InfoTeaserCollectionProps
> = props => {
  const { entries } = props
  return (
    <Entries>
      {entries.map(entry => (
        <Item key={entry.title}>
          <InfoTeaser {...entry}></InfoTeaser>
        </Item>
      ))}
    </Entries>
  )
}

const Entries = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -0.625rem -1.25rem;
`

const Item = styled.li`
  padding: 0 0.625rem 1.25rem;
`

export const InfoTeaserCollection = withGTMV4Context(
  InfoTeaserCollectionContainer,
  'InfoTeaserCollection'
)
