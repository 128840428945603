import React, { FunctionComponent } from 'react'
import { Section } from '../../components/atoms/Section'
import { PageLayout } from '../PageLayout'
import { NewsletterLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'

export const NewsletterLayout: FunctionComponent<
  Aggregated<NewsletterLayoutSchema>
> = ({ brazeForm, pageMetaData, textHero }) => {
  return (
    <PageLayout meta={pageMetaData}>
      <ContentModels.TextHero {...textHero} />
      <Section backgroundColor={brazeForm.backgroundColor}>
        <ContentModels.BrazeForm
          {...brazeForm}
          referenceTitle="[coded] NewsletterLayout"
          componentProps={{ formLocation: 'nl-page' }}
        />
      </Section>
    </PageLayout>
  )
}
