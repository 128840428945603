import React, { forwardRef, ReactNode } from 'react'
import { useIsViewportMobile } from '@boxine/tonies-ui'
import { ContentfulImage } from '../../../lib/contentful/datamodel/schemas'
import { ContainerProps } from './components/Container'
import { Layout, LayoutProps } from './components/Layout'
import { Column, ColumnProps } from './components/Column'
import { Text, TextProps } from './components/Text'
import { getActualRatio, Ratio } from '../../../utils/ratio'
import { Image } from '@/tonies-ui/atoms/Image'

import {
  ImageContainer,
  ImageContainerProps,
} from './components/ImageContainer'
import { Stopper } from './components/Stopper'
import { withGTMV4Context } from '../../../providers/gtmV4'
import { Section } from '../../../components/atoms/Section'

export type ImageTextProps = ContainerProps &
  ColumnProps &
  TextProps &
  ImageContainerProps &
  LayoutProps & {
    children?: ReactNode
    dataTestId?: string
    imageMobile: ContentfulImage
    imageDesktop: ContentfulImage
    imageAlt?: string
    imageWidthMobile?: string
    imageWidthDesktop?: string
    stopper?: ContentfulImage
    id?: string
  }

const ImageTextContainer = forwardRef<HTMLElement, ImageTextProps>(
  function InnerImageText(
    {
      backgroundColor,
      children,
      dataTestId,
      imageMobile,
      imageDesktop,
      imageAlt,
      text,
      title,
      headlineAsHTMLTag,
      contentPositionMobile,
      contentPositionDesktop,
      textAlignMobile,
      ctaText,
      ctaUrl,
      imageWidthMobile,
      imageWidthDesktop,
      stopper,
      id,
    },
    ref
  ) {
    const isMobile = useIsViewportMobile()

    // enabled ratios
    const ratios: Ratio[] = [
      [1, 1],
      [4, 3],
      [16, 9],
      [21, 9],
      [3, 4],
      [9, 16],
    ]

    const [, ratioMobile] = getActualRatio(
      imageMobile.file.details.image.width,
      imageMobile.file.details.image.height,
      ratios
    )
    const [, ratioDesktop] = getActualRatio(
      imageDesktop.file.details.image.width,
      imageDesktop.file.details.image.height,
      ratios
    )

    return (
      <Section
        data-testid={dataTestId}
        ref={ref}
        backgroundColor={backgroundColor}
        id={id}
      >
        <Layout contentPositionMobile={contentPositionMobile}>
          <Column
            textAlignMobile={textAlignMobile}
            contentPositionDesktop={contentPositionDesktop}
          >
            <Text
              ctaText={ctaText}
              ctaUrl={ctaUrl}
              text={text}
              title={title}
              headlineAsHTMLTag={headlineAsHTMLTag}
              align={isMobile ? textAlignMobile : undefined}
            />
            {children}
          </Column>
          <Column>
            <ImageContainer
              $imageWidthMobile={imageWidthMobile}
              $imageWidthDesktop={imageWidthDesktop}
              viewport={{ amount: 0.4 }}
            >
              {stopper && (
                <Stopper alt={stopper.file.fileName} src={stopper.file.url} />
              )}
              <Image
                src={isMobile ? imageMobile.file.url : imageDesktop.file.url}
                alt={imageAlt || ''}
                responsive={[290, 350, 380, 480, 510, 580]}
                ratio={isMobile ? ratioMobile : ratioDesktop}
                crop="autoGravity"
              />
            </ImageContainer>
          </Column>
        </Layout>
      </Section>
    )
  }
)

export const ImageText = withGTMV4Context(ImageTextContainer, 'ImageText')
