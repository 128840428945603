/*
 * Reduce a given Array of numbers to the closest number
 * of a given value
 */

export const getClosestValue = (value: number, closest: number[]): number => {
  return (
    closest.reduce((pv, cv) => {
      const diffPv = pv - value < 0 ? (pv - value) * -1 : pv - value
      const diffCv = cv - value < 0 ? (cv - value) * -1 : cv - value
      return diffPv > diffCv ? cv : pv
    }) ?? closest[0]
  )
}

export type Ratio = [number, number]
export type RatioMap = [number, Ratio]

export const getActualRatio = (
  width: number,
  height: number,
  ratios: Ratio[],
  fallback: RatioMap = [133, [4, 3]]
) => {
  // calculate ratio
  const ratioDraft = Math.round((width / height) * 100)

  // genrate calculated ratio from the named ratio
  const drafts: number[] = ratios.map(ratio => {
    return Math.round((ratio[0] / ratio[1]) * 100)
  })

  // generate calculated and named ratio map
  const ratioMapDrafts: RatioMap[] = ratios.map((ratio, index) => {
    return [drafts[index], ratio]
  })

  // get the closest calculated ratio
  const closestRatioDraft = getClosestValue(ratioDraft, drafts)
  return (
    ratioMapDrafts.find(RatioMapDraft => {
      return RatioMapDraft[0] === closestRatioDraft
    }) ?? fallback
  )
}
