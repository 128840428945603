import styled from 'styled-components'

export const Wrapper = styled.div<{
  size: 'small' | 'normal'
}>`
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ size }) => {
    if (size === 'small') {
      return `
        width: 21.5em;
        height: 18.75rem;
      `
    }

    return `
      width: 27.25em;
      height: 23.625rem;
    `
  }}
`
