import React from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { Headline5, Paragraph, Spacing } from '@boxine/tonies-ui'
import { Expandable } from '../../../components/atoms/Expandable'
import { Hr } from '../../../components/atoms/Hr'
import { PaymentInfoTeaser } from '../../../components/molecules/PaymentInfoTeaser'
import { BxAddress } from '../../../lib/commercetools/requests/bxTypes'
import { PaymentType } from '../../../providers/payment/types'
import { Summary } from '../../../components/organisms/CartSummary'
import { ReadOnly } from '../../CheckoutLayout/01-Addresses/ReadOnly'
import { useDateFormatter } from '../../../hooks/useDateFormatter'
import { LineItemCollection } from '../../../components/organisms/LineItemCollection'
import { CheckoutConfig } from '../../../providers/checkout/config/types'
import { InfoBox } from '@/tonies-ui/atoms/InfoBox'

// FIXME: avoid overwriting internal component styles
const StyledInfoBox = styled(InfoBox)`
  margin: 0;
`

type OrderProps = {
  paymentType: PaymentType | undefined
  billingAddress: BxAddress
  shippingAddress: BxAddress
  lineItems: NormalizedLineItem[]
  shipping: NormalizedShipping
  priceTotal: NormalizedAmount
  priceOriginal: NormalizedAmount
  discount: NormalizedDiscount | undefined
  orderNumber: string | undefined
  config: CheckoutConfig
}

/**
 * Element to be displayed in `layouts/OrderSuccessLayout/components/Page.tsx` containing
 *  - shipping/billing Addresses
 *  - Payment Info
 *  - ordered products
 *  - subtotal, total, shipping price and discounts
 * @param {OrderProps} OrderProps - attributes of the current order
 * @returns {JSX.Element}
 */
export const Order = ({
  billingAddress,
  shippingAddress,
  paymentType,
  lineItems,
  shipping,
  priceTotal,
  priceOriginal,
  discount,
  orderNumber,
  config,
}: OrderProps) => {
  const { t } = useTranslation()
  const df = useDateFormatter()

  const shippingFromDate = shipping?.fromDate
  const shippingPrice = shipping?.price
  const shippingMethodId = shipping?.method?.id
  const shippingMethodName = shipping?.method?.name
  const shippingMethodDesc = shipping?.method?.description

  return (
    <Expandable
      headline={t('checkout:orderReview:order', { orderNumber })}
      isExpanded
    >
      {lineItems && (
        <>
          <Spacing pt="spacing-m" mr="spacing-s" mb="spacing-s" ml="spacing-s">
            <StyledInfoBox type="info">
              {t('checkout:orderSuccess:info')}
            </StyledInfoBox>
          </Spacing>
          <ReadOnly
            billingAddress={billingAddress}
            shippingAddress={
              config.addresses.hasShippingAddress ? shippingAddress : undefined
            }
          />
          <Hr />
          {config.shipping.shippingMethodSelect && (
            <>
              <Spacing
                pt="spacing-m"
                mr="spacing-s"
                mb="spacing-s"
                ml="spacing-s"
              >
                <Headline5
                  asHTMLTag="h3"
                  data-testid="shipping-info-name"
                  data-shippinginfoname={shippingMethodId}
                >
                  {shippingMethodName}
                </Headline5>
              </Spacing>
              <Spacing mr="spacing-s" mb="spacing-m" ml="spacing-s">
                <Paragraph size={2}>{shippingMethodDesc}</Paragraph>
              </Spacing>
              <Hr />
            </>
          )}
          <Spacing pt="spacing-m" mr="spacing-s" mb="spacing-s" ml="spacing-s">
            <Headline5 asHTMLTag="h3">
              {t('checkout:orderSuccess:paymentHeadline')}
            </Headline5>
          </Spacing>
          <Spacing mr="spacing-s" mb="spacing-m" ml="spacing-s">
            <PaymentInfoTeaser
              paymentType={paymentType}
              handleEdit={undefined}
              hasNoPaymentNecessaryButton={false}
            />
          </Spacing>
          <Hr />
          <Spacing py="spacing-m" mx="spacing-s">
            <Headline5 asHTMLTag="h3">
              {t('checkout:orderSuccess:orderHeadline')}
            </Headline5>
            <LineItemCollection lineItems={lineItems} context="orderSuccess" />
          </Spacing>
          <Hr />
          <Spacing pt="spacing-m" mr="spacing-s" mb="spacing-m" ml="spacing-s">
            <Summary
              hasCouponRows={config.coupon.hasCouponCode}
              hasCouponRemoveOption={false}
              hasShippingCostRows={
                config.orderSuccess.summary.price.shouldShowShippingCosts
              }
              hasSubtotalRows={
                config.orderSuccess.summary.price.shouldShowSubtotal
              }
              values={{
                subTotal: priceOriginal,
                shipping: shippingPrice,
                total: priceTotal,
                codeDiscounts: discount?.byDiscountCode
                  ? [discount.byDiscountCode]
                  : undefined,
                cartDiscounts: discount?.others,
              }}
            />
          </Spacing>
          {shippingFromDate !== undefined && (
            <>
              <Hr />
              <Spacing
                pt="spacing-s"
                mr="spacing-s"
                mb="spacing-m"
                ml="spacing-s"
              >
                <Paragraph color="primary" weight={700} size={3} align="center">
                  {t('checkout:preOrderDate', {
                    date: df(new Date(shippingFromDate)),
                  })}
                </Paragraph>
              </Spacing>
            </>
          )}
        </>
      )}
    </Expandable>
  )
}
