import {
  Headline1,
  media,
  Spacing,
  useIsViewportDesktop,
  ProgressIndicator,
} from '@boxine/tonies-ui'
import React, { useCallback } from 'react'
import styled, { useTheme } from 'styled-components'
import { ReferralSectionSchema } from '../../../lib/contentful/datamodel/schemas'
import { useAuth } from '../../../providers/auth'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { Section } from '../../atoms/Section'
import { ReferralCodeBox } from '../../molecules/ReferralCodeBox'
import { RichText } from '../../molecules/RichText'
import { Illustration } from './components/Illustration'
import { useReferralCode } from '../../../hooks/useReferralCode'
import { mainFirstChildPaddingTop } from '@/tonies-ui/helpers/css-helper'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.laptop`
    justify-content: space-between;
    flex-direction: row; 
  `}

  ${mainFirstChildPaddingTop}
`

const Wrapper = styled.div`
  ${media.laptop`
    width: 40%;
    min-width: 27.25rem;
    text-align: center;
  `}
`

export const ReferralSection = ({
  contentHeadline,
  headlineAsHTMLTag = 'h2',
  copy,
  referralBoxHeadlineLoggedIn,
  referralBoxHeadlineNotLoggedIn,
  referralBoxSubHeadline,
}: ReferralSectionSchema) => {
  const theme = useTheme()
  const isDesktop = useIsViewportDesktop()
  const auth = useAuth()
  const { code: referralCode, handleShare, isPending } = useReferralCode()
  const handleLogin = useCallback(() => auth.login(), [auth])

  return (
    <Section backgroundColor={theme.colors['lightblue-20']}>
      <Layout>
        <Spacing
          pr={isDesktop ? 'spacing-xxl' : undefined}
          mb={isDesktop ? undefined : 'spacing-l'}
          mr={isDesktop ? 'spacing-xxl' : undefined}
        >
          <div>
            <Spacing mb="spacing-m">
              <Headline1
                asHTMLTag={headlineAsHTMLTag}
                weight={900}
                align={isDesktop ? 'left' : 'center'}
              >
                <Accent text={contentHeadline} />
              </Headline1>
            </Spacing>
            <Spacing mb="spacing-s">
              <RichText
                size={1}
                document={copy}
                align={isDesktop ? 'left' : 'center'}
              />
            </Spacing>
            <Illustration />
          </div>
        </Spacing>

        <Wrapper>
          {/*
          We show a progress indicator until we know that
          - the user is not logged in
          or
          - the user is logged in and the API request has finished
        */}
          {auth.isPending || (auth.user && isPending) ? (
            <ProgressIndicator
              data-testid="referral-code-progress-indicator"
              styling="secondary"
              height="2rem"
              width="2rem"
            />
          ) : (
            <ReferralCodeBox
              headline={
                auth.user
                  ? referralBoxHeadlineLoggedIn
                  : referralBoxHeadlineNotLoggedIn
              }
              subHeadline={referralBoxSubHeadline}
              shareOnClick={handleShare}
              loginOnClick={handleLogin}
              referralCode={referralCode}
            />
          )}
        </Wrapper>
      </Layout>
    </Section>
  )
}
