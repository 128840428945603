import React, { FunctionComponent } from 'react'
import { ContainerProps } from '../ImageText/components/Container'
import { LayoutProps, Layout } from '../ImageText/components/Layout'
import { ColumnProps, Column } from '../ImageText/components/Column'
import { TextProps, Text } from '../ImageText/components/Text'
import { VideoPlayer } from '@/tonies-ui/molecules/VideoPlayer'
import { ContentfulImage } from '../../../lib/contentful/datamodel/schemas'
import { withGTMV4Context } from '../../../providers/gtmV4'
import { Section } from '../../../components/atoms/Section'
import { ToniesMotions } from '@/tonies-ui/motions'

type BaseProps = ContainerProps &
  LayoutProps &
  ColumnProps &
  TextProps & {
    dataTestId?: string
  }

/**
 * TODO
 * unify this type with VideoPlayProps
 */
export type VideoProps = {
  videoAlt: string
  videoDesktop: string
  videoMobile: string
  posterMobile?: ContentfulImage
  posterDesktop?: ContentfulImage
  id?: string
}

export type VideoTextProps = BaseProps & VideoProps

const VideoTextContainer: FunctionComponent<VideoTextProps> = ({
  backgroundColor,
  dataTestId,
  videoMobile,
  videoDesktop,
  posterMobile,
  posterDesktop,
  videoAlt,
  text,
  title,
  headlineAsHTMLTag,
  contentPositionMobile,
  contentPositionDesktop,
  textAlignMobile,
  ctaText,
  ctaUrl,
  id,
}) => (
  <Section data-testid={dataTestId} backgroundColor={backgroundColor} id={id}>
    <Layout contentPositionMobile={contentPositionMobile}>
      <Column
        textAlignMobile={textAlignMobile}
        contentPositionDesktop={contentPositionDesktop}
      >
        <Text
          title={title}
          headlineAsHTMLTag={headlineAsHTMLTag}
          text={text}
          ctaText={ctaText}
          ctaUrl={ctaUrl}
        />
      </Column>
      <Column>
        <ToniesMotions.ZoomIt viewport={{ amount: 0.4 }}>
          <VideoPlayer
            alt={videoAlt}
            srcDesktop={videoDesktop}
            srcMobile={videoMobile}
            posterMobile={posterMobile?.file.url}
            posterDesktop={posterDesktop?.file.url}
          />
        </ToniesMotions.ZoomIt>
      </Column>
    </Layout>
  </Section>
)

export const VideoText = withGTMV4Context(VideoTextContainer, 'VideoText')
