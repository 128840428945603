import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { PageLayout } from '../PageLayout'
import { HowItWorksLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { ProductSelector } from '../../components/organisms/ProductSelector'
import { Headline1, Headline5, Spacing, media } from '@boxine/tonies-ui'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { RichText } from '../../components/molecules/RichText'
import { Section } from '../../components/atoms/Section'
import { ImageText } from '../../components/organisms/ImageText'
import { SeoText } from '../../components/molecules/SeoText'
import { normalizeProduct } from '../../lib/commercetools/normalizers/normalizeProduct'
import { useLocale } from '../../providers/locale'
import { isEcomLocale } from '../../config/shopAPI'
import { getProductsByCategory } from '../../lib/commercetools/requests/products/getProductsByCategory'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { AggregatedProductList } from '../../lib/transformation/aggregateProductList'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'

const GalleryWrapper = styled.div`
  /* negative margins to cope with the layout of the product selector component */
  margin: -2rem auto 2.5rem auto;
  max-width: 75rem;

  ${media.tablet`
    margin-top: -5rem;
  `}
`

export const HowItWorksLayout: FunctionComponent<
  Aggregated<HowItWorksLayoutSchema>
> = ({
  pageMetaData,
  stage,
  description1,
  tonieboxSelector,
  tonieboxSelectorGallery,
  description2,
  description3,
  audioPlayer,
  description4,
  description5,
  description6,
  description7,
  description8,
  description9,
  description10,
  seoText,
}) => {
  const lcCC = useLocale()
  const [tonieboxes, setTonieboxes] = useState<
    AggregatedProductList | undefined
  >()

  // FIXME: Do this on build if possible
  useEffect(() => {
    if (isEcomLocale(lcCC)) {
      // Fetch Tonieboxes
      getProductsByCategory(lcCC, ['tonieboxes']).then(response => {
        if (response.result === 'successful') {
          const products = response.data.results

          // Generate Toniebox product list
          const productList: AggregatedProductList = {
            contentTypeId: 'productList',
            referenceTitle: '',
            normalizedProducts: products.map(p => normalizeProduct(p, lcCC)),
            categoryKey: 'tonieboxes',
            randomProductId:
              // Preselect a random Toniebox
              products[Math.floor(Math.random() * products.length)].id,
            headline: tonieboxSelector.title,
          }

          setTonieboxes(productList)
        }
      })
    }
  }, [lcCC, tonieboxSelector.title])

  return (
    <PageLayout meta={pageMetaData}>
      <ContentModels.MediaCopySection
        {...stage}
        componentProps={{ hasMarginTop: true }}
      />
      <Section>
        <ContentModels.TextHero {...description1} />
      </Section>

      {tonieboxes && (
        <ProductSelector
          referenceTitle="[coded] HowItWorks ProductSelector"
          uniqueInputName="tonieboxes"
          hasLargerProductImage
          hasSkyIllustration
          isImageLink={false}
          headlinePosition="bottom"
          tabletAlignContent="left"
          productList={tonieboxes}
          headline={
            <>
              <Headline5 align="left" weight={700} asHTMLTag="h1">
                {tonieboxSelector.subTitle}
              </Headline5>
              <Headline1 align="left" asHTMLTag="h2">
                <Accent text={tonieboxSelector.title} />
              </Headline1>
              {tonieboxSelector.text && (
                <Spacing media={{ m: { mb: 'spacing-m', mt: 'spacing-s' } }}>
                  <RichText
                    align="left"
                    size={2}
                    document={tonieboxSelector.text}
                  />
                </Spacing>
              )}
            </>
          }
          lowerSection={
            <GalleryWrapper>
              <ContentModels.Gallery {...tonieboxSelectorGallery} />
            </GalleryWrapper>
          }
        />
      )}

      <ImageText {...description2} textAlignMobile="left" />

      <Section>
        <ContentModels.TextHero {...description3} />
      </Section>

      <ContentModels.AudioPlayerSection {...audioPlayer} />

      <ImageText {...description4} textAlignMobile="left" />

      <Section>
        <ContentModels.TextHero {...description5} />
      </Section>

      <ImageText {...description6} textAlignMobile="left" />

      <ImageText {...description7} textAlignMobile="left" />

      <Section>
        <ContentModels.TextHero {...description8} />
      </Section>

      <ImageText {...description9} textAlignMobile="left" />

      <ImageText {...description10} textAlignMobile="left" />

      {seoText?.content && (
        <Section>
          <SeoText document={seoText.content} />
        </Section>
      )}
    </PageLayout>
  )
}
