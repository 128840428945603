import React, { FunctionComponent } from 'react'
import { InfoTeaserCollection } from '../../components/organisms/InfoTeaserCollection'
import { PageLayout } from '../PageLayout'
import { ContactLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { Section } from '../../components/atoms/Section'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'

export const ContactLayout: FunctionComponent<
  Aggregated<ContactLayoutSchema>
> = ({ pageMetaData, textHero, infoTeaserList }) => (
  <PageLayout meta={pageMetaData} preventScrollingHeadsInFooter>
    <ContentModels.TextHero {...textHero} />
    <Section>
      <InfoTeaserCollection entries={infoTeaserList.entries} />
    </Section>
  </PageLayout>
)
