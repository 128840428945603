import React from 'react'
import styled from 'styled-components'
import { shopConfig } from '../../../config/shop'
import { Section } from '../../../components/atoms/Section'
import { useEcomLocale } from '../../../providers/locale'

/**
 * I hate external stuff
 */
const RatingSummaryWrapper = styled.div`
  height: 54px; // prevent layout shift because of async fetch
  [data-bv-show='rating_summary'] .bv_main_container {
    margin: 1rem 0 !important;
    line-height: 1.4 !important;
  }
  [data-bv-show='rating_summary'] .bv_main_container .bv_numReviews_text,
  [data-bv-show='rating_summary'] .bv_main_container {
    font-size: 1rem !important;
    font-weight: 300 !important;
    .bv_ratings_summary {
      padding-right: 8px !important;
    }
    .bv_stars_component_container {
      padding-right: 4px !important;
    }
    .bv_avgRating_component_container {
      font-weight: 300 !important;
      text-decoration: none !important;
      &:hover {
        font-weight: inherit !important;
      }
    }
    .bv_numReviews_text {
      text-decoration: none !important;
      &:hover {
        color: ${props => props.theme.colors['primary-hover']} !important;
        font-weight: inherit !important;
        text-decoration: none !important;
      }
    }
    .bv_button_buttonMinimalist {
      color: ${props => props.theme.colors.primary} !important;
      font-weight: 300 !important;
      &:hover {
        color: ${props => props.theme.colors['primary-hover']} !important;
        font-weight: inherit !important;
      }
    }
  }
`

const ReviewsWrapper = styled(Section)`
  > div > div {
    padding: 0 !important;
    margin-top: -19px; // fix margin-block-start in shadow-root
  }
`

export const BazaarvoiceRatingSummary = ({
  product,
}: {
  product: NormalizedProduct
}) => {
  const lcCC = useEcomLocale()
  const hasBazaarvoice = !!shopConfig.locales[lcCC].bazaarvoice

  return hasBazaarvoice ? (
    <RatingSummaryWrapper>
      <div
        data-bv-show="rating_summary"
        data-bv-product-id={product.salesId}
      ></div>
    </RatingSummaryWrapper>
  ) : null
}

export const BazaarvoiceReviews = ({
  product,
}: {
  product: NormalizedProduct
}) => {
  const lcCC = useEcomLocale()
  const hasBazaarvoice = !!shopConfig.locales[lcCC].bazaarvoice

  return hasBazaarvoice ? (
    <ReviewsWrapper removePaddingY id="reviews">
      <div data-bv-show="reviews" data-bv-product-id={product.salesId}></div>
    </ReviewsWrapper>
  ) : null
}
