import { Button } from '@boxine/tonies-ui'
import { useTranslation } from 'next-i18next'
import { AddToCart } from '../../../components/atoms/AddToCart'
import React, { FunctionComponent } from 'react'

import { useCartState } from '../../../hooks/useCartState'
import { useAssignTune } from '../../../hooks/useAssignTune'
import { AssignButton } from '../../../components/atoms/AssignButton'
import { useOwnAudioContentContext } from '../../../providers/ownAudioContent/OwnAudioContext'
import { GtmV4AudioContentEvent } from '../../../providers/gtmV4/types'

export const MainActionButton: FunctionComponent<{
  product: NormalizedProductExtended
  isDisabled?: boolean
  type: 'addToCart' | 'assign' | 'alreadyBought' | undefined
  'data-testid'?: string
  setIsAssignModalOpen?: (isOpen?: boolean) => void
  hasToniesToAssign?: boolean
  isAudioContentCurrentlyAssigned?: boolean
  isAlreadyAssignedToOnlyCompatibleTonie?: boolean
}> = ({
  product,
  isDisabled = false,
  type,
  'data-testid': dataTestId = '',
  setIsAssignModalOpen = undefined,
  hasToniesToAssign = undefined,
  isAudioContentCurrentlyAssigned = undefined,
  isAlreadyAssignedToOnlyCompatibleTonie = undefined,
}) => {
  const { t } = useTranslation()
  const { isItemInCart } = useCartState()
  const { handleClickAssign } = useAssignTune(product)
  const {
    isDirectOwnAudioContentAssignmentEnabled,
    isAllOwnAudioContentReady,
  } = useOwnAudioContentContext()

  const createAssignButtonLabelText = () => {
    // Undefined will lead to the assignbutton component taking the default label 'Assign'
    let labelText: string | undefined = undefined

    if (
      isAllOwnAudioContentReady &&
      hasToniesToAssign &&
      !isAlreadyAssignedToOnlyCompatibleTonie &&
      isAudioContentCurrentlyAssigned
    ) {
      labelText = t('ownAudioContent:ChangeTonieButtonLabel')
    }
    if (
      isAllOwnAudioContentReady &&
      (!hasToniesToAssign || isAlreadyAssignedToOnlyCompatibleTonie)
    ) {
      labelText = t('ownAudioContent:ButtonLabelInfo')
    }
    return labelText
  }

  const assignAudioTrackingEvent: GtmV4AudioContentEvent = {
    eventType: 'audioContent',
    step: 'start_assign',
    item: product,
  }

  return (
    <>
      {!type ? (
        <Button disabled isPending data-testid={`${dataTestId}--pending`} />
      ) : type === 'alreadyBought' ? (
        <Button
          disabled
          icon="headphones"
          data-testid={`${dataTestId}--alreadyBought`}
        >
          {t('products:tunes:alreadyBoughtHint')}
        </Button>
      ) : type === 'assign' && product.audioLibraryAssignUrl ? (
        <AssignButton
          referenceTitle="[coded] PDP MainAction Assign"
          dataLayerEvent={assignAudioTrackingEvent}
          isDisabled={
            isDirectOwnAudioContentAssignmentEnabled ? false : isDisabled
          }
          audioLibraryAssignUrl={
            isDirectOwnAudioContentAssignmentEnabled
              ? '#'
              : product.audioLibraryAssignUrl
          }
          dataTestId={`${dataTestId}--assign`}
          handleClickAssign={
            isDirectOwnAudioContentAssignmentEnabled && setIsAssignModalOpen
              ? () => setIsAssignModalOpen(true)
              : handleClickAssign
          }
          labelText={createAssignButtonLabelText()}
        />
      ) : product.maxQuantity === 1 && isItemInCart(product.sku) ? (
        <Button
          icon="cart"
          disabled
          data-testid={`${dataTestId}--alreadyInCart`}
        >
          {t('products:alreadyInCart')}
        </Button>
      ) : (
        <AddToCart
          referenceTitle="[coded] PDP MainAction AddToCart"
          gtmActionField="pdp"
          product={product}
          type="omniButton"
          isDisabled={isDisabled}
          data-testid={dataTestId}
        />
      )}
    </>
  )
}
