import { BlogTeaser } from '../../../components/molecules/GenericImageText/variants/BlogTeaser'
import { Aggregated } from '../../../lib/transformation/aggregatePage/aggregate'
import { useUrlQuery } from '../../../providers/urlQuery'

export const useTagFilter = (posts: Aggregated<BlogTeaser>[]) => {
  const urlTag = useUrlQuery().getUrlParamAsSingleton('tag')

  return {
    activeFilter: urlTag,
    posts: urlTag
      ? posts.filter(post => post.tagLabels?.some(tag => tag.label === urlTag))
      : posts,
  }
}
