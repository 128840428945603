import { useMedia } from '@boxine/tonies-ui'

export const useDisplayResolution = () => {
  const isHighRes = useMedia(`
    screen and (min-width:600px) and (-webkit-min-device-pixel-ratio: 2),
    screen and (min-width:600px) and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) 
  `)
  return {
    isHighRes,
  }
}
