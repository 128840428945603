import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import GlobeSVG from '../assets/doodles-ed-welt-flugzeug-globus.svg'
import PaperplaneSVG from '../assets/doodles-md-erweitert-papierflieger.svg'

const DoodleWrapper = styled.div`
  position: relative;
  min-height: 8rem;
`

const Globe = styled(GlobeSVG)`
  justify-self: center;
  position: absolute;
  top: 21px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-20%);
`

const Paperplane = styled(PaperplaneSVG)`
  justify-self: center;
  position: absolute;
  top: 0px;
  left: 50%;
  z-index: 1;
  -webkit-transform: translateX(-50%);
  transform: translateX(-115%);
`

export const GeoIpDoodle: FunctionComponent = () => {
  return (
    <DoodleWrapper>
      <Paperplane />
      <Globe />
    </DoodleWrapper>
  )
}
