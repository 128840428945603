import React from 'react'
import { AggregatedShopLocale } from 'lib/transformation/aggregateShopLocale/types'
import {
  GetStaticPathsResult,
  GetStaticPropsContext,
  GetStaticPropsResult,
} from 'next'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { FunctionComponent } from 'react'
import prerenderPaths from '../../config/prerender/paths.json'
import { isEcomLocale, isLocale } from '../../config/shopAPI'
import { hasZendesk } from '../../config/support'
import { sentryWithExtras } from '../../hooks/useSentry'
import { BlogLayout } from '../../layouts/BlogLayout'
import { BlogPostLayout } from '../../layouts/BlogPostLayout'
import { CareerLayout } from '../../layouts/CareerLayout'
import { ComingSoonLandingLayout } from '../../layouts/ComingSoonLandingLayout'
import { ContactLayout } from '../../layouts/ContactLayout'
import { EducationUserLayout } from '../../layouts/EducationUserLayout'
import { HomePageLayout } from '../../layouts/HomePageLayout'
import { HowItWorksLayout } from '../../layouts/HowItWorksLayout'
import { ImageTextLayout } from '../../layouts/ImageTextLayout'
import { LoadingLayout } from '../../layouts/LoadingLayout'
import { MagicLinkLayout } from '../../layouts/MagicLinkLayout'
import { MainLayout } from '../../layouts/MainLayout'
import { ManualCategoryLayout } from '../../layouts/ManualCategoryLayout'
import { ManualProductListingLayout } from '../../layouts/ManualProductListingLayout'
import { NewsletterLayout } from '../../layouts/NewsletterLayout'
import { OrderSuccessLayout } from '../../layouts/OrderSuccessLayout'
import { PodcastLayout } from '../../layouts/PodcastLayout'
import { ProductDetailLayout } from '../../layouts/ProductDetailLayout'
import { ProductOverviewLayout } from '../../layouts/ProductOverviewLayout'
import { PromotionLayout } from '../../layouts/PromotionLayout'
import { ReferralLandingLayout } from '../../layouts/ReferralLandingLayout'
import { SimpleDocumentLayout } from '../../layouts/SimpleDocumentLayout'
import { TonieboxBundlesLayout } from '../../layouts/TonieboxBundlesLayout'
import { TonieboxLayout } from '../../layouts/TonieboxLayout'
import { TonieSongLayout } from '../../layouts/TonieSongLayout'
import { ShopAllLayout } from '../../layouts/ShopAllLayout'
import { normalizeProductExtended } from '../../lib/commercetools/normalizers/normalizeProduct'
import { getProductBySlug } from '../../lib/commercetools/requests/products/getProductBySlug'
import { getPage } from '../../lib/contentful/client/getPage'
import { getShopLocale } from '../../lib/contentful/client/getShopLocale'
import { getShopLocaleCodes } from '../../lib/contentful/client/getShopLocaleCodes'
import { Layout } from '../../lib/contentful/datamodel/layouts'
import {
  getLimitFromEnv,
  getMostVisitedPaths,
  getPagesList,
  mapBuckets,
} from '../../lib/prerender'
import { aggregatePage } from '../../lib/transformation/aggregatePage'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { aggregatePdp } from '../../lib/transformation/aggregatePdp'
import { aggregateShopLocale } from '../../lib/transformation/aggregateShopLocale'
import { hasPage } from '../../lib/transformation/aggregateShopLocale/hasPage'
import mostVisitedPages from '../../most-visited-pages.json'
import ErrorHandler from '../../pages/_error.page'
import { toLcCC } from '../../providers/locale'
import { isShopMainCategorySlug } from '../../utils/isShopMainCategorySlug'
import { AppStaticProps } from '../_app.page'
import { CompatibleToniesResponseType } from 'lib/cloudservices/requests/rest/getCompatibleContentTonieSalesIds'
import { makeSerializable } from '../../utils/makeSerializable'

type SlugStaticErrorProps = {
  kind: 'error'
  errorCode: number
}

type SlugStaticPageProps = {
  kind: 'page'
  page: Aggregated<Layout>
}

type SlugStaticProductProps = {
  kind: 'product'
  product: NormalizedProductExtended
  compatibleAudioContents: NormalizedProduct[]
  compatibleCreativeTonies: NormalizedProduct[]
  compatibleTonies: CompatibleToniesResponseType
  moreFromSeriesGroup: {
    products: NormalizedProduct[]
    aggregations: NormalizedAggregation[]
  }
  productVariants: NormalizedProductExtended[]
}

type SlugProps =
  | (AppStaticProps & (SlugStaticPageProps | SlugStaticProductProps))
  | SlugStaticErrorProps

export type SlugParams = ParsedUrlQuery & {
  locale: string
  slug: string[]
}

const pageLayouts: {
  [C in Layout['contentTypeId']]: FunctionComponent<
    Aggregated<Extract<Layout, { contentTypeId: C }>>
  >
} = {
  blogPage: BlogLayout,
  blogPostPage: BlogPostLayout,
  careerPage: CareerLayout,
  comingSoonLandingPage: ComingSoonLandingLayout,
  contactPage: ContactLayout,
  educationUserPage: EducationUserLayout,
  homePage: HomePageLayout,
  howItWorksPage: HowItWorksLayout,
  imageTextPage: ImageTextLayout,
  magicLinkPage: MagicLinkLayout,
  mainPage: MainLayout,
  manualCategoryPage: ManualCategoryLayout,
  manualProductListingPage: ManualProductListingLayout,
  newsletterPage: NewsletterLayout,
  orderSuccessPage: OrderSuccessLayout,
  podcastPage: PodcastLayout,
  productOverviewPage: ProductOverviewLayout,
  promotionPage: PromotionLayout,
  referralLandingPage: ReferralLandingLayout,
  shopAllPage: ShopAllLayout,
  simpleDocumentPage: SimpleDocumentLayout,
  tonieboxBundlesPage: TonieboxBundlesLayout,
  tonieboxPage: TonieboxLayout,
  tonieSongPage: TonieSongLayout,
} as const

type PageProps =
  | SlugStaticPageProps
  | SlugStaticProductProps
  | SlugStaticErrorProps

export const Page: FunctionComponent<PageProps> = props => {
  const router = useRouter()

  if (router.isFallback) {
    return <LoadingLayout />
  }

  if (props.kind === 'product') {
    const {
      compatibleAudioContents,
      compatibleCreativeTonies,
      compatibleTonies,
      moreFromSeriesGroup,
      product,
      productVariants,
    } = props

    return (
      <ProductDetailLayout
        compatibleAudioContents={compatibleAudioContents}
        compatibleCreativeTonies={compatibleCreativeTonies}
        compatibleTonies={compatibleTonies}
        moreFromSeriesGroup={moreFromSeriesGroup}
        product={product}
        productVariants={productVariants}
      />
    )
  }

  if (props.kind === 'page') {
    const { page } = props
    const LayoutComponent = pageLayouts[
      page.contentTypeId
    ] as FunctionComponent<typeof page>

    return <LayoutComponent {...page} />
  }

  return <ErrorHandler statusCode={props.errorCode} />
}

export const baseStaticProps = {
  // Next.js will attempt to re-generate the page
  // - when a request comes in
  // - at most once every [seconds]
  // https://nextjs.org/docs/pages/building-your-application/data-fetching/incremental-static-regeneration
  // 15 minutes for production
  // 5 minutes for all other environments
  revalidate:
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? 60 * 15 : 60 * 1,
}

export const notFound = {
  ...baseStaticProps,
  notFound: true,
} as const

/**
 * Creates a normalized page slug from the given lcCC locale code
 * and an optional array of additional slug path elements.
 *
 * Example: "/en-gb/mainpage"
 */
export const toPageSlug = (locale: string, slug: string[] = []) =>
  ['', locale, ...(slug || [])].join('/')

// for test page preview feature on local and dev
// see: https://boxine.atlassian.net/wiki/spaces/TSTW/pages/3703734319/Previewing#Preview-test--pages
function isContentfulTestPage(slug: string[]) {
  return (
    (process.env.NEXT_PUBLIC_ENVIRONMENT === 'local' ||
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev' ||
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'preview') &&
    slug &&
    slug[slug.length - 1].startsWith('test-')
  )
}

export async function getStaticProps(
  context: GetStaticPropsContext<SlugParams>
): Promise<GetStaticPropsResult<SlugProps>> {
  // Retrieve slug and locale from next.js context

  if (!context.params) {
    sentryWithExtras(
      'getStaticProps:params',
      new Error('notFound: missing params'),
      context
    )

    return notFound
  }

  const { locale, slug } = context.params
  const lcCC = toLcCC(locale)

  // Validate locale in slug
  if (!isLocale(lcCC)) {
    sentryWithExtras(
      'getStaticProps:locale',
      new Error('notFound: missing locale'),
      {
        locale,
        slug,
        context,
      }
    )

    return notFound
  }

  let aggregatedShopLocale: AggregatedShopLocale

  try {
    const sl = await getShopLocale(lcCC)
    aggregatedShopLocale = await aggregateShopLocale(sl)
  } catch (error) {
    if (error instanceof Error) {
      sentryWithExtras('getStaticProps:aggregateShopLocale', error, {
        locale,
        slug,
        context,
      })
    }

    return notFound
  }

  // Fetch locale-specific static props
  const pageSlug = toPageSlug(locale, slug)
  const withZendesk = hasZendesk(lcCC)

  if (
    // ensure that page/slug is linked (= made official) in ShopLocale OR is a test page (only on local and dev)
    hasPage(aggregatedShopLocale, pageSlug) ||
    isContentfulTestPage(slug)
  ) {
    /**
     * Contentful page
     */
    try {
      const page = await getPage(pageSlug) // throws if validation fails

      if (page) {
        // Aggregate other content
        const aggPage = await aggregatePage(page)

        return {
          ...baseStaticProps,
          props: makeSerializable({
            kind: 'page',
            aggregatedShopLocale,
            page: aggPage || null,
            // overwrite support button settings with Contentful values, if available
            withZendesk: aggPage.pageMetaData.withZendesk || withZendesk,
            isPageAllowedInAppView:
              aggPage?.pageMetaData.slug ===
              aggregatedShopLocale.commonPages.orderSuccess?.slug,
          }),
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        sentryWithExtras('getStaticProps:aggregatePage', error, {
          pageSlug,
          context,
        })
      }
    }
  }
  /**
   * PDP
   */

  // Is this an EcomLocale with commercetools and therefor products
  if (!isEcomLocale(lcCC)) {
    return notFound
  }

  // PDP slugs have a min. length 2
  // The last part is the product slug we use in the fetch
  const productSlug =
    (slug && slug.length >= 2 && slug[slug.length - 1]) || undefined

  if (!productSlug) {
    sentryWithExtras(
      'getStaticProps:ctSlug',
      new Error('notFound: invalid productSlug'),
      {
        pageSlug,
        context,
      }
    )

    return notFound
  }

  // Does the PDP slug contain a main product category's slug
  if (
    pageSlug !== process.env.DEBUG_PAGE_SLUG &&
    !isShopMainCategorySlug(aggregatedShopLocale, pageSlug)
  ) {
    return notFound
  }

  // Try to fetch the requested product
  try {
    const response = await getProductBySlug(lcCC, productSlug)

    // Return "not found" in case of an empty response or throw on errors
    if (response.result !== 'successful' && response.httpStatus === 404) {
      sentryWithExtras('getStaticProps:getProductBySlug', response.error, {
        ctSlug: productSlug,
        response,
        context,
      })

      return notFound
    }

    if (response.result !== 'successful') {
      // If the backend returned a (temporary) 5xx we don't want to render a 404 page
      // Instead the build should fail, or the previous cached version for that URL version should be served
      return {
        ...baseStaticProps,
        props: {
          kind: 'error',
          errorCode: response.httpStatus || 400,
        },
      }
    }

    // Normalize product data
    const normalizedProduct = normalizeProductExtended(response.data, lcCC)

    // Double-check if the FULL request path matches that of the resolved product.
    // Since we only extracted the product slug to resolve the product earlier,
    // other parts of the URL might not match (e.g. /en-gb/foo/bar/frozen) and cause
    // unwanted SEO duplicates if we returned a product page in response.
    if (normalizedProduct.path !== pageSlug + '/') {
      return notFound
    }

    // Enrich PDPs depending on the product's data
    const pdp = await aggregatePdp(context, lcCC, normalizedProduct)

    return {
      ...baseStaticProps,
      props: makeSerializable({
        kind: 'product',
        aggregatedShopLocale: {
          ...aggregatedShopLocale,
          content: {
            ...aggregatedShopLocale.content,
            urlModularContentSections: pdp.urlModularContentSection,
          },
        },
        product: normalizedProduct,
        compatibleAudioContents: pdp.compatibleAudioContents,
        compatibleCreativeTonies: pdp.compatibleCreativeTonies,
        compatibleTonies: pdp.compatibleTonies,
        moreFromSeriesGroup: pdp.moreFromSeriesGroup,
        productVariants: pdp.productVariants,
        withZendesk,
      }),
    }
  } catch (error) {
    if (error instanceof Error) {
      sentryWithExtras('getStaticProps:getProductBySlug', error, {
        ctSlug: productSlug,
        context,
      })
    }
  }

  return notFound
}

export const getStaticPaths = async (): Promise<GetStaticPathsResult> => {
  // Most visited paths
  // Adding paths to ignore with a bad word list because ELK (data from 'most-visited-pages.json') adds all requested paths
  // Removing this will cause conflicts of existing pre-rendered pages, unnecessary rendering of 404 pages and errors in the serverless functions
  const pagesList = getPagesList() // locale specific paths (e.g. /en-gb/cart) which are pre-rendering anyway
  const badWords = [
    // If a path contains any of these words, it will be excluded from the list
    '/r/', // redirect urls
    ...pagesList,
  ]
  const limit = getLimitFromEnv()
  // See mapBuckets() for a RegExp we use to match only paths that either come from Contentful or are commercetools products paths
  const buckets = mapBuckets(mostVisitedPages.aggregations[2].buckets, badWords)
  const mostVisitedPaths = getMostVisitedPaths(buckets, limit)

  // Contentful common pages paths
  // These are our "core" pages and should always be pre rendered.
  let commonPagesPaths: string[] = []
  const locales = await getShopLocaleCodes()

  for (const lcCC of locales) {
    const shopLocale = await getShopLocale(lcCC)
    const aggregatedShopLocale = await aggregateShopLocale(shopLocale)

    commonPagesPaths = commonPagesPaths.concat(
      Object.values(aggregatedShopLocale.commonPages)
        // filter /{lc-cc}/blog because this route has its own getStaticPaths
        .filter(p => !p.slug.match(/^\/[a-z]{2}-[a-z]{2}\/blog/))
        .map(p => p.slug)
    )
  }

  // e2e product paths
  // These make sure that we prerender at least 1
  // PDP for every product type in all environments other than prod.
  // This makes sure that errors in our code will pop up prior to a prod release.
  // TODO
  // Can we fetch these from commercetools?
  const e2ePaths =
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
      ? []
      : prerenderPaths.e2e

  const allPaths = mostVisitedPaths.concat(commonPagesPaths).concat(e2ePaths)
  const paths = [...new Set(allPaths)]

  return {
    paths,
    fallback: 'blocking', // allow fetching additional pages at runtime
  }
}

export default Page
