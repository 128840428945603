import { media } from '@boxine/tonies-ui'
import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  position: absolute;
  top: -1rem;
  right: 1rem;
  width: 3.75rem;
  height: 3.75rem;

  ${media.tablet`
    right: 6rem;
    top: -2.5rem;
    height: 5rem; 
    width: 5rem;
  `}
`

export const DragonFly = () => (
  <Svg viewBox="0 0 79 79">
    <defs>
      <path d="M.519.8h24.335v27.21H.52z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M40.027 34.98a38.584 38.584 0 0 0 12.196-8.337c1.25-1.27 2.416-2.629 3.773-3.784 1.207-1.028 2.55-1.881 3.839-2.803a44.102 44.102 0 0 0 4.705-3.874c.51-.482 1.021-.986 1.66-1.272.641-.286 1.451-.308 1.992.137.744.614.67 1.76.465 2.701-1.185 5.456-4.591 10.346-9.145 13.577-4.553 3.232-10.193 4.825-15.776 4.734-.85-.013-1.72-.069-2.502-.402l-1.207-.676z"
        fill="#FEFEFE"
      />
      <g transform="rotate(14 -10.506 190.821)">
        <path
          d="M16.06 21.433c-3.545 3.068-9.56 6.65-14.502 5.794 4.156-3.34 7.47-7.359 10.111-12.003 1.51-2.657 3.322-4.86 5.278-7.198a44.018 44.018 0 0 0 2.54-3.334c.36-.523.769-1.421 1.226-1.862 2.232-1.27 3.348-1.17 3.348.297.077.434.104.87.08 1.31.078 6.468-3.259 12.825-8.08 16.996m8.637-18.225c-.28-1.84-1.649-3.159-3.49-1.93-.995.665-1.601 2.048-2.258 3-1.307 1.9-2.807 3.613-4.3 5.364-2.354 2.76-3.814 6.042-5.887 8.994-2.263 3.222-5.009 6.03-8.11 8.447a.16.16 0 0 0-.09.092c-.13.178.048.463.262.517l.007.003c5.486 1.495 12.289-2.622 16.212-6.22C21.74 17.168 25.701 9.8 24.698 3.208"
          fill="#151616"
        />
      </g>
      <path
        d="M43.231 38.842a85.81 85.81 0 0 0 18.74-5.684c2.677-1.16 5.298-2.458 8.038-3.46 1.069-.393 2.208-.743 3.33-.55.475.084.965.29 1.21.705.367.62.05 1.41-.3 2.04-3.122 5.646-8.644 9.81-14.803 11.733-1.347.42-2.734.743-4.145.814-2.149.11-4.283-.363-6.372-.884-1.137-.283-2.285-.588-3.296-1.179-1.012-.59-1.886-1.506-2.171-2.642l-.23-.893z"
        fill="#FEFEFE"
      />
      <path
        d="M72.146 34.506c-3.213 4.182-7.826 7.255-12.85 8.812-3.094.959-6.049.908-9.192.194-2.885-.655-5.57-1.409-6.502-4.402 5.555-1.057 11.002-2.6 16.255-4.701 2.732-1.092 5.372-2.388 8.083-3.527 1.374-.577 3.26-1.644 4.766-1.407 3.006.474.316 3.892-.56 5.031m2.284-5.2c-1.685-1.556-5.136.267-6.79.96-8.084 3.387-15.805 6.623-24.488 8.247a.226.226 0 0 0-.143.088.202.202 0 0 0-.04.053v.001a.302.302 0 0 0-.015.22c.57 2.208 1.706 3.553 3.88 4.394 3.037 1.174 6.712 1.853 9.948 1.352 6.338-.984 12.454-4.985 16.198-10.136.862-1.187 3.03-3.719 1.45-5.179"
        fill="#151616"
      />
      <path
        d="M36.04 36.623c-3.79 2.452-8.176 3.79-12.498 5.099-.994.3-1.99.603-3.004.826-1.307.289-2.638.447-3.966.605l-8.68 1.032c-1.316.156-2.93.569-3.207 1.865-.184.864.371 1.725 1.046 2.295.942.797 2.13 1.239 3.313 1.587 8.358 2.457 17.837.72 24.782-4.54 1.323-1.002 2.574-2.143 3.438-3.561.862-1.418 1.307-3.146.938-4.764l-2.161-.444z"
        fill="#FEFEFE"
      />
      <path
        d="M28.853 47.995c-5.381 2.534-11.574 3.335-17.416 2.192-1.48-.29-3.07-.63-4.405-1.342-1.512-.806-2.715-3.12-.717-3.96.787-.33 1.687-.37 2.524-.474 3.395-.421 6.821-.704 10.2-1.242 5.803-.924 12.105-3.104 17.095-6.205.494.08 1.426.088 1.735.36 1.134.996-.752 4.315-1.644 5.393-1.915 2.315-4.68 4.01-7.372 5.278m9.626-10.962a.384.384 0 0 0-.283-.293l-2.074-.425a.21.21 0 0 0-.178-.017c-.051.007-.095.024-.124.059-7.116 4.417-15.487 6.01-23.668 6.983-1.912.227-7.024-.128-7.72 2.505-.584 2.204 2.236 3.602 3.835 4.167 6.025 2.131 13.028 1.687 18.94-.572 5.017-1.918 12.269-6.056 11.272-12.407"
        fill="#151616"
      />
      <path
        d="M39.91 41.35a103.532 103.532 0 0 1-13.224 12.933c-1.441 1.177-2.92 2.32-4.195 3.675-1.453 1.545-2.624 3.348-4.164 4.807-.329.31-.685.621-.85 1.043-.34.867.305 1.873 1.16 2.245.853.371 1.832.27 2.75.118 6.94-1.145 13.432-4.83 17.97-10.203 1.089-1.288 2.09-2.716 2.482-4.356.243-1.014.242-2.067.237-3.109a479.148 479.148 0 0 0-.043-4.655c-.013-.884-.074-1.875-.727-2.47l-1.395-.027z"
        fill="#FEFEFE"
      />
      <path
        d="M40.949 53.083c-.676 1.365-1.702 2.562-2.735 3.671a30.057 30.057 0 0 1-14.157 8.497c-1.111.296-2.295.608-3.446.689-1.765.4-2.456-.57-2.073-2.91l.561-.562c.45-.623 1.065-1.213 1.554-1.81 1.898-2.315 3.932-4.212 6.243-6.11A107.402 107.402 0 0 0 40.062 41.68c1.318.024 1.48-.022 1.673 1.846.14 1.354.041 2.787.048 4.149.01 1.874.022 3.68-.834 5.407m1.354-9.722c-.182-1.906-.676-2.285-2.365-2.334a.186.186 0 0 0-.168.008.168.168 0 0 0-.127.115c-2.916 3.38-6.017 6.589-9.334 9.579-3.233 2.914-6.803 5.397-9.578 8.779-.673.82-1.324 1.656-2.053 2.43-.45.478-1.225.997-1.455 1.665-.239.687-.086 1.362.34 1.946 1.556 2.131 5.387.737 7.365.16 6.214-1.811 13.18-6.045 16.366-11.895 1.615-2.963 1.322-7.198 1.01-10.453"
        fill="#151616"
      />
      <path
        d="M36.27 27.101c-.812.055-1.671.17-2.298.69-.626.518-.896 1.338-1.139 2.114-.124.397-.248.824-.121 1.22.126.396.493.682.892.8.398.119.827.089 1.236.016 1.22-.222 2.334-.822 3.412-1.435.368-.209.741-.425 1.022-.74.282-.316.46-.753.366-1.166-.117-.512-.607-.845-1.083-1.07a6.716 6.716 0 0 0-1.377-.473l-.91.044z"
        fill="#FEFEFE"
      />
      <path
        d="M36.448 31.06c-.51.228-1.122.546-1.687.55-.775.212-1.362-.178-1.762-1.168.07-.267.15-.532.24-.794.55-1.566 1.544-2.091 3.11-2.218.869-.042 1.97.02 2.624.685 1.333 1.359-1.705 2.58-2.525 2.945m3.455-2.537c-.394-1.475-2.433-1.813-3.713-1.75-2.156.173-3.72 1.497-3.812 3.815-.08 2.025 2.304 1.842 3.605 1.393 1.281-.441 4.417-1.589 3.92-3.458"
        fill="#151616"
      />
      <path
        d="M35.624 31.306c-.427 1.386-.216 2.906.303 4.261s1.329 2.574 2.133 3.782c1.16 1.743 2.328 3.497 3.773 5.011.871.914 1.84 1.737 2.647 2.706 1.826 2.192 2.717 4.984 3.825 7.61 1.99 4.726 4.778 9.091 6.654 13.863.232.591.457 1.2.859 1.69.66.808 1.696 1.185 2.681 1.528.36.126.754.252 1.116.128.783-.267.77-1.39.469-2.16-.502-1.279-1.364-2.378-2.199-3.469-2.563-3.355-4.972-6.847-6.874-10.616-.797-1.578-1.503-3.205-2.398-4.729-.926-1.577-2.06-3.064-2.62-4.806-.377-1.172-.477-2.412-.74-3.615-.692-3.182-2.481-5.998-4.239-8.74-.618-.964-1.276-1.967-2.279-2.522-1.002-.555-2.451-.483-3.093.466l-.018-.388z"
        fill="#FEFEFE"
      />
      <path
        d="M58.766 70.23c-.262 1.003-.72 1.26-1.377.77-.93-.365-1.579-1.028-1.947-1.99-.468-.89-.77-1.904-1.186-2.824-.78-1.721-1.653-3.397-2.521-5.075-1.674-3.238-3.09-6.53-4.539-9.87-1.373-3.167-3.445-5.124-5.694-7.657-2.587-2.912-6.244-7.483-5.683-11.61.011-.008.023-.003.034-.015 3.404-3.586 8.29 7.43 8.945 9.837.396 1.459.47 2.99.948 4.43.584 1.754 1.7 3.224 2.62 4.805 1.703 2.925 2.986 6.056 4.783 8.938a76.077 76.077 0 0 0 3.116 4.583c.602.824 1.233 1.628 1.84 2.45.28.363.549.736.807 1.117.91.767.862 1.471-.146 2.112m-.473-3.899c-2.599-3.44-5.082-6.957-7.036-10.813-.799-1.577-1.509-3.197-2.397-4.728-1.19-2.05-2.426-3.787-2.937-6.152-.85-3.935-2.128-7.035-4.305-10.453-1.173-1.84-3.517-4.963-5.823-3.084-.104-.108-.25-.155-.353-.084a.216.216 0 0 0-.04.03l-.005.007a.23.23 0 0 0-.05.11c-.001.004-.003.005-.003.009-.697 3.028.692 5.6 2.317 8.07 1.072 1.628 2.155 3.27 3.445 4.736 1.464 1.664 3.19 3.018 4.34 4.95 1.908 3.211 2.994 6.925 4.684 10.26.915 1.806 1.874 3.59 2.765 5.41.829 1.689 1.373 3.669 2.35 5.264.692 1.125 3.65 3.365 4.96 2.033 1.462-1.49-1.111-4.504-1.912-5.565"
        fill="#151616"
      />
      <path
        d="M34.595 28.655c-.699.07-1.473.455-1.583 1.148-.1.626.47 1.242 1.103 1.28.633.038 1.234-.426 1.455-1.02.132-.358.122-.814-.167-1.063l-.808-.345zm4.175-.885c-.483-.246-1.101-.258-1.552.045-.45.303-.67.941-.43 1.428.097.198.259.36.44.485.433.3 1.01.399 1.504.214.492-.186.871-.67.883-1.197.012-.526-.377-1.048-.895-1.142l.05.167z"
        fill="#FEFEFE"
      />
      <path
        d="M34.733 30.576c-.507.315-1.458.162-1.444-.588.012-.64.807-.916 1.333-.995.219.079.506.152.661.288.349.304-.21 1.085-.55 1.295m-.07-2.224-.007-.002a.257.257 0 0 0-.09-.02c-.015 0-.032-.005-.045-.004h-.006c-.883.115-1.885.664-1.795 1.692.075.863.96 1.539 1.808 1.367.845-.171 1.515-1.038 1.396-1.905-.1-.732-.674-.877-1.26-1.128m4.435.91c-.342.424-.915.53-1.414.357-.63-.218-.948-.874-.426-1.396.386-.385.98-.36 1.443-.15.117.053.202.029.258-.028l.007-.005c.404.26.452.824.132 1.222m-.384-1.986c-.088-.022-.154.016-.203.073-.783-.215-1.657.01-1.998.836-.372.904.248 1.776 1.105 2.071.866.297 1.87-.067 2.19-.961.31-.867-.223-1.79-1.094-2.019"
        fill="#151616"
      />
    </g>
  </Svg>
)
