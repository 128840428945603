import React, { FunctionComponent } from 'react'
import { StageVideo } from '../../components/organisms/StageVideo'
import { SeoText } from '../../components/molecules/SeoText'
import { PageLayout } from '../PageLayout'
import { MainLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { Section } from '../../components/atoms/Section'
import {
  useIsViewportDesktop,
  Headline2,
  Paragraph,
  Spacing,
} from '@boxine/tonies-ui'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { FullWidthMedia } from '../../components/organisms/FullWidthMedia'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'
import { Carousel } from '../../components/organisms/Carousel'
import { useTheme } from 'styled-components'
import { GeoIpModal } from '../../components/organisms/GeoIpModal'
import { FlashMessage } from '@/tonies-ui/index'

export const MainLayout: FunctionComponent<Aggregated<MainLayoutSchema>> = ({
  stage,
  editorialTeaserList,
  videoText,
  marketingTeaserList,
  testimonials,
  partnerLogos,
  community,
  seoText,
  pageMetaData,
  productItemTeaserCarousel,
  fullWidthImage,
}) => {
  const isDesktop = useIsViewportDesktop()
  const { colors } = useTheme()

  return (
    <PageLayout
      dataTestId="mainpage-pagelayout"
      meta={pageMetaData}
      stickyFooterChildren={
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev' && (
          <Spacing m="spacing-m">
            <FlashMessage type="error" isPersistent={true}>
              <Paragraph size={2}>
                The contentful layout &quot;Main Page&quot; is deprecated and
                will be removed soon. Please migrate to e.g. the &quot;Home
                Page&quot; layout. Please post your migration update{' '}
                <a href="https://boxine.slack.com/archives/C02DQHVSXB8/p1694442258667309">
                  here
                </a>
                . This is a warning only shown on dev.
              </Paragraph>
            </FlashMessage>
          </Spacing>
        )
      }
    >
      {stage.contentTypeId === 'mediaCopySection' && (
        <ContentModels.MediaCopySection
          {...stage}
          componentProps={{ hasMarginTop: true }}
        />
      )}

      {stage.contentTypeId === 'carousel' && (
        <ContentModels.Carousel
          {...stage}
          componentProps={{ hasMarginTop: true }}
        />
      )}

      <Section
        backgroundColor={editorialTeaserList.backgroundColor}
        removePaddingX={editorialTeaserList.variant === 'slider'}
      >
        <ContentModels.EditorialTeaserList {...editorialTeaserList} />
      </Section>

      <Section
        aspectRatio={{
          ratio: isDesktop ? 56.25 : undefined,
          alignContent: isDesktop ? 'center' : undefined,
        }}
      >
        <StageVideo
          title={
            <Headline2 asHTMLTag="h3">
              <Accent text={videoText.title} />
            </Headline2>
          }
          text={videoText.text}
          ctaText={videoText.ctaText}
          ctaUrl={videoText.ctaUrl}
          video={{
            mobile: videoText.videoMobile,
            desktop: videoText.videoDesktop,
          }}
        />
      </Section>

      {productItemTeaserCarousel?.skuList && (
        <Section backgroundColor={productItemTeaserCarousel.backgroundColor}>
          <ContentModels.ProductItemTeaserCarousel
            {...productItemTeaserCarousel}
          />
        </Section>
      )}

      <FullWidthMedia {...fullWidthImage} />

      <Section backgroundColor={colors['lightblue-20']}>
        <Carousel isContentWidth hasSidePadding items={testimonials} />
        <ContentModels.Gallery {...partnerLogos} />
      </Section>

      <Section
        backgroundColor={marketingTeaserList.backgroundColor}
        removePaddingX={marketingTeaserList.variant === 'slider'}
      >
        <ContentModels.EditorialTeaserList {...marketingTeaserList} />
      </Section>

      <Section
        backgroundColor={community.backgroundColor}
        removePaddingX={community.variant === 'slider'}
      >
        <ContentModels.Community {...community} />
      </Section>

      {seoText?.content && (
        <Section>
          <SeoText document={seoText.content} />
        </Section>
      )}
      <GeoIpModal />
    </PageLayout>
  )
}
