import React from 'react'
import { BxAddress } from '../../../../lib/commercetools/requests/bxTypes'
import { useAddresses } from '../../../../providers/addresses'
import { ReadOnlyView } from './ReadOnlyView'

export const ReadOnly = ({
  billingAddress,
  shippingAddress,
}: {
  hasEditOption?: boolean
  billingAddress: BxAddress
  shippingAddress?: BxAddress
}) => {
  const { areCartAddressesEqual } = useAddresses()

  return (
    <ReadOnlyView
      billingAddress={billingAddress}
      shippingAddress={shippingAddress}
      handleEditMode={undefined}
      isBillingAsShipping={areCartAddressesEqual ?? false}
    />
  )
}
