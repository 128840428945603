import React, { FunctionComponent } from 'react'
import { Section } from '../../components/atoms/Section'
import { ManualCategoryLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { PageLayout } from '../PageLayout'
import { useAggregatedShopLocale } from '../../providers/aggregatedShopLocale'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { DeliveryInfoTeaserCollection } from '../../components/organisms/DeliveryInfoTeaserCollection'
import { SeoText } from '../../components/molecules/SeoText'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'

export const ManualCategoryLayout: FunctionComponent<
  Aggregated<ManualCategoryLayoutSchema>
> = props => {
  const {
    pageMetaData,
    stage,
    textHero,
    editorialTeaserList,
    productItemTeaserCarousel,
    seoText,
  } = props
  const aggregatedShopLocale = useAggregatedShopLocale()

  return (
    <PageLayout meta={pageMetaData}>
      {stage && (
        <ContentModels.MediaCopySection
          {...stage}
          componentProps={{ hasMarginTop: true }}
        />
      )}

      {textHero && <ContentModels.TextHero {...textHero} />}

      <Section
        backgroundColor={editorialTeaserList.backgroundColor}
        isStageSection={!stage && !textHero}
        removePaddingX={editorialTeaserList.variant === 'slider'}
      >
        <ContentModels.EditorialTeaserList {...editorialTeaserList} />
      </Section>

      {productItemTeaserCarousel && productItemTeaserCarousel.skuList && (
        <Section backgroundColor={productItemTeaserCarousel.backgroundColor}>
          <ContentModels.ProductItemTeaserCarousel
            {...productItemTeaserCarousel}
          />
        </Section>
      )}

      <Section>
        <DeliveryInfoTeaserCollection
          items={aggregatedShopLocale?.content.deliveryInfoTeaserList}
        />
      </Section>

      {seoText?.content && (
        <Section>
          <SeoText document={seoText.content} />
        </Section>
      )}
    </PageLayout>
  )
}
