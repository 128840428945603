import React, { FunctionComponent } from 'react'
import ReactMarkdown from 'react-markdown'
import {
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  Headline5,
  Paragraph,
  List,
  ListItem,
  Spacing,
  ThemeSpacing,
} from '@boxine/tonies-ui'
import { SupportedSizes } from '@boxine/tonies-ui/dest/types/src/atoms/Typography/types'
import styled from 'styled-components'

export type Props = {
  md: string
  headlinesMarginBottom?: ThemeSpacing // optional margin-bottom for headlines
  textsSize?: SupportedSizes // font-size for p, ul and ol
  textsMarginBottom?: ThemeSpacing // optional margin-bottom for p, ul and ol
}

const StyledWrapper = styled.div`
  strong {
    font-weight: 500;
  }
`

export const Markdown: FunctionComponent<Props> = ({
  md,
  headlinesMarginBottom,
  textsSize,
  textsMarginBottom,
}) => {
  return (
    <StyledWrapper>
      <ReactMarkdown
        components={{
          /**
           * TODO: integrate <Accent /> in headlines
           */
          // eslint-disable-next-line react/display-name
          h1: ({ children }) => (
            <>
              <Spacing mb={headlinesMarginBottom}>
                <Headline1>{children}</Headline1>
              </Spacing>
            </>
          ),
          // eslint-disable-next-line react/display-name
          h2: ({ children }) => (
            <>
              <Spacing mb={headlinesMarginBottom}>
                <Headline2>{children}</Headline2>
              </Spacing>
            </>
          ),
          // eslint-disable-next-line react/display-name
          h3: ({ children }) => (
            <>
              <Spacing mb={headlinesMarginBottom}>
                <Headline3>{children}</Headline3>
              </Spacing>
            </>
          ),
          // eslint-disable-next-line react/display-name
          h4: ({ children }) => (
            <>
              <Spacing mb={headlinesMarginBottom}>
                <Headline4>{children}</Headline4>
              </Spacing>
            </>
          ),
          // eslint-disable-next-line react/display-name
          h5: ({ children }) => (
            <>
              <Spacing mb={headlinesMarginBottom}>
                <Headline5>{children}</Headline5>
              </Spacing>
            </>
          ),
          // eslint-disable-next-line react/display-name
          p: ({ children }) => (
            <>
              <Spacing mb={textsMarginBottom}>
                <Paragraph size={textsSize}>{children}</Paragraph>
              </Spacing>
            </>
          ),
          // eslint-disable-next-line react/display-name
          ul: ({ children }) => (
            <>
              <Spacing mb={textsMarginBottom}>
                <List size={textsSize}>{children}</List>
              </Spacing>
            </>
          ),
          // eslint-disable-next-line react/display-name
          ol: ({ children }) => (
            <>
              <Spacing mb={textsMarginBottom}>
                <List type="ordered" size={textsSize}>
                  {children}
                </List>
              </Spacing>
            </>
          ),
          // eslint-disable-next-line react/display-name
          li: ({ children }) => <ListItem>{children}</ListItem>,
        }}
      >
        {md.trim()}
      </ReactMarkdown>
    </StyledWrapper>
  )
}
