import React, { FunctionComponent } from 'react'
import styled, { useTheme } from 'styled-components'
import InfoIcon from '../assets/info.svg'
import NoteIcon from '../assets/note.svg'
import BasketIcon from '../assets/basket.svg'
import Background from '../assets/background.svg'

export interface IconProps {
  iconType: 'basket' | 'note' | 'info'
  iconBackgroundColor: string
}

const Icon: FunctionComponent<IconProps> = ({
  iconType = 'info',
  iconBackgroundColor,
}) => {
  const { colors } = useTheme()

  return (
    <>
      <StyledWrapper>
        <StyledIcon>
          {iconType === 'info' && <InfoIcon />}
          {iconType === 'note' && <NoteIcon />}
          {iconType === 'basket' && <BasketIcon />}
        </StyledIcon>
        <StyledBackground>
          <Background fill={iconBackgroundColor || colors['yellow-100']} />
        </StyledBackground>
      </StyledWrapper>
    </>
  )
}

const StyledWrapper = styled.div`
  border-top-left-radius: 1rem;
  position: relative;
  width: 80px;
  height: 65px;
  overflow: hidden;
`
const StyledIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-65%, -50%);
  z-index: +1;
`
const StyledBackground = styled.div`
  position: absolute;
`

export { Icon }
