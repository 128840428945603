import React, { FunctionComponent } from 'react'
import { useTheme } from 'styled-components'
import { PageLayout } from '../PageLayout'
import { Grid, GridArea, useIsViewportDesktop } from '@boxine/tonies-ui'
import { Section } from '../../components/atoms/Section'
import { ImageText } from '../../components/organisms/ImageText'
import { EducationUserLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { VideoText } from '../../components/organisms/VideoText'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'
import { Carousel } from '../../components/organisms/Carousel'
import { Image } from '@/tonies-ui/atoms/Image'

export const EducationUserLayout: FunctionComponent<
  Aggregated<EducationUserLayoutSchema>
> = ({
  pageMetaData,
  stage: { content: stageContent, ...stage },
  productExplanation1,
  productExplanation2,
  productExplanation3,
  testimonialList,
  videoTextComponent,
  quotationHighlight,
  coreFeatures,
  reportDownload,
  miscImages,
  imageTeasers,
}) => {
  const isDesktop = useIsViewportDesktop()
  const { colors } = useTheme()

  return (
    <PageLayout meta={pageMetaData}>
      <ContentModels.MediaCopySection {...stage} />

      {/* Product explanation */}
      <Section>
        <ImageText {...productExplanation1} />
        <ImageText {...productExplanation2} />
        <ImageText {...productExplanation3} />
      </Section>

      {/* Testimonials */}
      <Carousel isContentWidth hasSidePadding items={testimonialList} />

      {/* Video Text Component */}
      <VideoText
        ctaText={videoTextComponent.ctaText}
        ctaUrl={videoTextComponent.ctaUrl}
        posterDesktop={videoTextComponent.posterDesktop}
        posterMobile={videoTextComponent.posterMobile}
        text={videoTextComponent.text}
        title={videoTextComponent.title}
        headlineAsHTMLTag={videoTextComponent.headlineAsHTMLTag}
        videoAlt={videoTextComponent.videoAlt}
        videoDesktop={videoTextComponent.videoDesktop.file.url}
        videoMobile={videoTextComponent.videoMobile.file.url}
      />

      {/* Misc Images */}
      <Section>
        <Grid
          hasGapH
          hasGapV={!isDesktop}
          columns={isDesktop ? miscImages.length : 2}
        >
          {miscImages.map((image, i) => (
            <GridArea key={i}>
              <figure style={{ lineHeight: 0 }}>
                <Image
                  ratio={[1, 1]}
                  responsive={[220, 250, 280, 300, 360, 480]}
                  src={image.file.url}
                  alt={image.title}
                />
              </figure>
            </GridArea>
          ))}
        </Grid>
      </Section>

      {/* Quotation Highlight */}
      <ContentModels.TextHero {...quotationHighlight} />

      {/* Core Features */}
      <Section backgroundColor={coreFeatures.backgroundColor}>
        <ContentModels.MediaCopyCollection {...coreFeatures} />
      </Section>

      {/* Report download */}
      <Section>
        <ImageText {...reportDownload} />
      </Section>

      {/* Image teasers */}
      <Section backgroundColor={colors['lightblue-20']}>
        <ContentModels.MediaCopyCollection {...imageTeasers} />
      </Section>
    </PageLayout>
  )
}
