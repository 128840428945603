import React, { FunctionComponent } from 'react'
import { Carousel } from '../../../components/molecules/Carousel'
import styled from 'styled-components'
import { CarouselChangeHandler } from '../../../components/molecules/Carousel/types'
import {
  media,
  useIsViewportMobile,
  useIsViewportTablet,
} from '@boxine/tonies-ui'
import { Stopper } from './Stopper'
import { Image } from '@/tonies-ui/atoms/Image'
import { ToggleFromWishlist } from '../../../components/atoms/ToggleFromWishlist'

export type Props = {
  onChange?: CarouselChangeHandler
  product: NormalizedProductExtended
}

const Wrapper = styled.div`
  position: relative; /* needed for absolute positioning of the StopperWrapper */
`

const StopperWrapper = styled.div`
  left: 0;
  position: absolute;
  top: 0.5rem;
  z-index: 1;

  ${media.tablet`
    left: 0.5rem;
  `}
`

const WishlistWrapper = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 1;
`

const ImageContainer = styled.div`
  padding-bottom: 4rem;
  width: 100%;
`

export const ImageCarousel: FunctionComponent<Props> = ({
  onChange,
  product,
}) => {
  const { images, name } = product
  const isMobile = useIsViewportMobile()
  const isTablet = useIsViewportTablet()
  const imageWidth = isTablet ? 524 : 668

  return (
    <Wrapper>
      <StopperWrapper>
        <Stopper
          borderLeft={isMobile ? 'straight' : 'round'}
          product={product}
        />
      </StopperWrapper>
      <WishlistWrapper>
        <ToggleFromWishlist product={product} />
      </WishlistWrapper>
      <Carousel
        ariaTitle={name}
        naturalSlideWidth={imageWidth}
        visibleSlides={1}
        buttons="none"
        hasPagination
        isInfinite={false}
        isIntrinsicHeight
        onChange={onChange}
      >
        {images.map((image, i) => (
          <ImageContainer key={i}>
            <Image
              src={image.src}
              alt={image.alt}
              ratio={[4, 3]}
              responsive={[240, 360, 480, 524, 668]}
              crop="fill"
              background="#ffffff"
            />
          </ImageContainer>
        ))}
      </Carousel>
    </Wrapper>
  )
}
