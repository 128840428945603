import { BlogTeaser } from '../../../components/molecules/GenericImageText/variants/BlogTeaser'
import { Aggregated } from '../../../lib/transformation/aggregatePage/aggregate'
import { useUrlQuery } from '../../../providers/urlQuery'

export const useCategoryFilter = (posts: Aggregated<BlogTeaser>[]) => {
  const urlCategory = useUrlQuery().getUrlParamAsSingleton('category')

  return {
    activeFilter: urlCategory,
    posts: urlCategory
      ? posts.filter(post => post.categoryLabel.label === urlCategory)
      : posts,
  }
}
