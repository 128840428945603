import React, { FunctionComponent, ReactNode, useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Grid,
  GridArea,
  Button,
  useIsViewportDesktop,
  media,
} from '@boxine/tonies-ui'
import { ColorContextProvider } from '@/tonies-ui/index'
import { ContentfulVideo } from '../../../lib/contentful/datamodel/schemas'
import { TextProps } from '../ImageText/components/Text'
import { RichText } from '../../molecules/RichText'

export type StageVideoProps = {
  title: ReactNode
  text?: TextProps['text']
  ctaUrl?: string
  ctaText?: string
  video: {
    description?: string
    mobile: ContentfulVideo
    desktop: ContentfulVideo
  }
}

const VideoWrapper = styled.div`
  position: relative;
  margin: 0 -1rem -1.25rem;
  line-height: 0;

  ${media.laptop`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  `}
`
const Video = styled.video`
  width: 100%;
  max-width: 100%;

  ${media.laptop`
    object-fit: cover;
    height: 100%;
    margin: 0;
  `}
`
const ContentWrapper = styled.div`
  position: relative;
  z-index: 10;
  text-align: center;
  margin-bottom: 1rem;

  ${media.laptop`
    text-align: left;
    margin-bottom: 0;
  `}

  ${media.laptopL`
    margin-left: 0rem;
  `}
`

const DistanceAtBottom = styled.div`
  margin-bottom: 1.5rem;

  ${media.laptop`
    // Stretch grid from 1024px+ to prevent short text from
    // placing content in the middle of the layout
    width: 37.5rem;
    `}
`

const ComponentWrapper = styled.div`
  display: flex;
  flex-flow: column;
`

export const StageVideo: FunctionComponent<StageVideoProps> = ({
  title,
  text,
  ctaText,
  ctaUrl,
  video,
}) => {
  const isDesktop = useIsViewportDesktop()
  const [videoSrc, setVideoSrc] = useState('')

  useEffect(() => {
    if (isDesktop) {
      if (videoSrc !== video.desktop.file.url) {
        setVideoSrc(video.desktop.file.url)
      }
    } else {
      if (videoSrc !== video.mobile.file.url) {
        setVideoSrc(video.mobile.file.url)
      }
    }
  }, [isDesktop, video, videoSrc])

  return (
    <ColorContextProvider backgroundColor={isDesktop ? '#000' : '#fff'}>
      <ComponentWrapper>
        <ContentWrapper>
          <Grid>
            <GridArea colWidth={isDesktop ? 5 : 12}>
              {title}
              {text && (
                <DistanceAtBottom>
                  <RichText
                    align={isDesktop ? 'left' : 'center'}
                    document={text}
                  />
                </DistanceAtBottom>
              )}
              {ctaText && ctaUrl && (
                <Button as="a" href={ctaUrl}>
                  {ctaText}
                </Button>
              )}
            </GridArea>
          </Grid>
        </ContentWrapper>
        <VideoWrapper>
          <Video
            autoPlay
            loop
            muted
            playsInline
            title={isDesktop ? video.desktop.title : video.mobile.title}
            src={videoSrc}
          >
            {video.description}
          </Video>
        </VideoWrapper>
      </ComponentWrapper>
    </ColorContextProvider>
  )
}
