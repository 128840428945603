import { Headline1, Paragraph, Spacing } from '@boxine/tonies-ui'
import React, { FunctionComponent } from 'react'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { Section } from '../../components/atoms/Section'
import { SeoText } from '../../components/molecules/SeoText'
import { ImageTextWrapper } from './components/ImageTextWrapper'
import { ImageText } from '../../components/organisms/ImageText'
import { ReferralSection } from '../../components/organisms/ReferralSection'
import { ReferralLandingLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { PageLayout } from '../PageLayout'
import { contentfulImage } from '../../utils/contentful-helpers'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'

export const ReferralLandingLayout: FunctionComponent<
  Aggregated<ReferralLandingLayoutSchema>
> = ({
  pageMetaData,
  referralSection,
  referralFlowHeadline,
  referralFlowText,
  referralFlowStep1Headline,
  referralFlowStep1Text,
  referralFlowStep2Headline,
  referralFlowStep2Text,
  referralFlowStep3Headline,
  referralFlowStep3Text,
  referralFlowStep4Headline,
  referralFlowStep4Text,
  frequentlyAskedQuestions,
  productItemTeaserCarousel,
  seoText,
}) => (
  <PageLayout meta={pageMetaData}>
    <ReferralSection {...referralSection} />

    <Section>
      <div>
        <Headline1 asHTMLTag="h2" align="center">
          <Accent text={referralFlowHeadline} />
        </Headline1>
        {referralFlowText && (
          <Spacing mt="spacing-m" mb="spacing-m">
            <Paragraph align="center">{referralFlowText}</Paragraph>
          </Spacing>
        )}
      </div>
    </Section>

    <ImageTextWrapper>
      <ImageText
        contentPositionMobile="bottom"
        title={referralFlowStep1Headline}
        text={referralFlowStep1Text}
        imageMobile={contentfulImage(
          'https://images.ctfassets.net/l6vo76cztcax/5aR2gGsZ7MVviP0EJUy41t/555abb7d1d0287d67e3adf10b66e4e1e/referral-flow-1.png',
          850,
          365
        )}
        imageDesktop={contentfulImage(
          'https://images.ctfassets.net/l6vo76cztcax/5aR2gGsZ7MVviP0EJUy41t/555abb7d1d0287d67e3adf10b66e4e1e/referral-flow-1.png',
          850,
          365
        )}
      />
      <ImageText
        contentPositionMobile="bottom"
        title={referralFlowStep2Headline}
        text={referralFlowStep2Text}
        imageMobile={contentfulImage(
          'https://images.ctfassets.net/l6vo76cztcax/ak9OBrhYMutj5xhmJqfb9/a694fbce5e431a22d2461a471db9dbf7/referral-flow-2.png',
          850,
          365
        )}
        imageDesktop={contentfulImage(
          'https://images.ctfassets.net/l6vo76cztcax/ak9OBrhYMutj5xhmJqfb9/a694fbce5e431a22d2461a471db9dbf7/referral-flow-2.png',
          850,
          365
        )}
      />
      <ImageText
        contentPositionMobile="bottom"
        title={referralFlowStep3Headline}
        text={referralFlowStep3Text}
        imageMobile={contentfulImage(
          'https://images.ctfassets.net/l6vo76cztcax/4jp0BYNn5oV9RyXNqYPywH/21c739e21ba64862f45461e808e83710/referral-flow-3.png',
          850,
          365
        )}
        imageDesktop={contentfulImage(
          'https://images.ctfassets.net/l6vo76cztcax/4jp0BYNn5oV9RyXNqYPywH/21c739e21ba64862f45461e808e83710/referral-flow-3.png',
          850,
          365
        )}
      />
      <ImageText
        contentPositionMobile="bottom"
        title={referralFlowStep4Headline}
        text={referralFlowStep4Text}
        imageMobile={contentfulImage(
          'https://images.ctfassets.net/l6vo76cztcax/7AeSyOYCQ4Ex3079U9o0z5/5fe5cde181cfc003775d0586d262d558/referral-flow-4.png',
          850,
          365
        )}
        imageDesktop={contentfulImage(
          'https://images.ctfassets.net/l6vo76cztcax/7AeSyOYCQ4Ex3079U9o0z5/5fe5cde181cfc003775d0586d262d558/referral-flow-4.png',
          850,
          365
        )}
      />
    </ImageTextWrapper>

    <ContentModels.FrequentlyAskedQuestions {...frequentlyAskedQuestions} />

    {productItemTeaserCarousel?.skuList && (
      <Section backgroundColor={productItemTeaserCarousel.backgroundColor}>
        <ContentModels.ProductItemTeaserCarousel
          {...productItemTeaserCarousel}
        />
      </Section>
    )}

    {seoText?.content && (
      <Section>
        <SeoText document={seoText.content} />
      </Section>
    )}
  </PageLayout>
)
