import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Paragraph } from '@boxine/tonies-ui'

import { useEcomLocale } from '../../../providers/locale'
import {
  bundleBase,
  isSupportedBundlerProduct,
} from '../../../config/bundles/getCustomBundlerConfig'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'

export const PredefinedBundleToBundlerLinkContainer: FunctionComponent<{
  product: NormalizedProductExtended
  variant: 'link' | 'textWithLink'
}> = ({ product, variant }) => {
  const { t } = useTranslation()
  const { pushGtmV4Event } = useGtmV4()
  const lcCC = useEcomLocale()

  const products = product.bundledProductsExtended

  if (!products) {
    return null
  }

  /**
   * For now we only want to create this link if there is
   * 1. only Tonieboxes and Tonies
   * 2. exactly 1 Toniebox
   * in the bundled products.
   *
   * TODO:
   * Maybe get this from the custom bundle config later?
   */
  if (
    products.some(
      p => p.shopCategory !== 'tonieboxes' && p.shopCategory !== 'tonies'
    ) ||
    products.filter(p => p.shopCategory === 'tonieboxes').length > 1
  ) {
    return null
  }

  const predefinedToniebox = products.find(
    p => p.shopCategory === 'tonieboxes' && p.availability.state === 'orderable'
  )

  // Make sure that only orderable and supported Tonies are passed to the custom bundler
  const predefinedTonies = products
    .filter(
      p => p.shopCategory === 'tonies' && p.availability.state === 'orderable'
    )
    .filter(p => isSupportedBundlerProduct(p, lcCC))

  // Get the best matching custom bundler template
  const customBundleConfig = bundleBase[lcCC]
  const customBundleTemplateId = customBundleConfig.find(
    c =>
      c.tonieboxes.quantity === 1 &&
      c.tonies.quantity >= predefinedTonies.length
  )?.id

  if (!customBundleTemplateId) {
    return null
  }

  // Build the custom bundler URL
  let ctaLink = t('bundler:url')

  const urlParts: string[] = []

  if (predefinedToniebox || predefinedTonies.length) {
    urlParts.push(`cbTemplate=${customBundleTemplateId}`)
  }

  if (predefinedToniebox) {
    urlParts.push(`cbTonieboxes=${predefinedToniebox.sku}`)
  }

  predefinedTonies.forEach(p => {
    urlParts.push(`cbTonies=${p.sku}`)
  })

  if (urlParts.length) {
    ctaLink += '/?' + urlParts.join('&')
  }

  // Finaly return
  const ctaLabel = t(
    'productDetailPage:predefinedBundle.createCustomBundle.linkText'
  )

  const link = (
    <Button
      variant="secondary"
      as="a"
      href={ctaLink}
      onClick={() =>
        pushGtmV4Event({
          eventType: 'selectContent',
          ctaLabel,
          ctaLink,
          type: 'text-link',
        })
      }
    >
      {ctaLabel}
    </Button>
  )

  if (variant === 'link') {
    return link
  }

  return (
    <Paragraph size={2} weight={700}>
      {t('productDetailPage:predefinedBundle.createCustomBundle.text')} {link}
    </Paragraph>
  )
}

export const PredefinedBundleToBundlerLink = withGTMV4Context(
  PredefinedBundleToBundlerLinkContainer,
  'PredefinedBundleToBundlerLink'
)
