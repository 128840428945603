import React from 'react'
import styled from 'styled-components'
import { Button, Paragraph, Spacing } from '@boxine/tonies-ui'
import { Trans } from 'next-i18next'
import { useGeoInformation } from '../../../hooks/useGeoInformation'
import { FlashMessage } from '@/tonies-ui/index'
import { useSupportArticle } from '../../../hooks/useSupportArticle'

const NoMarginText = styled(Paragraph)`
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
`

export const DevWarning = () => {
  return (
    <Spacing mx="spacing-s">
      <FlashMessage type="warning" isPersistent={true}>
        <NoMarginText>
          <Trans
            i18nKey="warning:devSystemWarning"
            components={{
              em: <em />,
              Link: (
                <Button as="a" variant="secondary" href="https://tonies.com">
                  tonies.com
                </Button>
              ),
            }}
          />
        </NoMarginText>
      </FlashMessage>
    </Spacing>
  )
}

export const TunesGeoIpWarning = () => {
  const {
    isValid,
    country: { label },
  } = useGeoInformation()
  const articleLink = useSupportArticle('10688594363154')

  return isValid === false ? (
    <FlashMessage type="warning" isPersistent={true}>
      <NoMarginText>
        <Spacing mb="spacing-s" asHtmlTag="span">
          <Trans
            i18nKey="products:tunes:geoIpFlashMessage"
            components={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              currentCountry: (label || 'undefined') as any,
              SupportResource: (
                <Button
                  target="_blank"
                  as="a"
                  variant="secondary"
                  href={articleLink}
                />
              ),
            }}
          />
        </Spacing>
      </NoMarginText>
    </FlashMessage>
  ) : null
}
