import { TabNavigationEntry } from '../lib/contentful/datamodel/schemas'
import { cloudinaryUrl } from '@/tonies-ui/atoms/Image/components/cloudinary'
import { theme } from '../tonies-ui/themes/theme'
import { useRouter } from 'next/router'
import { contentfulImage } from '../utils/contentful-helpers'
import { useIsViewportMobile } from '@boxine/tonies-ui'
import { useMemo } from 'react'
import { useLocaleAltNotation } from '../providers/locale/altNotation'
import { slugify } from '../utils/slugify'
import { useDisplayResolution } from './useDisplayResulotion'

export const useActiveTabNavigationItemByUrl = (
  entries: TabNavigationEntry[]
) => {
  const {
    query: { slug = [] },
  } = useRouter()
  const isMobile = useIsViewportMobile()
  const { isHighRes } = useDisplayResolution()
  const lc = useLocaleAltNotation('lc')
  const currentActiveTabId = useMemo(() => slug[slug.length - 1], [slug])
  const currentNavigationItem = useMemo(
    () =>
      entries.find(({ url }) =>
        url.split('/').includes(currentActiveTabId)
      ) ?? {
        name: '',
        url: '',
        backgroundImageMobile: contentfulImage(''),
        backgroundImageDesktop: contentfulImage(''),
      },
    [entries, currentActiveTabId]
  )

  const active = useMemo(
    () =>
      slugify(currentNavigationItem.name, lc)
        .toLowerCase()
        .split(' ')
        .join('-'),
    [currentNavigationItem.name, lc]
  )

  const backgroundImageUrl = useMemo(() => {
    if (isMobile && currentNavigationItem.backgroundImageMobile?.file.url) {
      return cloudinaryUrl({
        src: currentNavigationItem.backgroundImageMobile.file.url,
        width: isHighRes ? theme.screenLaptopL : theme.screenLaptop,
      })
    }

    if (!isMobile && currentNavigationItem.backgroundImageDesktop?.file.url) {
      return cloudinaryUrl({
        src: currentNavigationItem.backgroundImageDesktop.file.url,
        width: isHighRes ? theme.screen4k : theme.screenLaptopL,
      })
    }

    return undefined
  }, [currentNavigationItem, isMobile, isHighRes])

  return { active, backgroundImageUrl }
}
