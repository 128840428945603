import React, { FunctionComponent, useState, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { ImageSelector } from '../../../components/molecules/ImageSelector'
import { CarouselChangeHandlerParams } from '../../../components/molecules/Carousel/types'

import { ImageCarousel } from './ImageCarousel'

const previewImageWidth = 168

export type Props = {
  product: NormalizedProductExtended
}

const Container = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: ${previewImageWidth}px auto;
`

export const ImageGallery: FunctionComponent<Props> = ({ product }) => {
  const numImages = product.images.length
  const numVisibleImages = Math.min(numImages, 4)
  const [selectedImage, setSelectedImage] = useState(0)
  const [topImage, setTopImage] = useState(0)

  const setCurrentSlide = useRef<(n: number) => void>()

  const clamp = useCallback(
    (n: number) => Math.max(0, Math.min(n, numImages - numVisibleImages)),
    [numImages, numVisibleImages]
  )

  const handleArrowDown = useCallback(() => {
    setTopImage(s => clamp(s + numImages))
  }, [clamp, numImages])

  const handleArrowUp = useCallback(() => {
    setTopImage(s => clamp(s - numImages))
  }, [clamp, numImages])

  const handleCarouselChange = useCallback((p: CarouselChangeHandlerParams) => {
    setCurrentSlide.current = p.setCurrentSlide
    setSelectedImage(p.currentSlide)
  }, [])

  const handleSelectionChange = useCallback((n: number) => {
    setSelectedImage(n)

    if (setCurrentSlide.current) {
      setCurrentSlide.current(n)
    }
  }, [])

  return (
    <Container>
      <ImageSelector
        images={product.images}
        selectedIndex={selectedImage}
        topIndex={topImage}
        onArrowDown={handleArrowDown}
        onArrowUp={handleArrowUp}
        onImageHover={handleSelectionChange}
        numVisibleImages={numVisibleImages}
      />
      <ImageCarousel onChange={handleCarouselChange} product={product} />
    </Container>
  )
}
