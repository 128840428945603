import {
  useIsViewportDesktop,
  Headline1,
  Headline2,
  Headline5,
  Spacing,
} from '@boxine/tonies-ui'
import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Section } from '../../atoms/Section'
import { RichText } from '../../molecules/RichText'
import { Testimonial } from '../../molecules/Testimonial'
import { FullWidthMedia } from '../FullWidthMedia'
import { ImageText } from '../ImageText'
import { ImageTextCarousel } from '../ImageTextCarousel'
import { SectionProductCopy } from '../SectionProductCopy'
import { VideoText } from '../VideoText'
import { VideoTextCarousel } from '../VideoTextCarousel'
import {
  isRichTextBlockSchema,
  ModularContent,
} from '../../../lib/contentful/datamodel/schemas'
import { Aggregated } from '../../../lib/transformation/aggregatePage/aggregate'
import { AggregatedProductList } from '../../../lib/transformation/aggregateProductList'
import { useLocale } from '../../../providers/locale'
import { getProductsByCategory } from '../../../lib/commercetools/requests/products/getProductsByCategory'
import { normalizeProduct } from '../../../lib/commercetools/normalizers/normalizeProduct'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { ProductSelector } from '../ProductSelector'
import { StageVideo } from '../StageVideo'
import { DeliveryInfoTeaserCollection } from '../DeliveryInfoTeaserCollection'
import { useAggregatedShopLocale } from '../../../providers/aggregatedShopLocale'
import { ContentModels } from '../../../lib/contentful/datamodel/contentModels'
import { isEcomLocale } from '../../../config/shopAPI'
import { mainFirstChildPaddingTop } from '@/tonies-ui/helpers/css-helper'

const TextHeroWrapper = styled.div`
  main > &:first-child + div {
    ${mainFirstChildPaddingTop}
  }
`

export type ModularContentSectionProps = {
  contentSection: Aggregated<ModularContent>[]
}

export const ModularContentSection: FunctionComponent<
  ModularContentSectionProps
> = ({ contentSection: content }) => {
  const lcCC = useLocale()
  const aggregatedShopLocale = useAggregatedShopLocale()
  const isDesktop = useIsViewportDesktop()
  const { deliveryInfoTeaserList } = aggregatedShopLocale.content

  const [tonieboxes, setTonieboxes] = useState<
    AggregatedProductList | undefined
  >()

  // Fetch Tonieboxes when there's at least 1 MarketEntrySelector
  // FIXME: Do this on build if possible
  useEffect(() => {
    if (
      content.filter(
        element =>
          !isRichTextBlockSchema(element) &&
          element.contentTypeId === 'marketEntrySelector'
      ).length &&
      isEcomLocale(lcCC)
    ) {
      getProductsByCategory(lcCC, ['tonieboxes']).then(response => {
        if (response.result === 'successful') {
          const products = response.data.results

          // Generate Toniebox product list
          const productList: AggregatedProductList = {
            contentTypeId: 'productList',
            referenceTitle: '',
            normalizedProducts: products.map(p => normalizeProduct(p, lcCC)),
            categoryKey: 'tonieboxes',
            randomProductId:
              // Preselect a random Toniebox
              products[Math.floor(Math.random() * products.length)].id,
            headline: '',
          }

          setTonieboxes(productList)
        }
      })
    }
  }, [lcCC, content])

  return (
    <div data-testid="modularContentSection">
      {/* eslint-disable-next-line sonarjs/cognitive-complexity */}
      {content.map((element, index) => {
        if (isRichTextBlockSchema(element)) {
          return (
            <Section data-testid="richTextBlock" key={`${index}_richTextBlock`}>
              <RichText document={element.richText} />
            </Section>
          )
        } else if (element.contentTypeId === 'imageText') {
          return (
            <ImageText
              {...element}
              dataTestId={element.contentTypeId}
              key={`${index}_${element.contentTypeId}`}
            />
          )
        } else if (element.contentTypeId === 'community') {
          return (
            <Section
              data-testid={element.contentTypeId}
              key={`${index}_${element.contentTypeId}`}
              backgroundColor={element.backgroundColor}
              removePaddingX={element.variant === 'slider'}
              id={element.id}
            >
              <ContentModels.Community {...element} />
            </Section>
          )
        } else if (element.contentTypeId === 'videoText') {
          return (
            <VideoText
              {...element}
              dataTestId={element.contentTypeId}
              key={`${index}_${element.contentTypeId}`}
              videoDesktop={element.videoDesktop.file.url}
              videoMobile={element.videoMobile.file.url}
            />
          )
        } else if (element.contentTypeId === 'stageVideo') {
          return (
            <Section
              key={`${index}_${element.contentTypeId}`}
              aspectRatio={{
                ratio: isDesktop ? 56.25 : undefined,
                alignContent: isDesktop ? 'center' : undefined,
              }}
              id={element.id}
            >
              <StageVideo
                title={
                  <Headline2 asHTMLTag="h3">
                    <Accent text={element.title} />
                  </Headline2>
                }
                text={element.text}
                ctaText={element.ctaText}
                ctaUrl={element.ctaUrl}
                video={{
                  mobile: element.videoMobile,
                  desktop: element.videoDesktop,
                }}
              />
            </Section>
          )
        } else if (element.contentTypeId === 'fullWidthImage') {
          return (
            <FullWidthMedia
              {...element}
              key={`${index}_${element.contentTypeId}`}
            />
          )
        } else if (element.contentTypeId === 'gallery') {
          return (
            <Section
              data-testid={element.contentTypeId}
              key={`${index}_${element.contentTypeId}`}
              id={element.id}
            >
              <ContentModels.Gallery {...element} />
            </Section>
          )
        } else if (element.contentTypeId === 'textHero') {
          return (
            // Wrapper needed to add padding to TextHero if its the first element of the page
            <TextHeroWrapper
              data-testid={element.contentTypeId}
              key={`${index}_${element.contentTypeId}`}
              id={element.id}
            >
              <ContentModels.TextHero
                {...element}
                hasCurve={element.hasCurve !== false}
              />
            </TextHeroWrapper>
          )
        } else if (element.contentTypeId === 'editorialTeaserList') {
          return (
            <Section
              data-testid={element.contentTypeId}
              key={`${index}_${element.contentTypeId}`}
              backgroundColor={element.backgroundColor}
              removePaddingX={element.variant === 'slider'}
              id={element.id}
            >
              <ContentModels.EditorialTeaserList {...element} />
            </Section>
          )
        } else if (element.contentTypeId === 'productItemTeaserCarousel') {
          return (
            <Section
              data-testid={element.contentTypeId}
              key={`${index}_${element.contentTypeId}`}
              backgroundColor={element.backgroundColor}
              id={element.id}
            >
              <ContentModels.ProductItemTeaserCarousel {...element} />
            </Section>
          )
        } else if (element.contentTypeId === 'testimonial') {
          return (
            <Section
              data-testid={element.contentTypeId}
              key={`${index}_${element.contentTypeId}`}
              backgroundColor={element.backgroundColor}
            >
              <Testimonial {...element} />
            </Section>
          )
        } else if (element.contentTypeId === 'videoTextCarousel') {
          return (
            <VideoTextCarousel
              {...element}
              dataTestId={element.contentTypeId}
              key={`${index}_${element.contentTypeId}`}
              items={element.items.map(item => ({
                ...item,
                videoDesktop: item.videoDesktop.file.url,
                videoMobile: item.videoMobile.file.url,
              }))}
            />
          )
        } else if (element.contentTypeId === 'imageTextCarousel') {
          return (
            <ImageTextCarousel
              {...element}
              dataTestId={element.contentTypeId}
              key={`${index}_${element.contentTypeId}`}
              title={element.title}
              headlineAsHTMLTag={element.headlineAsHTMLTag}
              items={element.items.map(item => ({
                ...item,
                contentPositionDesktop: 'left',
              }))}
            />
          )
        } else if (element.contentTypeId === 'sectionProductCopy') {
          return (
            element.normalizedProduct && (
              <SectionProductCopy
                {...element}
                dataTestId={element.contentTypeId}
                key={`${index}_${element.contentTypeId}`}
              />
            )
          )
        } else if (element.contentTypeId === 'frequentlyAskedQuestions') {
          return (
            <ContentModels.FrequentlyAskedQuestions
              {...element}
              data-testid={element.contentTypeId}
              key={`${index}_${element.contentTypeId}`}
            />
          )
        } else if (element.contentTypeId === 'audioPlayer') {
          return (
            <ContentModels.AudioPlayerSection
              {...element}
              data-testid={element.contentTypeId}
              key={`${index}_${element.contentTypeId}`}
            />
          )
        } else if (element.contentTypeId === 'marketEntrySelector') {
          return (
            tonieboxes && (
              <ProductSelector
                referenceTitle="[coded] marketEntrySelector"
                dataTestId={element.contentTypeId}
                key={`${index}_${element.contentTypeId}`}
                uniqueInputName={`${index}_${element.contentTypeId}`}
                hasLargerProductImage
                hasSkyIllustration
                isImageLink={false}
                headlinePosition="bottom"
                tabletAlignContent="left"
                productList={tonieboxes}
                headline={
                  <>
                    <Headline5 align="left" weight={700} asHTMLTag="h2">
                      {element.subTitle}
                    </Headline5>
                    <Headline1 align="left" asHTMLTag="h3">
                      <Accent text={element.title} />
                    </Headline1>
                    {element.text && (
                      <Spacing
                        media={{ m: { mb: 'spacing-m', mt: 'spacing-s' } }}
                      >
                        <RichText
                          align="left"
                          size={2}
                          document={element.text}
                        />
                      </Spacing>
                    )}
                  </>
                }
                id={element.id}
              />
            )
          )
        } else if (element.contentTypeId === 'mediaCopySection') {
          return (
            <ContentModels.MediaCopySection
              data-testid={element.contentTypeId}
              key={`${index}_${element.contentTypeId}`}
              {...element}
              componentProps={{ hasMarginTop: false }}
            />
          )
        } else if (element.contentTypeId === 'mediaCopyCollection') {
          return (
            <Section
              data-testid={element.contentTypeId}
              key={`${index}_${element.contentTypeId}`}
              backgroundColor={element.backgroundColor}
              removePaddingX={element.variant === 'slider'}
              id={element.id}
            >
              <ContentModels.MediaCopyCollection {...element} />
            </Section>
          )
        } else if (element.contentTypeId === 'productItemTeaserList') {
          return (
            <Section key={`${index}_${element.contentTypeId}`} id={element.id}>
              <ContentModels.ProductItemTeaserList {...element} />
            </Section>
          )
        } else if (element.contentTypeId === 'deliveryInfoTeaserList') {
          return (
            <Section key={`${index}_${element.contentTypeId}`}>
              <DeliveryInfoTeaserCollection items={deliveryInfoTeaserList} />
            </Section>
          )
        } else if (element.contentTypeId === 'carousel') {
          return (
            <ContentModels.Carousel
              key={`${index}_${element.contentTypeId}`}
              {...element}
            />
          )
        } else if (element.contentTypeId === 'brazeForm') {
          return (
            <Section
              key={`${index}_${element.contentTypeId}`}
              backgroundColor={element.backgroundColor}
              id={element.id}
            >
              <ContentModels.BrazeForm
                key={`${index}_${element.contentTypeId}`}
                {...element}
              />
            </Section>
          )
        } else if (element.contentTypeId === 'giftfinder') {
          return (
            <Section
              key={`${index}_${element.contentTypeId}`}
              backgroundColor={element.backgroundColor}
              id={element.id}
            >
              <ContentModels.Giftfinder {...element} />
            </Section>
          )
        } else if (element.contentTypeId === 'productSelector') {
          return (
            <ContentModels.ProductSelector
              key={`${index}_${element.contentTypeId}`}
              {...element}
            />
          )
        } else if (element.contentTypeId === 'products') {
          return (
            <Section
              key={`${index}_${element.contentTypeId}`}
              backgroundColor={element.backgroundColor}
              id={element.id}
              noGaps
            >
              <ContentModels.Products {...element} />
            </Section>
          )
        } else {
          throw new Error(
            `ModularContentSection: no matching component for [${index}]`,
            // @ts-expect-error should not be reached with fully type-compliant content
            element
          )
        }
      })}
    </div>
  )
}
