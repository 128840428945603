import React, { FunctionComponent } from 'react'
import styled, { useTheme } from 'styled-components'
import { Headline3, Paragraph, Button } from '@boxine/tonies-ui'
import { ColorContextProvider } from '@/tonies-ui/index'
import { Icon, IconProps } from './components/Icon'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { SupportedHTMLTags } from '@boxine/tonies-ui/dest/types/src/atoms/Typography/types'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'

export interface InfoTeaserProps {
  iconType?: IconProps['iconType']
  iconBackgroundColor?: string
  title: string
  headlineAsHTMLTag?: SupportedHTMLTags
  text: string
  ctaUrl: string
  ctaText: string
}

const InfoTeaserContainer: FunctionComponent<InfoTeaserProps> = ({
  iconType = 'info',
  iconBackgroundColor,
  title,
  headlineAsHTMLTag,
  text,
  ctaUrl,
  ctaText,
}) => {
  const { pushGtmV4Event } = useGtmV4()
  const { colors } = useTheme()

  return (
    <ColorContextProvider backgroundColor="#fff">
      <Container>
        <StyledShadow>
          <StyledInner>
            <StyledIcon>
              <Icon
                iconType={iconType}
                iconBackgroundColor={
                  iconBackgroundColor || colors['yellow-100']
                }
              />
            </StyledIcon>
            <StyledHeadline3 asHTMLTag={headlineAsHTMLTag} align="center">
              <Accent text={title} />
            </StyledHeadline3>
            <StyledParagraph align="center" size={2}>
              {text}
            </StyledParagraph>
            <StyledButtonWrapper>
              <Button
                as="a"
                href={ctaUrl}
                onClick={() =>
                  pushGtmV4Event({
                    eventType: 'selectContent',
                    ctaLabel: ctaText || '',
                    ctaLink: ctaUrl || '',
                    type: 'secondary',
                  })
                }
              >
                {ctaText}
              </Button>
            </StyledButtonWrapper>
          </StyledInner>
        </StyledShadow>
      </Container>
    </ColorContextProvider>
  )
}

const Container = styled.div`
  position: relative;
  max-width: 23.5rem;
`

const shadow = '0.25rem'
const StyledShadow = styled.span`
  height: 100%;
  display: block;
  padding: 0 ${shadow} ${shadow} 0;

  &::after {
    content: '';
    position: absolute;
    top: ${shadow};
    left: ${shadow};
    width: calc(100% - ${shadow});
    height: calc(100% - ${shadow});
    border-radius: 1.5rem;
    background: ${({ theme: { colors } }) => colors['anthracite-30']};
    z-index: -1;
  }
`

const StyledInner = styled.div`
  position: relative;
  display: grid;
  padding: 1rem 1rem 1.25rem;
  background-color: ${({ theme: { colors } }) => colors.lightergrey};
  border-radius: 1.5rem;
  text-align: center;
  min-height: 16.5rem;
`

const StyledIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`

// FIXME: avoid overwriting internal component styles
const StyledHeadline3 = styled(Headline3)`
  /* have space for the icon in the left corner */
  margin: 0 3.75rem;
  em {
    /* Accent does not handle this correctly */
    color: ${({ theme: { colors } }) => colors.primary};
  }
`

// FIXME: avoid overwriting internal component styles
const StyledParagraph = styled(Paragraph)`
  align-self: center;
  margin-bottom: 0.5em;
`

const StyledButtonWrapper = styled.div`
  align-self: end;
  justify-content: center;
`

export const InfoTeaser = withGTMV4Context(InfoTeaserContainer, 'InfoTeaser')
