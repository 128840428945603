import React, { FunctionComponent } from 'react'
import {
  Headline2,
  Headline3,
  useIsViewportMobile,
  media,
} from '@boxine/tonies-ui'
import styled from 'styled-components'

const StyledHeadline2 = styled(Headline2)`
  font-size: 1rem !important;
  font-weight: 300 !important;

  ${media.tablet`
    font-size: 1.25rem !important;
  `}
`

export type Props = {
  product: NormalizedProduct
}

export const ProductName: FunctionComponent<Props> = ({ product }) => {
  const isMobile = useIsViewportMobile()

  return (
    <div>
      {isMobile ? (
        <Headline2 asHTMLTag="h1" data-testid="headline">
          {product.name}
        </Headline2>
      ) : (
        <Headline3 asHTMLTag="h1" data-testid="headline">
          {product.name}
        </Headline3>
      )}
      <StyledHeadline2 data-testid="subheadline">
        {product.subName}
      </StyledHeadline2>
    </div>
  )
}
