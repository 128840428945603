import React from 'react'
import styled from 'styled-components'
import Heart from '../assets/heart.svg'
import Tonies from '../assets/tonies.svg'
import Leaf from '../assets/leaf.svg'
import { media } from '@boxine/tonies-ui'

const StyledLeaf = styled(Leaf)`
  position: absolute;
  height: 3rem;
  width: 3.5rem;
  top: 3rem;
  left: 50%;
  transform: translateX(-10rem);

  ${media.tablet`
    transform: translateX(-16rem);
    height: 5rem;
    width: 5.5rem;
    `}

  ${media.laptop`
    transform: unset;
    left: 0;
  `}

  ${media.laptopL`
    left: -2rem; 
  `}
`

const StyledHeart1 = styled(Heart)`
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 50%;
  transform: translateX(5.5rem);
  top: 4rem;

  ${media.tablet`
    transform: translateX(9.5rem);
    top: 7rem;
    height: 1.5rem;
    width: 1.5rem;
  `}

  ${media.laptop`
    transform: translateX(14.5rem);
    top: 0rem;
  `}
`

const StyledHeart2 = styled(Heart)`
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 50%;
  transform: translateX(7rem);
  top: 3rem;

  ${media.tablet`
    height: 1.5rem;
    width: 1.5rem;
    transform: translateX(12.5rem);
    top: 3rem;
  `}

  ${media.laptop`
    transform: translateX(12.5rem);
    top: 3rem;
  `}
`

const StyledTonies = styled(Tonies)`
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  height: 8rem;
  width: 7.5rem;

  ${media.tablet`
    height: 13.5rem;
    width: 13.5rem;
  `}

  ${media.laptop`
    transform: translateX(-33%);
  `}
`

const IllustrationWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 8rem;

  ${media.tablet`
    height: 17rem;
  `}

  ${media.laptop`
    width: 27rem;
  `}
`

export const Illustration = () => {
  return (
    <IllustrationWrapper>
      <StyledLeaf />
      <StyledTonies />
      <StyledHeart1 />
      <StyledHeart2 />
    </IllustrationWrapper>
  )
}
