import { Headline1, Spacing, useIsViewportMobile } from '@boxine/tonies-ui'
import React, { FunctionComponent } from 'react'
import { Section } from '../../components/atoms/Section'
import { SeoText } from '../../components/molecules/SeoText'
import { DeliveryInfoTeaserCollection } from '../../components/organisms/DeliveryInfoTeaserCollection'
import { ModularContentLayout as Props } from '../../lib/contentful/datamodel/layouts'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { useAggregatedShopLocale } from '../../providers/aggregatedShopLocale'
import { PageLayout } from '../PageLayout'
import { ModularContentSection } from '../../components/organisms/ModularContentSection'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { ProductSelector } from '../../components/organisms/ProductSelector'
import { AggregatedProductList } from '../../lib/transformation/aggregateProductList'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'
import { Carousel } from '../../components/organisms/Carousel'
import { BrazeForm } from '../../components/organisms/BrazeForm'
import { useTheme } from 'styled-components'
import { useTranslation } from 'next-i18next'
import { GeoIpModal } from '../../components/organisms/GeoIpModal'

export const ModularContentLayout: FunctionComponent<Aggregated<Props>> = ({
  pageMetaData,
  stage,
  modularContent,
  hasDeliveryInfoTeaser,
  hasNewsletterSignup,
  testimonialCarousel,
  closingEditorialTeaserList,
  seoText,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsViewportMobile()
  const { colors } = useTheme()
  const aggregatedShopLocale = useAggregatedShopLocale()
  const lowerCaseLcCC = aggregatedShopLocale.lcCC.toLowerCase()
  const isMainpage =
    pageMetaData.slug === `/${lowerCaseLcCC}/mainpage` ||
    pageMetaData.slug === `/${lowerCaseLcCC}`
  const stageProductList: AggregatedProductList | undefined =
    stage?.contentTypeId === 'productList'
      ? {
          contentTypeId: 'productList',
          referenceTitle: 'stageProductList',
          normalizedProducts: stage.normalizedProducts,
          categoryKey: stage.categoryKey,
          randomProductId: stage.randomProductId,
          headline: '',
        }
      : undefined

  const stageTonieboxBundleList: AggregatedProductList | undefined =
    stage?.contentTypeId === 'tonieboxBundlesList'
      ? {
          contentTypeId: 'productList',
          referenceTitle: 'stageTonieboxBundle',
          normalizedProducts: stage.normalizedProducts,
          categoryKey: 'tonieboxes',
          randomProductId: stage.randomProductId,
          headline: '',
        }
      : undefined

  return (
    <PageLayout meta={pageMetaData}>
      {stage && stage.contentTypeId === 'mediaCopySection' && (
        <ContentModels.MediaCopySection
          {...stage}
          componentProps={{ hasMarginTop: true }}
        />
      )}

      {stage && stage.contentTypeId === 'carousel' && (
        <ContentModels.Carousel
          {...stage}
          componentProps={{ hasMarginTop: true }}
        />
      )}

      {stage && stage.contentTypeId === 'textHero' && (
        <ContentModels.TextHero {...stage} />
      )}

      {stage && stage.contentTypeId === 'productList' && stageProductList && (
        <ProductSelector
          referenceTitle={stageProductList.referenceTitle}
          uniqueInputName="stageProductSelector"
          productList={stageProductList}
          headline={
            <Spacing pb={isMobile ? 'spacing-xs' : 'spacing-xl'}>
              <Headline1 align="center">
                <Accent text={stage.headline} />
              </Headline1>
            </Spacing>
          }
          hasAddToCart
          hasDetailLink
          hasProductDetails
          isImageRight
          curveColor={
            'backgroundColor' in modularContent[0]
              ? modularContent[0].backgroundColor
              : undefined
          }
        />
      )}

      {stage &&
        stage.contentTypeId === 'tonieboxBundlesList' &&
        stageTonieboxBundleList && (
          <ProductSelector
            referenceTitle={stage.referenceTitle}
            uniqueInputName="stageTonieboxBundleSelector"
            productList={stageTonieboxBundleList}
            hasProductDetails
            isImageLink
            hasDetailLink
            detailLinkText={stage.ctaText}
            hasAddToCart
            headline={
              <Spacing pb={isMobile ? 'spacing-xs' : 'spacing-xl'}>
                <Headline1 align="center">
                  <Accent text={stage.headline || ''} />
                </Headline1>
              </Spacing>
            }
            key={stage.referenceTitle}
          />
        )}

      {stage && stage.contentTypeId === 'editorialTeaserList' && (
        <Section
          backgroundColor={stage.backgroundColor || colors['lightblue-20']}
          isStageSection
          removePaddingX={stage.variant === 'slider'}
        >
          <ContentModels.EditorialTeaserList {...stage} />
        </Section>
      )}

      {stage && stage.contentTypeId === 'brazeForm' && (
        <Section backgroundColor={stage.backgroundColor} isStageSection>
          <ContentModels.BrazeForm
            {...stage}
            componentProps={{
              formLocation: 'stage',
            }}
          />
        </Section>
      )}

      {stage && stage.contentTypeId === 'productSelector' && (
        <ContentModels.ProductSelector {...stage} />
      )}

      <ModularContentSection contentSection={modularContent} />

      {testimonialCarousel && (
        <Carousel isContentWidth hasSidePadding items={testimonialCarousel} />
      )}

      {hasNewsletterSignup && (
        <Section>
          <BrazeForm
            formLocation="component"
            headline={t('brazeForm:headline')}
            headlineAsHTMLTag="h3"
            referenceTitle="[coded] ModularContentLayout"
            successHeadline={t('brazeForm:successHeadline')}
            successRichtext={t('brazeForm:successText')}
            variant="Newsletter"
          />
        </Section>
      )}

      {hasDeliveryInfoTeaser && (
        <Section>
          <DeliveryInfoTeaserCollection
            items={aggregatedShopLocale.content.deliveryInfoTeaserList}
          />
        </Section>
      )}

      {closingEditorialTeaserList && (
        <Section
          backgroundColor={closingEditorialTeaserList.backgroundColor}
          removePaddingX={closingEditorialTeaserList.variant === 'slider'}
        >
          <ContentModels.EditorialTeaserList {...closingEditorialTeaserList} />
        </Section>
      )}

      {seoText?.content && (
        <Section>
          <SeoText document={seoText.content} />
        </Section>
      )}
      {isMainpage && <GeoIpModal />}
    </PageLayout>
  )
}
