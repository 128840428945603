import { ToniesMotions } from '@/tonies-ui/motions'
import styled from 'styled-components'

export type ImageContainerProps = {
  $imageWidthMobile?: string
  $imageWidthDesktop?: string
}

export const ImageContainer = styled(ToniesMotions.ZoomIt)<ImageContainerProps>`
  width: ${props => props.$imageWidthMobile || '100%'};
  margin: 0 auto;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoint.screenTablet}) {
    width: ${props => props.$imageWidthDesktop || '100%'};
  }
`
