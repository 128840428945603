import React, { ComponentProps } from 'react'
import { Accordion, Button, Spacing, Box } from '@boxine/tonies-ui'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { AccordionItem } from '@/tonies-ui/index'
import { useCustomBundler } from '../../../hooks/useCustomBundler'
import { Image } from '@/tonies-ui/atoms/Image'

const Wrapper = styled.div`
  display: grid;
  gap: 1rem;
  align-items: center;
  grid-template-columns: minmax(6rem, 10rem) minmax(60%, auto);
`

const CreateCustomBundleAccordionContainer = ({
  product,
  ...props
}: Partial<ComponentProps<typeof Box>> & { product?: NormalizedProduct }) => {
  const { t } = useTranslation()
  const { pushGtmV4Event } = useGtmV4()
  const { link } = useCustomBundler({
    products: product ? [product] : undefined,
  })
  const imgSc =
    'https://images.ctfassets.net/l6vo76cztcax/6BTR3W5mb3szJNlQ4VvPkD/8b9c1ce58836578b470bbdfa8b3b8e0f/Bundler_Landingpage_KV_2x.png'

  const ctaLabel = t('productDetailPage:createCustomBundle.linkText')

  return link ? (
    <Accordion py={undefined} px="spacing-s" {...props}>
      <AccordionItem title={t('productDetailPage:createCustomBundle.headline')}>
        <Wrapper>
          <Image alt="bundler visual" src={imgSc} />
          <Spacing>
            {t('productDetailPage:createCustomBundle.text')}
            <br />
            <br />
            <Button
              variant="secondary"
              as="a"
              href={link}
              onClick={() =>
                pushGtmV4Event({
                  eventType: 'selectContent',
                  ctaLabel,
                  ctaLink: link,
                  type: 'text-link',
                })
              }
            >
              {ctaLabel}
            </Button>
          </Spacing>
        </Wrapper>
      </AccordionItem>
    </Accordion>
  ) : null
}

export const CreateCustomBundleAccordion = withGTMV4Context(
  CreateCustomBundleAccordionContainer,
  'CreateCustomBundleAccordion'
)
