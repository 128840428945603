import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { Headline5, Spacing, ListItemSmall } from '@boxine/tonies-ui'

export type Props = {
  tracks: string[]
}

/**
 * TODO
 * Add prop for padding for ListItem(Small) in tonies-ui
 */
const ListWrapper = styled.div`
  margin-left: -1rem;
  margin-right: -1rem;
`

export const TrackList: FunctionComponent<Props> = ({ tracks }) => {
  const { t } = useTranslation()

  return (
    <>
      <Spacing pb="spacing-xxs">
        <Headline5 asHTMLTag="h3">
          {t('productDetailPage:additionalProductInfo:tracks')}
        </Headline5>
      </Spacing>
      <ListWrapper>
        {tracks.map((track, i) => {
          const trackNumber = `${i < 9 ? '0' : ''}${i + 1} - `
          return (
            <ListItemSmall
              key={trackNumber + track}
              {...{ title: trackNumber + track }}
            />
          )
        })}
      </ListWrapper>
    </>
  )
}
