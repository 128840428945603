import { useTranslation } from 'next-i18next'
import { stripTags } from '../../../utils/stripTags'

export const useMetaData = (product: NormalizedProductExtended) => {
  const { t, i18n } = useTranslation()

  /**
   * Meta title and description will be used in the following order:
   * 1. commercetools meta attribute if maintained
   * 2. translation if maintained enriched with attributes
   * 3. commercetools attribute
   */
  const tTitleKey = `metaDefaults:pdp.${product.normalizedCategories[0].key}.title`
  const tDescriptionKey = `metaDefaults:pdp.${product.normalizedCategories[0].key}.description`
  const tInterpolation = {
    ageMin: product.ageMin || '',
    category1: product.normalizedCategories[0].name,
    category2: product.normalizedCategories[1]?.name || '',
    category3: product.normalizedCategories[2]?.name || '',
    flag: product.flag?.label || '',
    genre: product.genre?.label || '',
    name: product.name,
    runTime: product.runTime || '',
    series: product.series?.label || '',
    theme: product.theme?.map(t => t.label).join(' | ') || '',
  }

  let title = product.metaTitle
  if (!title && i18n.exists(tTitleKey)) {
    title = t(tTitleKey, tInterpolation)
  }
  if (!title) {
    title = product.name
  }

  let description = product.metaDescription
  if (!description && i18n.exists(tDescriptionKey)) {
    description = t(tDescriptionKey, tInterpolation)
  }
  if (!description) {
    description = stripTags(product.description || '')
      .replace(/\n+/g, ' ')
      .substring(0, 300)
  }

  return {
    slug: product.path,
    pageTitle: title,
    metaTitle: title,
    metaDescription: description,
    openGraphImage: {
      title: title,
      file: {
        url: product.images[0].src,
        details: {
          image: {
            width: 0,
            height: 0,
          },
          size: 0,
        },
        fileName: '',
        contentType: '',
      },
    },
    product: product,
  }
}
