import React from 'react'
import { useTranslation } from 'next-i18next'
import styled, { css } from 'styled-components'

import {
  Headline5,
  Button,
  Paragraph,
  Spacing,
  useIsViewportMobile,
} from '@boxine/tonies-ui'

import { BxAddress } from '../../../../lib/commercetools/requests/bxTypes'
import { ReadOnlyAddress } from '../components/ReadOnlyAddress'

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto;
  align-items: flex-start;
`

const InnerWrapper = styled.div<{
  layout: 'horizontal' | 'vertical'
}>`
  ${({ layout }) =>
    layout === 'horizontal' &&
    css`
      display: grid;
      width: 100%;
      grid-gap: 1rem;
      width: 100%;
      grid-template-columns: 1fr 1fr;
    `}
`

export const ReadOnlyView = ({
  billingAddress,
  shippingAddress,
  isBillingAsShipping,
  handleEditMode,
}: {
  billingAddress: BxAddress
  shippingAddress?: BxAddress
  isBillingAsShipping: boolean
  handleEditMode: (() => void) | undefined
}) => {
  const { t } = useTranslation()
  const isMobile = useIsViewportMobile()

  return (
    <Spacing mb="spacing-s" ml="spacing-s" mr="spacing-s" mt="spacing-s">
      <Wrapper>
        <InnerWrapper layout={isMobile ? 'vertical' : 'horizontal'}>
          {/* Billing Address */}
          {billingAddress && (
            <div>
              <Spacing mb="spacing-s">
                <Headline5 asHTMLTag="h3">
                  {t('checkout:addresses:readOnlyBillingHeadline')}
                </Headline5>
              </Spacing>
              <Spacing mb="spacing-m">
                <ReadOnlyAddress address={billingAddress} type="billing" />
              </Spacing>
            </div>
          )}

          {/* Shipping Address */}
          {(shippingAddress ||
            (!shippingAddress && billingAddress && isBillingAsShipping)) && (
            <div>
              <Spacing mb={!isBillingAsShipping ? 'spacing-s' : undefined}>
                <Headline5 asHTMLTag="h3">
                  {t('checkout:addresses:readOnlyShippingHeadline')}
                </Headline5>
              </Spacing>
              {isBillingAsShipping && (
                <Spacing my="spacing-s">
                  <Paragraph size={2}>
                    {t('checkout:addresses:readOnlyBillingAsShipping')}
                  </Paragraph>
                </Spacing>
              )}

              {!isBillingAsShipping && shippingAddress && (
                <ReadOnlyAddress address={shippingAddress} type="shipping" />
              )}
            </div>
          )}
        </InnerWrapper>

        {/* Edit Button */}
        {handleEditMode && (
          <div>
            <Button variant="secondary" onClick={handleEditMode}>
              {t('checkout:addresses:edit')}
            </Button>
          </div>
        )}
      </Wrapper>
    </Spacing>
  )
}
