import {
  Button,
  Headline3,
  Paragraph,
  Spacing,
  useIsViewportMobile,
} from '@boxine/tonies-ui'
import React from 'react'
import { useTranslation } from 'next-i18next'
import { DragonFly } from './components/DragonFly'
import { BlueShape } from './components/BlueShape'
import { Wrapper } from './components/Wrapper'
import { ReferralCode } from '../../atoms/CodeField'

type ReferralCodeBoxProps = {
  loginOnClick: () => void
  shareOnClick: () => void
  headline: string
  referralCode?: string
  subHeadline: string
}

export const ReferralCodeBox = ({
  shareOnClick,
  loginOnClick,
  headline,
  referralCode,
  subHeadline,
}: ReferralCodeBoxProps) => {
  const { t } = useTranslation()
  const isMobile = useIsViewportMobile()

  return (
    <Wrapper size={referralCode && !isMobile ? 'normal' : 'small'}>
      <DragonFly />
      <Spacing
        mb={referralCode ? 'spacing-s' : 'spacing-xl'}
        mt={referralCode ? 'spacing-l' : undefined}
        px={referralCode ? undefined : 'spacing-m'}
      >
        <Headline3 weight={500} align="center">
          {headline}
        </Headline3>
      </Spacing>
      {referralCode && <ReferralCode />}
      <BlueShape size={referralCode && !isMobile ? 'normal' : 'small'} />

      {referralCode ? (
        <>
          <Spacing mb="spacing-m" mt={isMobile ? undefined : 'spacing-m'}>
            <Paragraph asHTMLTag="span">{subHeadline}</Paragraph>
          </Spacing>
          <Button icon="share" onClick={shareOnClick}>
            {t('referralSection:shareButtonLabel')}
          </Button>
        </>
      ) : (
        <Button onClick={loginOnClick}>
          {t('referralSection:logInButtonLabel')}
        </Button>
      )}
    </Wrapper>
  )
}
