import { Paragraph, Spacing } from '@boxine/tonies-ui'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import styled, { css } from 'styled-components'
import { Tag } from '@/tonies-ui/atoms/Tag'
import { TagList } from '../../../tonies-ui/molecules/TagList'
import { BlogTeaser } from '../../../components/molecules/GenericImageText/variants/BlogTeaser'
import { Aggregated } from '../../../lib/transformation/aggregatePage/aggregate'
import { useMeasurements } from '../../../providers/measurements'
import { useUrlQuery } from '../../../providers/urlQuery'
import { SimpleButton } from '@/tonies-ui/helpers/css-helper'
import { useCategories } from '../hooks/useCategories'
import { useFilteredPosts } from '../hooks/useFilteredPosts'
// import { useTags } from '../hooks/useTags'

const ScrollRef = styled.div<{ offset: number }>`
  ${({ offset }) => css`
    scroll-margin-top: ${offset + 24}px;
  `}
`

export const BlogFilter = ({ posts }: { posts: Aggregated<BlogTeaser>[] }) => {
  const { t } = useTranslation()
  const { mainNavigationHeight } = useMeasurements()
  const filterRef = useRef<HTMLDivElement>(null)
  const { setUrlParam } = useUrlQuery()
  const { filteredPosts, activeCategory, activeTag, isFilterActive } =
    useFilteredPosts(posts)
  // const allTags = useTags(filteredPosts)
  const allCategories = useCategories(filteredPosts)
  // const possibleCategories = allCategories.filter(c => c !== activeCategory)
  // const possibleTags = allTags.filter(t => t !== activeTag)
  // const hasPossibleFilterOptions =
  //   [...possibleTags, ...possibleCategories].length !== 0

  useEffect(() => {
    if (activeCategory || activeTag) {
      filterRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [activeCategory, activeTag])

  return (
    <>
      <ScrollRef ref={filterRef} offset={mainNavigationHeight}>
        <Spacing mb="spacing-l">
          {!isFilterActive && (
            <TagList hasBox>
              <Paragraph size={3} weight={500}>
                {t('blog:filters')}
              </Paragraph>
              {allCategories.map(c => (
                <SimpleButton
                  key={c}
                  onClick={() => setUrlParam({ category: c })}
                >
                  <Tag isActive={false}>{c}</Tag>
                </SimpleButton>
              ))}
              {/* {possibleTags.map(t => (
                  <SimpleButton key={t} onClick={() => setUrlParam({ tag: t })}>
                    <Tag>{t}</Tag>
                  </SimpleButton>
                ))} */}
            </TagList>
          )}
          {isFilterActive && (
            <TagList hasBox>
              <Paragraph size={3} weight={500}>
                {t('blog:activeFilters')}
              </Paragraph>
              {activeCategory && (
                <SimpleButton
                  onClick={() => setUrlParam({ category: undefined })}
                >
                  <Tag isActive={true}>{activeCategory}</Tag>
                </SimpleButton>
              )}
              {activeTag && (
                <SimpleButton onClick={() => setUrlParam({ tag: undefined })}>
                  <Tag isActive={true}>{activeTag}</Tag>
                </SimpleButton>
              )}
            </TagList>
          )}
        </Spacing>
      </ScrollRef>
    </>
  )
}
