import React, { useState } from 'react'
import styled from 'styled-components'
import { Paragraph, lineClampCss, Button, Spacing } from '@boxine/tonies-ui'
import { useTranslation } from 'next-i18next'
import { GenericImageText, GenericImageTextProps } from '..'
import { DeliveryInfoIconType } from '../../../atoms/DeliveryInfoIcon/types'
import { Modal } from '@/tonies-ui/molecules/Modal'

export type DeliveryInfoTeaserProps = Partial<
  Omit<GenericImageTextProps, 'media'>
> & {
  iconType: DeliveryInfoIconType
  title: string
  text?: string | null
}

// FIXME: avoid overwriting internal component styles
const StyledHeadline = styled(Paragraph)`
  ${lineClampCss(2)}
`

export const DeliveryInfoTeaser = ({
  iconType,
  title,
  text,
  orientation,
  ...rest
}: DeliveryInfoTeaserProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleToggle = () => setIsOpen(currentValue => !currentValue)
  const { t } = useTranslation()

  return (
    <GenericImageText
      media={{ variant: 'deliveryInfo', iconType }}
      orientation={orientation}
      {...rest}
    >
      <Spacing applyToChildren mb="spacing-xxs">
        <StyledHeadline size={2} weight={500}>
          {title}
        </StyledHeadline>
        <Button variant="secondary" onClick={handleToggle}>
          {t('deliveryInfoTeaser:more')}
        </Button>
      </Spacing>
      <Modal headline={title} isOpen={isOpen} onClose={handleToggle}>
        <Paragraph size={2}>{text}</Paragraph>
      </Modal>
    </GenericImageText>
  )
}
