import React from 'react'
import { TunesPdp, TunesPdpProps } from './variants/Tunes'
import { DefaultPdp, DefaultPdpProps } from './variants/Default'

export type ProductDetailLayoutProps = TunesPdpProps | DefaultPdpProps

function isTunesPDP(props: ProductDetailLayoutProps): props is TunesPdpProps {
  return (props as TunesPdpProps).product.shopCategory === 'tunes'
}

export const ProductDetailLayout = (props: ProductDetailLayoutProps) => {
  return isTunesPDP(props) ? <TunesPdp {...props} /> : <DefaultPdp {...props} />
}
