import React, { FunctionComponent } from 'react'
import { useTheme } from 'styled-components'
import { TonieboxBundlesLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { PageLayout } from '../PageLayout'
import { ProductSelectorCard } from '../../components/organisms/ProductSelector'
import { Accent } from '@/tonies-ui/atoms/Accent'
import {
  Headline1,
  Spacing,
  useIsViewportMobile,
  Grid,
  useIsViewportTablet,
  Headline2,
} from '@boxine/tonies-ui'
import { AggregatedProductList } from '../../lib/transformation/aggregateProductList'
import { normalizeProduct } from '../../lib/commercetools/normalizers/normalizeProduct'
import { useEcomLocale } from '../../providers/locale'
import { Section } from '../../components/atoms/Section'
import { SeoText } from '../../components/molecules/SeoText'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'
import { ModularContentSection } from '../../components/organisms/ModularContentSection'

export const TonieboxBundlesLayout: FunctionComponent<
  Aggregated<TonieboxBundlesLayoutSchema>
> = ({
  pageMetaData,
  editorialTeaserBundles,
  headline,
  attributeList,
  productItemTeaserCarousel,
  editorialTeaserList,
  seoText,
  modularContent,
}) => {
  const lcCC = useEcomLocale()
  const isMobile = useIsViewportMobile()
  const isTablet = useIsViewportTablet()
  const theme = useTheme()

  return (
    <PageLayout meta={pageMetaData}>
      {editorialTeaserBundles && (
        <Section
          backgroundColor={
            editorialTeaserBundles.backgroundColor ||
            theme.colors['lightblue-20']
          }
          isStageSection
          removePaddingX={editorialTeaserBundles.variant === 'slider'}
        >
          <ContentModels.EditorialTeaserList {...editorialTeaserBundles} />
        </Section>
      )}

      {modularContent && modularContent.length > 0 && (
        <ModularContentSection contentSection={modularContent} />
      )}

      {attributeList && attributeList.length > 0 && (
        <Section isStageSection={!editorialTeaserBundles}>
          {headline && (
            <Headline2 align="center">
              <Accent text={headline} />
            </Headline2>
          )}
          <Grid columns={isMobile ? 1 : isTablet ? 2 : 3} hasGapH hasGapV>
            {attributeList.map((item, i) => {
              const even = i % 2 === 0

              const productList: AggregatedProductList = {
                contentTypeId: 'productList',
                referenceTitle: '',
                randomProductId: item.randomProductId,
                normalizedProducts: item.productEntries.map(p =>
                  normalizeProduct(p, lcCC)
                ),
                categoryKey: 'tonieboxes',
                headline: item.headline || '',
              }

              return (
                <ProductSelectorCard
                  key={item.referenceTitle + i}
                  referenceTitle={item.referenceTitle}
                  uniqueInputName={'toniebox-bundles-' + i}
                  productList={productList}
                  hasProductDetails
                  isImageLink
                  hasDetailLink
                  detailLinkText={item.ctaText}
                  hasAddToCart
                  isImageRight={even}
                  backgroundColor={
                    even ? theme.colors.white : theme.colors.lightgrey
                  }
                  headline={
                    <Spacing pb={isMobile ? 'spacing-xs' : 'spacing-xl'}>
                      <Headline1 align="center" asHTMLTag="h3">
                        <Accent text={item.headline || ''} />
                      </Headline1>
                    </Spacing>
                  }
                  listEntries={item.bundleContent}
                />
              )
            })}
          </Grid>
        </Section>
      )}

      {productItemTeaserCarousel?.skuList && (
        <Section>
          <ContentModels.ProductItemTeaserCarousel
            {...productItemTeaserCarousel}
          />
        </Section>
      )}

      <Section
        backgroundColor={editorialTeaserList.backgroundColor}
        removePaddingX={editorialTeaserList.variant === 'slider'}
      >
        <ContentModels.EditorialTeaserList {...editorialTeaserList} />
      </Section>

      {seoText?.content && (
        <Section>
          <SeoText document={seoText.content} />
        </Section>
      )}
    </PageLayout>
  )
}
