import React, { FunctionComponent } from 'react'
import { Headline2, Button, Spacing } from '@boxine/tonies-ui'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { RichText } from '../../../molecules/RichText'
import { Document } from '@contentful/rich-text-types'
import { SupportedHTMLTags } from '@boxine/tonies-ui/dest/types/src/atoms/Typography/types'
import { useGtmV4 } from '../../../../providers/gtmV4'

export type TextProps = {
  align?: 'left' | 'center' | 'right'
  title?: string
  headlineAsHTMLTag?: SupportedHTMLTags
  text: Document
  ctaText?: string
  ctaUrl?: string
}

export const Text: FunctionComponent<TextProps> = ({
  align = 'left',
  title,
  headlineAsHTMLTag = 'h3',
  text,
  ctaText,
  ctaUrl,
}) => {
  const { pushGtmV4Event } = useGtmV4()

  return (
    <>
      {title && (
        <Headline2 align={align} asHTMLTag={headlineAsHTMLTag}>
          <Accent text={title} />
        </Headline2>
      )}
      <Spacing pb="spacing-m">
        <RichText align={align} size={2} document={text} />
      </Spacing>
      {ctaText && ctaUrl && (
        <Button
          as="a"
          href={ctaUrl}
          onClick={() =>
            pushGtmV4Event({
              eventType: 'selectContent',
              ctaLabel: ctaText,
              ctaLink: ctaUrl,
              type: 'primary',
            })
          }
        >
          {ctaText}
        </Button>
      )}
    </>
  )
}
