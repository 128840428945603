import React, { FunctionComponent } from 'react'
import { RichText } from '../../components/molecules/RichText'
import { Section } from '../../components/atoms/Section'
import { PageLayout } from '../PageLayout'
import { SimpleDocumentLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { Grid, GridArea } from '@boxine/tonies-ui'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { ContentModels } from '../../lib/contentful/datamodel/contentModels'

export const SimpleDocumentLayout: FunctionComponent<
  Aggregated<SimpleDocumentLayoutSchema>
> = ({ textHero, content, pageMetaData }) => (
  <PageLayout meta={pageMetaData}>
    <ContentModels.TextHero {...textHero} />
    {content && (
      <Section>
        <Grid>
          <GridArea colStart={2} colWidth={8}>
            <RichText document={content} />
          </GridArea>
        </Grid>
      </Section>
    )}
  </PageLayout>
)
