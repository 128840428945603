import React, { FunctionComponent, useState } from 'react'
import { Button, Headline1, Paragraph, Spacing } from '@boxine/tonies-ui'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { useGeoInformation } from '../../../hooks/useGeoInformation'
import { sentryWithExtras } from '../../../hooks/useSentry'
import useStorage from '../../../hooks/useStorage'
import { normalizeErrorMessage } from '../../../utils/normalizeError'
import { useConsent } from '../../../hooks/useConsent'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { Modal } from '@/tonies-ui/molecules/Modal'
import { GeoIpDoodle } from './components/GeoIpDoodle'

type GeoIpModalProps = {
  dataTestId?: string
}
const ModalContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
`

export const GeoIpModal: FunctionComponent<GeoIpModalProps> = ({
  dataTestId = 'geo-ip-modal',
}) => {
  const { t } = useTranslation()
  const { isPending: isPendingConsent } = useConsent()
  const router = useRouter()
  const [stayInDifferentRegion, setStayInDifferentRegion] = useStorage<
    boolean | undefined
  >('stay-in-different-region', undefined, 'sessionStorage')
  const [isRedirecting, setIsRedirecting] = useState<boolean | undefined>()
  const { isPending, isValid, regionLcCC } = useGeoInformation()
  const handleRedirectHome = async () => {
    if (regionLcCC) {
      try {
        setIsRedirecting(true)
        await router.push(`/${regionLcCC.toLowerCase()}`)
      } catch (error) {
        const sentryError =
          error instanceof Error
            ? error
            : new Error(normalizeErrorMessage(error))
        sentryWithExtras('GeoIpModalRedirect', sentryError)
      }
      setIsRedirecting(false)
    }
  }
  const handleStayHere = () => {
    setStayInDifferentRegion(true)
  }

  return (
    <Modal
      isOpen={
        Boolean(regionLcCC) &&
        !isPending &&
        !isPendingConsent &&
        !isValid &&
        !stayInDifferentRegion
      }
      shouldFocusFirstElement
      onClose={handleStayHere}
      data-testid={dataTestId}
    >
      <ModalContent>
        <Spacing mb="spacing-xs">
          <Headline1 data-testid={`${dataTestId}__headline`}>
            <Accent text={t('geoIpModal:headline')} />
          </Headline1>
        </Spacing>
        <Spacing mb="spacing-m">
          <Paragraph size={2}>{t('geoIpModal:copy')}</Paragraph>
        </Spacing>
        <Spacing mb="spacing-m">
          <ButtonWrapper>
            <Spacing mb="spacing-m">
              <Button
                onClick={handleRedirectHome}
                isPending={isRedirecting}
                data-testid={`${dataTestId}-redirect`}
              >
                {t('geoIpModal:cta')}
              </Button>
            </Spacing>
            <Button
              data-testid={`${dataTestId}-stay-here`}
              variant="secondary"
              onClick={handleStayHere}
              disabled={isRedirecting}
            >
              {t('geoIpModal:secondaryCta')}
            </Button>
          </ButtonWrapper>
        </Spacing>
        <GeoIpDoodle />
      </ModalContent>
    </Modal>
  )
}
