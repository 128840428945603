import { Headline1 } from '@boxine/tonies-ui'
import { ColorContextProvider } from '@/tonies-ui/index'
import { SupportedHTMLTags } from '@boxine/tonies-ui/dest/types/src/atoms/Typography/types'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { withGTMV4Context } from '../../../providers/gtmV4'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { Carousel } from '../../molecules/Carousel'
import { VideoText, VideoTextProps } from '../VideoText'

export type Props = {
  background?: string
  dataTestId?: string
  title: string
  headlineAsHTMLTag?: SupportedHTMLTags
  items: VideoTextProps[]
  id?: string
}

const Wrapper = styled.div<{ background: string }>`
  background: ${p => p.background};
  padding-top: 1rem;
  padding-bottom: 2rem;
`

const VideoTextCarouselContainer: FunctionComponent<Props> = ({
  background = 'transparent',
  dataTestId,
  title,
  headlineAsHTMLTag = 'h3',
  items,
  id,
}) => (
  <ColorContextProvider backgroundColor={background}>
    <Wrapper background={background} data-testid={dataTestId} id={id}>
      <Headline1 asHTMLTag={headlineAsHTMLTag} align="center">
        <Accent text={title} />
      </Headline1>
      <Carousel
        ariaTitle={title}
        naturalSlideWidth={320}
        visibleSlides={1}
        buttons="none"
        hasPagination
        isInfinite
        isIntrinsicHeight
      >
        {items.map((item, i) => (
          <VideoText key={i} {...item} backgroundColor={background} />
        ))}
      </Carousel>
    </Wrapper>
  </ColorContextProvider>
)

export const VideoTextCarousel = withGTMV4Context(
  VideoTextCarouselContainer,
  'VideoTextCarousel'
)
