import { BxOrder } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

/**
 * Fetches the order with the given ID from the Shop API.
 */
export const getOrder = async (lcCC: EcomLocale, orderId: string) =>
  fetch<Record<string, unknown>, BxOrder>({
    query: `/orders/${orderId}`,
    lcCC,
    method: 'get',
    request: {
      expand: 'shippingInfo.shippingMethod.zoneRates[*].zone',
    },
  })
