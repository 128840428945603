import React, { FunctionComponent } from 'react'
import { PageLayout } from '../PageLayout'
import { PromotionLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { PromotionToniesLayout } from '../../components/organisms/PromotionToniesLayout'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'

export const PromotionLayout: FunctionComponent<
  Aggregated<PromotionLayoutSchema>
> = ({ pageMetaData, promotionToniesLayout }) => (
  <PageLayout meta={pageMetaData}>
    <PromotionToniesLayout headlineAsHTMLTag="h1" {...promotionToniesLayout} />
  </PageLayout>
)
