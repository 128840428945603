import React, { FunctionComponent } from 'react'
import { Button, media } from '@boxine/tonies-ui'
import { useBreadcrumbs } from '../hooks/useBreadcrumbs'
import styled from 'styled-components'

const StyledUl = styled.ul`
  display: flex;
  font-size: 0.75rem;

  ${media.tablet`
    font-size: 0.875rem;
  `}

  li + li :before {
    content: '>';
    display: inline-flex;
    padding: 0 0.5rem;
  }

  a {
    font-size: inherit;
  }
`

type Props = {
  product: NormalizedProduct
}

export const Breadcrumbs: FunctionComponent<Props> = ({ product }) => {
  const breadcrumbs = useBreadcrumbs(product)

  return (
    <StyledUl>
      {breadcrumbs.map(b => (
        <li key={b.url}>
          <Button variant="secondary" as="a" href={b.url}>
            {b.label}
          </Button>
        </li>
      ))}
    </StyledUl>
  )
}
