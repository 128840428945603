import React, {
  FunctionComponent,
  MouseEvent,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useResizeObserver } from '@boxine/tonies-ui'
import * as icons from '@boxine/tonies-ui/icons'
import styled, { css } from 'styled-components'
import { IconButton, Styling } from '@/tonies-ui/atoms/IconButton'
import { useStateWhenMounted } from '../../../hooks/useStateWhenMounted'
import { Image } from '@/tonies-ui/atoms/Image'

const imageGapInRem = 1
const scrollTimeMs = 200

export type ImageProps = {
  src: string
  alt: string
}

export type Props = {
  images: ImageProps[]
  numVisibleImages: number
  onArrowDown?: () => void
  onArrowUp?: () => void
  onImageClick?: (imageIndex: number) => void
  onImageHover?: (imageIndex: number) => void
  selectedIndex: number
  styling?: Styling
  topIndex: number
}

const OuterWrapper = styled.div<{ height: number }>`
  display: grid;
  height: ${p => p.height}px;
  overflow: hidden;
  user-select: none;
`

const InnerWrapper = styled.div<{ verticalOffset: number }>`
  display: grid;
  gap: ${imageGapInRem}rem;
  position: relative;
  transform: translateY(${p => -p.verticalOffset}px);
  transition: transform ${scrollTimeMs}ms ease;
`

const ImageWrapper = styled.div<{
  hasSelection: boolean
  isClickable: boolean
}>`
  border: 1px solid transparent;
  display: grid;
  position: relative;

  ${p =>
    p.isClickable &&
    css`
      cursor: pointer;
    `}

  ${p =>
    p.hasSelection &&
    css`
      border-color: ${({ theme }) => theme.colors.middlegrey};
    `}
`

// FIXME: avoid overwriting internal component styles
const ArrowButton = styled(IconButton)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
`

const ImageContainer = styled.div<{ opacity: number }>`
  opacity: ${p => p.opacity};
  width: 100%;
`

const getEmSizeInPx = () =>
  parseFloat(getComputedStyle(global.document.body).fontSize)

export const ImageSelector: FunctionComponent<Props> = ({
  images,
  numVisibleImages,
  onArrowDown,
  onArrowUp,
  onImageClick,
  onImageHover,
  selectedIndex,
  styling = 'primary',
  topIndex,
}) => {
  const innerWrapperRef = useRef<HTMLDivElement>(null)
  const [arrowsVisible, setArrowsVisible] = useStateWhenMounted(true)
  const [imageGapInPx, setImageGapInPx] = useState(0)
  const [imageHeightInPx, setImageHeightInPx] = useState(0)
  const buttonUp = useRef<HTMLButtonElement>(null)
  const buttonDown = useRef<HTMLButtonElement>(null)

  const resize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (entries.length > 0) {
        // Compute sizes of images and gaps
        const innerWrapperHeightPx = entries[0].contentRect.height
        const imageGapInPx = imageGapInRem * getEmSizeInPx()
        const imageHeightInPx =
          (innerWrapperHeightPx - (images.length - 1) * imageGapInPx) /
          images.length

        // Update state
        setImageGapInPx(imageGapInPx)
        setImageHeightInPx(imageHeightInPx)
      }
    },
    [images.length]
  )

  useResizeObserver(resize, innerWrapperRef)

  useEffect(() => {
    setArrowsVisible(false)

    setTimeout(() => {
      setArrowsVisible(true)
    }, scrollTimeMs)
  }, [topIndex, setArrowsVisible])

  const handleArrowDown = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (onArrowDown) {
        onArrowDown()
      }

      e.stopPropagation()
    },
    [onArrowDown]
  )

  const handleArrowUp = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (onArrowUp) {
        onArrowUp()
      }

      e.stopPropagation()
    },
    [onArrowUp]
  )

  const outerWrapperHeight =
    numVisibleImages * imageHeightInPx + (numVisibleImages - 1) * imageGapInPx

  const innerWrapperOffset = topIndex * (imageHeightInPx + imageGapInPx)

  const canScrollUp = topIndex > 0
  const arrowUpIndex = canScrollUp ? topIndex : -1

  const canScrollDown = topIndex < images.length - numVisibleImages
  const arrowDownIndex = canScrollDown ? topIndex + numVisibleImages - 1 : -1

  return (
    <OuterWrapper height={outerWrapperHeight}>
      <InnerWrapper ref={innerWrapperRef} verticalOffset={innerWrapperOffset}>
        {images.map((image, i) => (
          <ImageWrapper
            key={i + '_' + image.src}
            hasSelection={i === selectedIndex}
            isClickable={Boolean(onImageClick)}
            onClick={() => onImageClick && onImageClick(i)}
            onMouseEnter={() => onImageHover && onImageHover(i)}
          >
            <ImageContainer
              opacity={i === arrowDownIndex || i === arrowUpIndex ? 0.5 : 1}
            >
              <Image
                src={image.src}
                alt={image.alt}
                ratio={[4, 3]}
                responsive={166}
                crop="fill"
                background="#ffffff"
                placeholder={false}
              />
            </ImageContainer>

            {arrowsVisible && i === arrowUpIndex && (
              <ArrowButton
                icon={icons.arrowUp}
                onClick={handleArrowUp}
                ref={buttonUp}
                styling={styling}
              />
            )}
            {arrowsVisible && i === arrowDownIndex && (
              <ArrowButton
                icon={icons.arrowDown}
                onClick={handleArrowDown}
                ref={buttonDown}
                styling={styling}
              />
            )}
          </ImageWrapper>
        ))}
      </InnerWrapper>
    </OuterWrapper>
  )
}
