import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg<{
  size: 'small' | 'normal'
}>`
  position: absolute;
  z-index: -1;

  ${({ size }) => {
    if (size === 'small') {
      return `
        width: 21.5em;
        height: 18.75rem;
      `
    }

    return `
      width: 27.25em;
      height: 23.625rem;
    `
  }}
`

export const BlueShape = ({ size }: { size: 'small' | 'normal' }) => (
  <Svg size={size} viewBox="0 0 437 378">
    <path
      d="M218-.002C167.612.006 53.867 9.863 17.945 49.851-17.203 88.975 3.357 295.365 44.438 335.516 82.504 372.644 145.476 378.009 218 378c71.577-.01 141.939-2.888 185.787-51.598 40.402-44.91 39.911-249.144 14.506-278.502C378.763 2.223 278.724-.008 218-.002z"
      fill="#A8D2E7"
      fillRule="evenodd"
    />
  </Svg>
)
