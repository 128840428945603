import React, { FunctionComponent, useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import {
  Grid,
  GridArea,
  media,
  Spacing,
  Headline5,
  Paragraph,
  useIsViewportDesktop,
  Button,
} from '@boxine/tonies-ui'
import { Section } from '../../../components/atoms/Section'
import { PageLayout } from '../../PageLayout'
import { DeliveryInfoTeaserCollection } from '../../../components/organisms/DeliveryInfoTeaserCollection'
import { useTranslation } from 'next-i18next'
import { FreeHtml } from '../../../components/molecules/FreeHtml'
import { ProductCardCarousel } from '../../../components/organisms/ProductCardCarousel'
import { useAggregatedShopLocale } from '../../../providers/aggregatedShopLocale'
import { useGtmV4 } from '../../../providers/gtmV4'
import { useProductVariants } from '../../../hooks/useProductVariants'
import { BundledProducts } from '../components/BundledProducts'
import {
  FloatingPanel,
  FloatingPanelContentDefault,
} from '../components/FloatingPanel'
import { TrackList } from '../components/TrackList'
import { StickyFooter } from '../components/StickyFooter'
import { ProductSocialShare } from '../components/ProductSocialShare'
import { ImageCarousel } from '../components/ImageCarousel'
import { AdditionalProductInfo } from '../components/AdditionalProductInfo'
import { ImageGallery } from '../components/ImageGallery'
import { usePDPLayout } from '../hooks/usePDPLayout'
import { useMetaData } from '../hooks/useMetaData'
import { AudioSampleContainer } from '../components/AudioSample'
import { MainActionButton } from '../components/MainActionButton'
import { PredefinedBundleToBundlerLink } from '../components/PredefinedBundleToBundlerLink'
import { Price } from '../../../components/atoms/Price'
import { RichText } from '../../../components/molecules/RichText'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { useShopLocaleConfig } from '../../../hooks/useShopLocaleConfig'
import { useUrlModularContentSection } from '../../../hooks/useUrlModularContentSection'
import { Hr } from '../../../components/atoms/Hr'
import { ModularContentSection } from '../../../components/organisms/ModularContentSection'
import { AggregatedPdp } from '../../../lib/transformation/aggregatePdp'
import { BazaarvoiceReviews } from '../components/Bazaarvoice'
import { useRecommendations } from '../../../providers/recommendations'

export type DefaultPdpProps = {
  product: NormalizedProductExtended
  compatibleAudioContents?: AggregatedPdp['compatibleAudioContents']
  moreFromSeriesGroup?: AggregatedPdp['moreFromSeriesGroup']
  productVariants?: AggregatedPdp['productVariants']
}

const MobileFullbleedWrapper = styled.div`
  margin: 0 -1rem;
`

// FIXME: avoid overwriting internal component styles
const ProductDetailWrapper = styled(Section)`
  padding-top: 6rem;

  ${media.laptop`
    padding-top: 8rem;
  `}
`

export const DefaultPdp: FunctionComponent<DefaultPdpProps> = ({
  product,
  compatibleAudioContents,
  moreFromSeriesGroup,
  productVariants,
}) => {
  const { handleSetLastSeenSku } = useRecommendations()
  handleSetLastSeenSku(product.sku)
  const {
    content: {
      additionalPaymentInfo,
      deliveryInfoTeaserList,
      recommendedNormalizedProducts,
    },
    commonPages: { audioContentPage, toniesPage },
  } = useAggregatedShopLocale()
  const { pushGtmV4Event } = useGtmV4()
  const isMobile = !useIsViewportDesktop()
  const { t } = useTranslation()
  const { colors } = useTheme()
  const layout = usePDPLayout()
  const shopGlobalLocaleConfig = useShopLocaleConfig()
  const { activeProduct, ...restOfUseProductVariants } = useProductVariants(
    product,
    productVariants || []
  )
  const urlModularContentSection = useUrlModularContentSection()
  const [hasProductDetails, setHasProductDetails] = useState(
    !urlModularContentSection
  )
  const { ageMin, description, hasChokingHazardWarning, tracks } = activeProduct
  const hasCompatibleAudioContents =
    shopGlobalLocaleConfig?.showTunes &&
    compatibleAudioContents &&
    compatibleAudioContents.length > 0
  const hasMoreFromSeriesGroup =
    moreFromSeriesGroup?.products && moreFromSeriesGroup.products.length > 0
  const hasRecommendations =
    recommendedNormalizedProducts && recommendedNormalizedProducts.length > 0

  // useEffect here because of PDPs with a ColorSelector where we don't have a page
  // load for the selected product, but the change counts as a new GTM product view
  useEffect(() => {
    pushGtmV4Event({
      eventType: 'viewItem',
      item: activeProduct,
    })
  }, [activeProduct, pushGtmV4Event])

  return (
    <PageLayout
      dataTestId="pdp-pagelayout"
      stickyFooterChildren={
        <StickyFooter product={activeProduct}>
          <div className="price-add-to-cart">
            <Price
              product={activeProduct}
              vatHint={{
                content: 'included',
                position: 'new-line',
              }}
            />
            {activeProduct.bundledProducts &&
            activeProduct.availability.state === 'sold-out' ? (
              <Paragraph size={2} weight={500} align="right">
                {t(
                  'productDetailPage:predefinedBundle.createCustomBundle.soldOut'
                )}
                <br />
                <PredefinedBundleToBundlerLink
                  product={activeProduct}
                  variant="link"
                />
              </Paragraph>
            ) : (
              <MainActionButton product={activeProduct} type="addToCart" />
            )}
          </div>
          {additionalPaymentInfo && (
            <RichText
              className="additional-payment-info"
              size={3}
              linksInheritColor={true}
              document={additionalPaymentInfo}
            />
          )}
        </StickyFooter>
      }
      meta={useMetaData(activeProduct)}
    >
      <ProductDetailWrapper data-testextra={activeProduct.sku}>
        <Grid hasGapH>
          <GridArea {...layout.breadcrumbs}>
            <Spacing mb={isMobile ? undefined : 'spacing-l'}>
              <Breadcrumbs product={activeProduct} />
            </Spacing>
          </GridArea>
          <GridArea {...layout.gallery}>
            {isMobile ? (
              <MobileFullbleedWrapper>
                <ImageCarousel product={activeProduct} />
              </MobileFullbleedWrapper>
            ) : (
              <ImageGallery product={activeProduct} />
            )}
          </GridArea>
          <GridArea {...layout.description}>
            <FloatingPanel product={activeProduct}>
              <FloatingPanelContentDefault
                activeProduct={activeProduct}
                {...restOfUseProductVariants}
              />
            </FloatingPanel>
          </GridArea>
          <GridArea {...layout.content}>
            <div>
              {isMobile && hasChokingHazardWarning && (
                <Spacing pt="spacing-l">
                  <Paragraph size={3}>
                    <Paragraph size={3} asHTMLTag="span" weight={700}>
                      {t('productDetailPage:chokingHazard:headline')}
                    </Paragraph>{' '}
                    {t('productDetailPage:chokingHazard:text')}
                  </Paragraph>
                </Spacing>
              )}

              {(hasProductDetails || activeProduct.audioSampleUrl) && (
                <Spacing pt={isMobile ? 'spacing-l' : 'spacing-xl'}>
                  <Headline5 asHTMLTag="h3">
                    {activeProduct.shopCategory === 'repairs'
                      ? t('productDetailPage:contentRepair')
                      : t('productDetailPage:content')}
                  </Headline5>
                </Spacing>
              )}

              <AudioSampleContainer product={activeProduct} />

              {hasProductDetails && (
                <>
                  <Spacing pt="spacing-xs">
                    <FreeHtml
                      html={description || ''}
                      pSize={isMobile ? 1 : 2}
                      pWeight={300}
                    />
                  </Spacing>

                  {typeof ageMin === 'number' && (
                    <Spacing
                      pt="spacing-s"
                      pb={isMobile ? 'spacing-xl' : undefined}
                    >
                      <Paragraph
                        size={isMobile ? 1 : 2}
                        weight={300}
                        color="primary"
                      >
                        {t(
                          'productDetailPage:additionalProductInfo:ageMinValue',
                          {
                            years: ageMin,
                          }
                        )}
                      </Paragraph>
                    </Spacing>
                  )}
                </>
              )}

              <BundledProducts product={activeProduct} />
            </div>
          </GridArea>
          {hasProductDetails && (
            <>
              {tracks && tracks.length && (
                <GridArea {...layout.tracks}>
                  <Spacing pt={isMobile ? 'spacing-s' : 'spacing-xl'}>
                    <TrackList tracks={tracks} />
                  </Spacing>
                </GridArea>
              )}
              <GridArea {...layout.additionalProductInfo}>
                <Spacing pt="spacing-xxl">
                  {isMobile ? (
                    <MobileFullbleedWrapper>
                      <AdditionalProductInfo product={activeProduct} />
                    </MobileFullbleedWrapper>
                  ) : (
                    <AdditionalProductInfo product={activeProduct} />
                  )}
                </Spacing>
              </GridArea>
              {'socialShare' in layout && (
                <GridArea {...layout.socialShare}>
                  <Spacing pt="spacing-l">
                    <ProductSocialShare
                      product={activeProduct}
                      justifyItems="center"
                      verticalGap="large"
                    />
                  </Spacing>
                </GridArea>
              )}
            </>
          )}
        </Grid>

        {hasProductDetails && (
          <>
            <BazaarvoiceReviews product={activeProduct} />
            {hasMoreFromSeriesGroup &&
              product.seriesGroup &&
              product.series && (
                <Section
                  removePaddingX
                  removePaddingY
                  id="related-to-this-product"
                >
                  <ProductCardCarousel
                    headline={t(
                      `productDetailPage:moreFromCategories:toniesHeadline`,
                      {
                        name: product.seriesGroup.label,
                      }
                    )}
                    referenceTitle={`[coded] MoreFromCategories -- seriesGroup: ${
                      product.seriesGroup.label
                    } -- ${moreFromSeriesGroup.products
                      .map(p => p.salesId)
                      .join(',')}`}
                    items={moreFromSeriesGroup.products}
                    showOutOfStock={true}
                    showAllLink={`${toniesPage?.slug}/?series=${product.seriesGroup.key}&series=${product.series.key}`}
                  />
                </Section>
              )}
            {hasCompatibleAudioContents &&
              product.seriesGroup &&
              product.series && (
                <Section
                  removePaddingX
                  removePaddingY
                  id="compatible-audio-content"
                >
                  <ProductCardCarousel
                    headline={t(
                      `productDetailPage:moreFromCategories:tunesHeadline`,
                      {
                        name: product.seriesGroup.label,
                      }
                    )}
                    referenceTitle={`[coded] MoreFromCategories -- seriesGroup: ${
                      product.seriesGroup.label
                    } -- ${compatibleAudioContents
                      ?.map(p => p.salesId)
                      .join(',')}`}
                    items={compatibleAudioContents}
                    showOutOfStock={true}
                    showAllLink={`${audioContentPage?.slug}/?series=${product.seriesGroup.key}&series=${product.series.key}`}
                  />
                </Section>
              )}
          </>
        )}

        {!hasProductDetails && (
          <>
            <Button
              variant="secondary"
              onClick={() => setHasProductDetails(curr => !curr)}
            >
              {t('productDetailPage:showMoreDetails')}
            </Button>
            <Hr />
          </>
        )}
      </ProductDetailWrapper>

      {urlModularContentSection && (
        <ModularContentSection
          contentSection={urlModularContentSection.modularContent}
        />
      )}

      <Section backgroundColor={colors.lightergrey}>
        <DeliveryInfoTeaserCollection items={deliveryInfoTeaserList} />
      </Section>

      {hasRecommendations && (
        <Section backgroundColor={colors['anthracite-10']}>
          <ProductCardCarousel
            headline={t('productDetailPage:recommended')}
            showAllLink={t('productDetailPage:find-more-link')}
            referenceTitle="[coded] Recommendations"
            items={recommendedNormalizedProducts}
            getAPIRecommendations={true}
          />
        </Section>
      )}
    </PageLayout>
  )
}
