// code inspired by https://nextjs.org/docs/advanced-features/custom-error-page#more-advanced-error-page-customizing
// Note: next.js executes this handler on uncaught exceptions in PRODUCTION; in other environments, it renders a stack trace view.
import { ServerResponse } from 'http'
import React from 'react'
import { sentryWithExtras } from '../hooks/useSentry'
import { ErrorLayout } from '../layouts/ErrorLayout'
import { normalizeError } from '../utils/normalizeError'

type ErrorHandlerProps = {
  statusCode: number
}

function ErrorHandler(props: ErrorHandlerProps) {
  return <ErrorLayout statusCode={props.statusCode} hasHeader />
}

// ref: https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/#create-a-custom-_error-page
// https://nextjs.org/docs/pages/building-your-application/routing/custom-error#customizing-the-404-page
ErrorHandler.getInitialProps = ({
  res,
  err,
}: {
  res?: ServerResponse
  err?: ErrorHandlerProps
}): ErrorHandlerProps => {
  if (err) {
    let extras = {}
    if (res) {
      const { statusCode, statusMessage } = res
      // extract some potentially useful information form the server response
      extras = { statusCode, statusMessage, headers: res.getHeaders() }
    }
    sentryWithExtras('ErrorHandler', normalizeError(err), extras)
  }

  return {
    statusCode: err?.statusCode || res?.statusCode || 400,
  }
}

export default ErrorHandler
