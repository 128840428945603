import { BlogTeaser } from '../../../components/molecules/GenericImageText/variants/BlogTeaser'
import { Aggregated } from '../../../lib/transformation/aggregatePage/aggregate'
import { useCategoryFilter } from './useCategoryFilter'
import { useTagFilter } from './useTagFilter'

export const useFilteredPosts = (posts: Aggregated<BlogTeaser>[]) => {
  const categoryPosts = useCategoryFilter(posts)
  const tagPosts = useTagFilter(categoryPosts.posts)
  const activeCategory = categoryPosts.activeFilter
  const activeTag = tagPosts.activeFilter

  return {
    activeTag,
    activeCategory,
    isFilterActive: !!(activeTag || activeCategory),
    filteredPosts: tagPosts.posts,
  }
}
