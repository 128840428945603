import { useEcomLocale } from '../providers/locale'
import { useAuth } from '../providers/auth'
import { useShopLocaleConfig } from './useShopLocaleConfig'
import { useCountryCodes } from '@boxine/tonies-ui'
import { useFeatureFlags } from './useFeatureFlags'

const useGeoInformationDefaultValues = {
  isValid: undefined,
  region: undefined,
  regionLcCC: undefined,
  country: {
    key: undefined,
    label: undefined,
  },
  isPending: true,
  geoIpRegions: undefined,
}

export const useGeoInformation = () => {
  const {
    isPending: authPending,
    region,
    geoIpCountry,
    geoIpLcCC: regionLcCC,
  } = useAuth()
  const { manualGeoIpBlock } = useFeatureFlags()
  const config = useShopLocaleConfig({ live: false })
  const isPending = authPending || !config

  const currentCountryLabel = useCountryCodes(useEcomLocale()).find(
    c => c.value === geoIpCountry?.toLowerCase()
  )?.label

  return isPending || !config
    ? useGeoInformationDefaultValues
    : {
        isValid: !manualGeoIpBlock && config.geoIpRegions.includes(region),
        region: region,
        regionLcCC,
        country: {
          key: geoIpCountry,
          label: currentCountryLabel,
        },
        isPending,
        geoIpRegions: config.geoIpRegions,
      }
}
