import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Headline3 } from '@boxine/tonies-ui'
import HeartsBottomLeftSvg from './assets/heartsBottomLeft.svg'
import HeartsBottomRightSvg from './assets/heartsBottomRight.svg'
import HeartsTopLeftSvg from './assets/heartsTopLeft.svg'
import HeartsTopRightSvg from './assets/heartsTopRight.svg'
import TonieSvg from './assets/tonie.svg'
import EmphasisSvg from './assets/emphasis.svg'

type Layout = 'wide' | 'narrow'

type Props = {
  layout: Layout
  message: string
}

const Wrapper = styled.div`
  height: 124px;
  overflow: hidden;
  position: relative;
`

const HeartsTopLeft = styled(HeartsTopLeftSvg)`
  position: absolute;
  top: 0;
  left: 60px;
`

const HeartsBottomLeft = styled(HeartsBottomLeftSvg)`
  position: absolute;
  top: 68px;
  left: 0;
`

const HeartsTopRight = styled(HeartsTopRightSvg)<{ $layout: Layout }>`
  position: absolute;
  top: 11px;
  right: ${p => (p.$layout === 'wide' ? '263px' : '150px')};
`

const HeartsBottomRight = styled(HeartsBottomRightSvg)`
  position: absolute;
  top: 76px;
  right: 6px;
`

const Tonie = styled(TonieSvg)`
  position: absolute;
  top: 0;
  right: 31px;
`

const Emphasis = styled(EmphasisSvg)`
  position: absolute;
  top: 52px;
  right: 155px;
`

const TextWrapper = styled.div<{ $layout: Layout }>`
  bottom: 0;
  display: grid;
  align-content: center;
  position: absolute;
  top: 0;
  left: ${p => (p.$layout === 'wide' ? '107px' : '8px')};
  right: 200px;
`

export const ThankYou: FunctionComponent<Props> = ({ layout, message }) => (
  <Wrapper>
    {layout === 'wide' && <HeartsTopLeft />}
    {layout === 'wide' && <HeartsBottomLeft />}
    <HeartsTopRight $layout={layout} />
    <HeartsBottomRight />
    <Tonie />
    <Emphasis />
    <TextWrapper $layout={layout}>
      <Headline3 asHTMLTag="h1" align={layout === 'wide' ? 'left' : 'center'}>
        {message}
      </Headline3>
    </TextWrapper>
  </Wrapper>
)
