import React, { FunctionComponent, PropsWithChildren } from 'react'
import styled, { useTheme } from 'styled-components'

import { useIsViewportMobile, useIsViewportTablet } from '@boxine/tonies-ui'
import { ColorContextProvider } from '@/tonies-ui/index'
import { CreateCustomBundleAccordion } from './CreateCustomBundleAccordion'

const StickyFooterWrapper = styled.div`
  background: ${p => p.theme.colors['anthracite-20']};
  padding: 1rem;

  .price-add-to-cart {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10px, auto));
    justify-items: center;
    width: 100%;
    > :first-child {
      justify-self: start;
    }
    > :last-child {
      justify-self: end;
    }
  }

  .additional-payment-info {
    padding-top: 1rem;
    margin-bottom: -1rem;
  }
`

export const StickyFooter: FunctionComponent<
  {
    product: NormalizedProductExtended
  } & PropsWithChildren
> = ({ children, product }) => {
  const { colors } = useTheme()
  const isTablet = useIsViewportTablet()
  const isMobile = useIsViewportMobile() || isTablet

  return isMobile ? (
    <>
      <CreateCustomBundleAccordion
        product={product}
        borderRadius={['m', 0, 0]}
        backgroundColor={colors['1-color-6-shades-of-secondary-shaded-blue']}
      />
      <ColorContextProvider backgroundColor="#fff">
        <StickyFooterWrapper>{children}</StickyFooterWrapper>
      </ColorContextProvider>
    </>
  ) : null
}
